.message-header {
    color: $white;
    height: 70vh;
    @include media-breakpoint-up(xxl) {
        height: 92vh;
    }
    @include media-breakpoint-down(xl) {
        height: 92vh;
    }
    @include media-breakpoint-down(sm) {
        height: inherit;
        padding: 9rem 6rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 8rem 4rem 4rem;
    }
    aside {
        display: flex;
        @include media-breakpoint-down(sm) {
            display: block
        }
    }
    h2 {
        color: $white;
        font-weight: 500;
        font-size: 3.5vw;
        @include media-breakpoint-down(sm) {
            font-weight: 700;
            font-size: 6vw;
        }
    }
    .sub-title-hoz {
        color: $primary;
        opacity: 1;
    }
    .copy {
        width: 60vw;
        margin-left: 8vw;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0;
        }
    }
}