// article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}body{margin:0;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;margin:0.67em 0;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:bold;}dfn{font-style:italic;}hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace,serif;font-size:1em;}pre{white-space:pre-wrap;}q{quotes:"\201C" "\201D" "\2018" "\2019";}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-0.5em;}sub{bottom:-0.25em;}img{border:0;}svg:not(:root){overflow:hidden;}figure{margin:0;}fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em;}legend{border:0;padding:0;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,select{text-transform:none;}button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}button[disabled],html input[disabled]{cursor:default;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0;}input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}
*,
*::after,
*::before {
    box-sizing: border-box;
}
.menu-wrapper {
    color: #57585c;
    color: $text-color;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    a {
        text-decoration: none;
        color: $white;
        outline: none;
        transition: color 0.2s;
    }
    a:not([href]):not([tabindex]) {
        color: $white;
    }
}

.menu-text {
    @include small-caps;
    color: $white;
    position: absolute;
    right: 80px;
    top: 8px;
    @include media-breakpoint-down(ms) {
        top: 12px;
        font-size: 1rem;
    }
}
.menu-text-close {
    @include small-caps;
    color: $white;
    position: absolute;
    right: -59px;
    top: 1px;
    z-index: 10;
    opacity: 1;
    display: block;
    transform: inherit;
}
a:hover,
a:focus {
    color: $text-color;
}

button:focus,
a:focus {
    outline: none;
}

.hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
}

/* Icons */
.icon {
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin: 0 auto;
    fill: currentColor;
}

.info {
    display: block;
    grid-area: info;
    margin: 1rem 0;
    justify-self: end;
    text-align: center;
}

.codrops-icon {
    display: inline-block;
    padding: 0.25em;
}

.action {
    background: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    padding: 0;
}

.action--menu {
    pointer-events: auto;
    position: absolute;
    top: 2rem;
    right: 0rem;
    z-index: 1000;
    @include media-breakpoint-down(sm) {
        top: 1rem;
        right: -3rem;
    }
}

.menu--open .action--menu {
    pointer-events: none;
}

.action--close {
    position: absolute;
    top: 2.75rem;
    right: 4.75rem;
    z-index: 1000;
    opacity: 0;
    padding: 0 0.5rem;
    @include media-breakpoint-down(sm) {
        top: 1.75rem;
        right: 1.75rem;
    }
}

.icon--menu {
    width: 7rem;
    height: 4rem;
}

.icon--close {
    width: 1.5rem;
}

.menu {
    text-align: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: grid;
    grid-template-rows: 50% 30% 20%;
    grid-template-columns: 50%;
    grid-template-areas:
                    "item1 item1 item1 item1"
                    "item2 item2 item2 item2"
                    "item4 item5 item5 item5";
    pointer-events: none;
    @include media-breakpoint-down(ms) {
        grid-template-rows: 100% 0% 0%;
    }
}
.menu--open {
    pointer-events: auto;
}
.menu__item {
    height: 101%;
}
.menu__item--1 {
    grid-area: item1;
}
.menu__item--2 {
    grid-area: item2;
}
.menu__item--3 {
    grid-area: item3;
}
.menu__item--4 {
    grid-area: item4;
}
.menu__item--5 {
    grid-area: item5;
}
.menu__item--4,
.menu__item--5 {
    display: block;
    color: $white;
}
.menu__item-inner {
    align-items: center;
}
.menu__item {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.menu__item-inner {
    overflow: hidden;
    transform: translate3d(100%,0,0);
    height: 100%;
    width: 101%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.menu-align-left {
        justify-content: flext-start;
    }
    @include media-breakpoint-down(ms) {
        justify-content: start;
        padding-top: 60px;
    }
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu {
    border-radius: 0;
    @include small-caps;
    background: $secondary;
    border: 0;
    text-align: right;
    padding: 0;
    @include media-breakpoint-down(ms) {
        padding: 1rem;
        width: 81%;
    }
}
.dropdown-item {
    color: $primary;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    @include media-breakpoint-down(ms) {
        padding: 0.5rem;
        font-weight: 700;
    }
}
.dropdown-btn::after {
    content: url(/media/images/arrow-down.svg);
    width: 12px;
    height: 12px;
    position: absolute;
    right: -26px;
    top: -7px;
    opacity: 0.5;
    @include media-breakpoint-down(md) {
        right: -16px;
        top: -3px;
    }
    @include media-breakpoint-down(ms) {
        top: 3px;
    }
}
.menu__item--1 .menu__item-inner {
    background: $primary;
    text-align: right;
    @include media-breakpoint-down(ms) {
        align-items: normal;
        padding-right: 38px;
    }
}
.menu__item--2 .menu__item-inner {
    background: $orange;
    @include media-breakpoint-down(ms) {
        display: none;
    }
}
.menu__item--3 .menu__item-inner {
    background: $yellow;
    @include media-breakpoint-down(ms) {
        display: none;
    }
}
.menu__item--4 .menu__item-inner {
    background: $black;
    @include media-breakpoint-down(ms) {
        display: none;
    }
}
.menu__item--5 .menu__item-inner {
    background: $secondary;
    @include media-breakpoint-down(ms) {
        display: none;
    }
}

// .menu__item--4,
// .menu__item--5 {
//     display: none;
// }

.label {
    display: none;
}

.mainmenu__item {
    opacity: 0;
}

.mainmenu__item,
.sidemenu__item {
    position: relative;
    overflow: hidden;
    transition: color 0.1s;
    margin: 0.25rem 0;
    display: block;
    @include media-breakpoint-down(md) {
        overflow: inherit;
    }
}
.mainmenu__item {
    @include font-sans-demi;
    font-size: 4vw;
    text-transform: uppercase;
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    position: relative;
    transition: color 0.3s;
    @include media-breakpoint-down(md) {
        font-size: 2vw;
        text-align: center;
    }
    @include media-breakpoint-down(ms) {
        margin: 0;
        font-size: 4.5vw;
        padding: 0.5rem;
        text-align: right;
    }
}
.sidemenu__item-inner {
    display: block;
    transform: translate3d(0,100%,0);
}

.menu__item-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.8s cubic-bezier(0.2,1,0.8,1);
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba($black, 0.4);
    }
    .text-area {
        position: absolute;
        color: #fff;
        bottom: 0;
        right: 0;
        padding: 5rem;
        width: 80%;
        z-index: 1;
        text-align: right;
        @include media-breakpoint-down(ms) {
            padding: 2rem;
        }
        h3 {
            color: $white;
            @include media-breakpoint-down(ms) {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
        }
        .sub-title {
            color: $primary;
            opacity: 1;
        }
    }
}

.menu__item-inner:hover .menu__item-image {
    transform: scale3d(1.1,1.1,1);
}

.menu__item-hoverlink {
    font-weight: bold;
    text-transform: capitalize;
    position: relative;
    z-index: 1000;
    display: block;
}

.menu__item-hoverlink:hover {
    color: $white;
}

.quote {
    font-size: 2rem;
    padding: 1rem;
    text-align: center;
    max-width: 70%;
    color: $yellow;
}

.menu__item-link {
    text-align: left;
    align-self: flex-start;
    justify-self: start;
    font-size: 1.5rem;
    width: 100%;
    line-height: 1;
    padding: 2rem ;
    margin: 3rem 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 3rem);
}

.menu__item-link::after {
    content: '\27F6';
    display: block;
    margin-top: auto;
    font-size: 0.95rem;
}
.menu-logo {
    max-height: 6vw;
    @include media-breakpoint-down(sm) {
        max-height: 11vw;
    }
}
@media screen and (min-width: 53em) {
    .menu {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        grid-template-columns: 25% 25% 50%;
        grid-template-rows: 60% 40%;
        grid-template-areas:
                        "item2 item2 item1"
                        "item4 item5 item1";
    }
    .menu__item {
        height: 100%;
    }
    .menu__item--1 {
        grid-area: item1;
    }
    .menu__item--2 {
        grid-area: item2;
    }
    .menu__item--3 {
        grid-area: item3;
    }
    .menu__item--4 {
        grid-area: item4;
    }
    .menu__item--5 {
        grid-area: item5;
    }
    .menu__item--4,
    .menu__item--5 {
        display: block;
        color: $white;
        .btn {
            margin-top: 1.5rem;
        }
    }
    .menu__item-inner {
        align-items: center;
    }
    .label {
        display: block;
        color: $orange;
        position: absolute;
        z-index: 1000;
        font-size: 0.85rem;
        font-weight: bold;
        margin: 0;
        white-space: nowrap;
    }
    .label--topleft {
        top: 2rem;
        left: 2rem;
    }
    .label--vert,
    .label--vert-mirror {
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
    }
    .label--vert-mirror {
        transform: rotate(180deg);
    }
    .label--bottomright {
        bottom: 2rem;
        right: 2rem;
    }
    .label::before {
        content: '------------- ';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: -1px;
        margin: 0 0.75rem 0 0;
    }
    .label--vert::before,
    .label--vert-mirror::before {
        margin: 0.75rem 0;
    }
    .mainmenu,
    .sidemenu {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-right: 15vw
    }
    .mainmenu {
        counter-reset: menuitem;
    }
    .mainmenu__item {
        @include font-sans-demi;
        font-size: 2.5rem;
        line-height: 3rem;
        text-transform: uppercase;
        overflow: visible;
        margin: 0.5rem 0;
        padding: 0 0.5rem;
        position: relative;
        transition: color 0.3s;
        @include media-breakpoint-only(xxl) {
            font-size: 2vw;
            line-height: 2.5vw;
        }
        @include media-breakpoint-only(sm) {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
    .mainmenu__item:hover {
        color: $text-color;
        text-decoration: none;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-menu {
        border-radius: 0;
        @include small-caps;
        background: $secondary;
        border: 0;
        text-align: right;
        padding: 2rem 3rem;
        .dropdown-item {
            font-family: $font-sans;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            font-size: 1.5rem;
            padding: 0.5rem 0;
        }
    }
    a.dropdown-item {
        color: $primary;
        padding: 0.75rem 1.5rem;
        color: $primary;
        transition: background 0.3s ease,
                    color 0.3s ease;
        &:hover {
            color: $white;
            background: $primary;
        }
    }
    .dropdown-btn::after {
        content: url(/media/images/arrow-down.svg);
        width: 12px;
        height: 12px;
        position: absolute;
        right: -26px;;
        opacity: 0.5;
        top: -15px;
        opacity: 0.5;
        transform: translateY(50%);
    }
    .mainmenu__item.dropdown-btn:hover::after {
        transform: translateY(50%);
    }
    .mainmenu__item:hover::after {
        opacity: 1;
        transform: scale3d(1,1,1);
    }
    .sidemenu__item {
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        font-size: 0.85rem;
    }
    .menu__item-hoverlink {
        font-size: 1.25rem;
        text-transform: lowercase;
        border-bottom: 2px solid #fff;
        opacity: 0;
        transition: opacity 0.3s;
    }
    .menu__item-inner:hover .menu__item-hoverlink {
        opacity: 1;
    }
}
