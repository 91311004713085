.card {
    border-radius: 0;
}
.card-group {
    &.intro {
        margin-bottom: 5rem;
        @include media-breakpoint-down(md) {
            display: block;
            position: static;
            top: 0;
        }
        .card {
            border-radius: 0;
            background: transparent;
            border: 0;
            font-size: 1.5rem;
            h2 {
                text-transform: uppercase;
                letter-spacing: 0.08em;
                font-size: 1.5rem;
                @include media-breakpoint-down(ms) {
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
            }
            .btn {
                padding-left: 40px;
                padding-right: 40px;
                margin-top: 1.5rem;
            }
            .card-body {
                @include media-breakpoint-down(ms) {
                    font-size: 1rem;
                }
            }
            @include media-breakpoint-down(sm) {
                padding: 2rem;
            }
            @include media-breakpoint-down(ms) {
                padding: 1rem;
                margin-bottom: 0;
            }
            &.card-img {
                @include media-breakpoint-down(md) {
                    height: 60vw;
                    margin-bottom: 0;
                }
                @include media-breakpoint-down(sm) {
                    height: 77vw;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.card-group.intro:nth-of-type(even) {
    flex-direction: row-reverse;
    display: flex;
    @include media-breakpoint-down(md) {
        display: block;
    }
}
.text-image-box {
    display: flex;
    flex-wrap: wrap;
    .content-area {
        display: flex;
        padding: 0;
        width: 100%;
        &.text {
            width: 60%;
            padding-right: 6vw;
            h1 {
                @include media-breakpoint-down(xl) {
                    font-size: 3.5rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 2.5rem;
                }
            }
            .copy {
                // font-size: 1.5rem;
            }
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
        &.image {
            width: 40%;
            height: auto;
            @include media-breakpoint-down(lg) {
                width: 100%;
                height: auto;
                padding: 5vw;
                height: auto;
            }
            @include media-breakpoint-down(sm) {
                padding: 0;
                margin-top: 3rem;
                height: 50vw;
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
            position: relative;
            z-index: 1;
        }
    }
    &.enquiry {
        .content-area {
            &.text {
                padding: 6vw;
            }
        }
    }
    &.home {
        .content-area {
            &.image {
                @include media-breakpoint-down(lg) {
                    height: 50vw;
                }
            }
        }
    }
}
.aftercare-card {
    .card-image {
        @include media-breakpoint-down(md) {
            height: 65vw !important;
        }
    }

    .card-body {
        padding: 4rem;

        @include media-breakpoint-up(lg) {
            padding: 8rem 4rem;
        }
    }

    .card-title {
        margin-top: 1rem;
    }
    
    .card-icon {
        align-items: center;
        background-color: $primary;
        border-top: 1px solid $white;
        display: none;
        justify-content: center;
        overflow: hidden;
        padding: 2rem 4rem;

        svg {
            width: 4rem;
        }

        @include media-breakpoint-up(lg) {
            border-left: 1px solid $white;
            border-top: 0;
            display: flex;
            padding: 8rem 4rem 8rem 0;

            svg {
                margin-left: -2px;
                width: 8rem;
            }
        }
    }

    .create-logo {
        height: 20px;
        width: auto;
    }

    .clixifix-logo {
        height: 20px;
        margin-top: 1rem;
        width: auto;
    }

    .btn-white {
        color: $white !important;

        &:active,
        &:focus,
        &:visited,
        &:hover {
            color: $white !important;
        }

        &:before {
            height: 100%;
            top: 0;
        }

        @include media-breakpoint-down(xs) {            
            &:after {
                height: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .card-footer {
        align-items: flex-start;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        padding: 0;

        @include media-breakpoint-up(lg) {
            margin-top: 8rem;
        }
    }
}
