.modal {
    .modal-content {
        background: $secondary;
        border-radius: 0;
        .modal-header {
            border: 0;
            padding: 3rem 3rem 0;
        }
        .modal-body {
            border: 0;
            padding: 0 3rem 3rem;
            color: $white;
        }
        .modal-footer {
            border-top: 0;
            background: darken($secondary, 10%)
        }
        .modal-footer > * {
            margin-right: 1rem;
        }
    }
}
.modal.light.modal-book {
    .modal-content {
        .modal-header {
            margin-bottom: 1rem;
        }
        .modal-body {
            font-size: 1.5rem;
            color: $text-color;
        }
    }
}
.close {
    color: $primary;
    opacity: 1;
    text-shadow: none;
}
.modal.silver {
    .modal-content {
        background: $silver;
        border-radius: 0;
        .modal-header {
            border: 0;
            padding: 3rem 3rem 0;
            h5 {
                color: $secondary;
            }
        }
        .modal-body {
            border: 0;
            padding: 0 3rem 3rem;
            color: $secondary;
            .form-control {
                border-color: $secondary;
                color: $secondary;
                &::-webkit-input-placeholder { /* Edge */
                  color: rgba($text-color, 0.3);
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: rgba($text-color, 0.3);
                }

                &::placeholder {
                  color: rgba($text-color, 0.3);
                }
            }
            a {
                color: $secondary;
            }
        }
    }
}
.modal.grey {
    .modal-content {
        background: $grey;
        border-radius: 0;
        .modal-header {
            border: 0;
            padding: 3rem 3rem 0;
        }
        .modal-body {
            border: 0;
            padding: 0 3rem 3rem;
            color: $white;
            .form-control {
                color: $secondary;
                &::-webkit-input-placeholder { /* Edge */
                  color: rgba($text-color, 0.5);
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: rgba($text-color, 0.5);
                }

                &::placeholder {
                  color: rgba($text-color, 0.5);
                }
            }
            select.form-control {
                color: rgba($text-color, 0.5);
            }
        }
    }
}
.modal.light {
    .modal-content {
        background: $light;
        border-radius: 0;
        .modal-header {
            border: 0;
            padding: 3rem 3rem 0;
        }
        .modal-body {
            border: 0;
            padding: 0 3rem 3rem;
            color: $white;
            .form-control {
                color: $secondary;
                &::-webkit-input-placeholder { /* Edge */
                  color: rgba($text-color, 0.5);
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: rgba($text-color, 0.5);
                }

                &::placeholder {
                  color: rgba($text-color, 0.5);
                }
            }
            select.form-control {
                color: rgba($text-color, 0.5);
            }
            label.checkbox {
                color: $text-color;
            }
        }
    }
}
.modal.marketing-modal {
    .modal-content {
        background: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/sandpipers-1000px.jpg) center center no-repeat;
        background-size: cover;
        border-radius: 0;
        border: 0;
        &:before {
            content: '';
            background: rgba($modal, 0.6);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
        }
        .modal-header {
            border: 0;
            padding: 3rem 3rem 0;
            .close {
                color: $white;
                font-size: 5rem;
                z-index: 2;
            }
        }
        .modal-body {
            border: 0;
            padding: 0 3rem 5rem;
            color: $white;
            font-weight: 600;
            font-size: 2rem;
            @include media-breakpoint-down(sm) {
                font-size: 1.25rem;
            }
            .title {
                @include font-serif;
                font-size: 4.5rem;
                @include media-breakpoint-down(sm) {
                    font-size: 2.5rem;
                    margin-bottom: 2rem;
                }
            }
            .bg-primary {
                background: rgba($primary, 0.9) !important;
            }
            .small-print {
                font-size: 1rem;
                font-weight: 400;
                margin-top: 1rem;
            }
        }
        .modal-footer {
            border-top: 0;
            background: darken($secondary, 10%)
        }
        .modal-footer > * {
            margin-right: 1rem;
        }
    }
}