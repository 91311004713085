.img-siteplan {
    width: 100%;
    height: auto;
}
.key-features {
    .title {
        @include small-caps;
        color: $primary;
        margin-bottom: 2rem;
    }
}
.siteplan-intro {
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
}
.siteplan-download {
    position: absolute;
    right: 2rem;
    bottom: 50px;
    @include media-breakpoint-down(ms) {
        bottom: 15px;
    }
}
.siteplan-select {
    .form-control {
        border-color: $primary;
        color: $primary;
    }
}
.siteplan-filter {
    border-bottom: 0;
    padding-bottom: 0;
}
.create-dropdown {
    position: relative;
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/arrow-down-gold.svg);
        width: 10px;
        height: 10px;
        position: absolute;
        right: 4px;
        top: 8px;
        @include media-breakpoint-down(sm) {
            top: 10px;
        }
    }
    &.show {
        .btn-secondary.dropdown-toggle {
            color: $primary;
            background-color: transparent;
            border-color: $primary;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .dropdown-toggle {
        background-color: transparent;
        &:focus {
            box-shadow: none;
        }
    }
    .btn-secondary {
        @include small-caps;
        background: transparent;
        padding: 0;
        font-size: 1.25rem;
        color: $primary;
        border: 0;
        border-bottom: 1px solid $primary;
        border-radius: 0;
        width: 100%;
        text-align: left;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        &:hover {
            color: $primary;
            background-color: transparent;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.25rem;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
        @include media-breakpoint-down(ms) {
            font-size: 1rem;
        }
    }
    .btn-secondary:not(:disabled):not(.disabled):active:focus {
        background-color: transparent;
        border-color: $primary;
        box-shadow: none;
        color: $primary;
        &:focus {
            box-shadow: none;
        }
    }
    .dropdown-menu {
        width: 100%;
        background: $light;
        padding: 1rem 0;
        .form-check {
            padding-left: 0;
            text-align: left;
            .form-check-label {
                @include small-caps;
                color: $text-color;
                font-size: 1.25rem;
                text-align: left;
                position: relative;
                padding-left: 0;
                top: -5px;
                left: 5px;
                @include media-breakpoint-down(sm) {
                    font-size: 1.25rem;
                    padding-top: 1rem;
                }
                @include media-breakpoint-down(ms) {
                    font-size: 1rem;
                }
            }
            .class_checkbox_available:before,
            .class_checkbox_reserved:before,
            .class_checkbox_sold:before,
            .class_checkbox_soon:before,
            .class_checkbox_hold:before,
            .class_checkbox:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-circle.svg);
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-top: 1rem;
                position: relative;
                top: 5px;
                left: -5px;
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
            .class_checkbox.checked:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-circle-active.svg);
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-top: 1rem;
            }
            .class_checkbox_available:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-available.svg);
            }
            .class_checkbox_available.checked:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-available-active.svg);
            }
            .class_checkbox_reserved:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-reserved.svg);
            }
            .class_checkbox_reserved.checked:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-reserved-active.svg);
            }
            .class_checkbox_sold:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-sold.svg);
            }
            .class_checkbox_sold.checked:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-sold-active.svg);
            }
            .class_checkbox_soon:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-soon.svg);
            }
            .class_checkbox_soon.checked:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-soon-active.svg);
            }
            .class_checkbox_hold:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-hold.svg);
            }
            .class_checkbox_hold.checked:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-hold-active.svg);
            }
        }
        .dropdown-item {
            @include small-caps;
            color: $text-color;
            font-size: 0.875vw;
            text-align: left;
            position: relative;
            padding-left: 2.5rem;
            @include media-breakpoint-down(sm) {
                font-size: 1rem;
                padding-bottom: 1rem;
                padding-top: 1rem;
            }
            &.active {
                background: transparent;
            }
            &.reserved:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-reserved.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.reserved.active:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-reserved-active.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.sold:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-sold.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.sold.active:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-sold-active.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.available:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-available.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.available.active:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-available-active.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.hold:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-hold.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.hold.active:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-hold-active.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.soon:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-soon.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.soon.active:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-soon-active.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.check-circle:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-circle.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
            &.check-circle.active:before {
                content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/check-circle-active.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 9px;
            }
        }
    }
}
.siteplan {
    .house-type {
        margin-right: -7rem;
        @include media-breakpoint-down(lg) {
            margin-right: 0;
        }
    }
    aside {
        h1 {
            bottom: 0;
            @include media-breakpoint-down(xl) {
                font-size: 70px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 30px;
                margin-top: 2rem;
            }
        }
        .sub-title {
            bottom: 0;
            margin-bottom: 0;
            color: $text-color;
            opacity: 0.5;
        }
    }
    .btn-area {
        margin-top: 2rem;
        text-align: right;
        margin-right: 3rem;
    }
    .filter {
        border-bottom: 0;
        margin-top: 0;
        @include media-breakpoint-down(lg) {
            margin-top: 3rem;
        }
    }
    .map-area {
        position: relative;
        .nav {
            .nav-link {
                border-radius: 50%;
                background: $yellow;
                position: absolute;
                width: 1.3vw;
                height: 1.3vw;
                border: solid $white;
                border-width: $icon-border-width;
                padding: 0;
                @include media-breakpoint-only(xl) {
                    width: 1.4vw;
                    height: 1.4vw;
                    border-width: 1px;
                }
                @include media-breakpoint-down(lg) {
                    width: 1.6vw;
                    height: 1.6vw;
                    border-width: 1px;
                }
                @include media-breakpoint-down(md) {
                    width: 2.2vw;
                    height: 2.2vw;
                }
                @include media-breakpoint-down(ms) {
                    width: 11px;
                    height: 11px;
                }
                &.reserved {
                    background: $reserved;
                }
                &.sold {
                    background: $sold;
                }
                &.soon {
                    background: $soon;
                }
                &.available {
                    background: $available;
                }
                &.apartment {
                    background: $apartment;
                }
                &.showhome {
                    background: $showhome;
                }
                &.hold {
                    background: $teal;
                }
            }
        }
    }
}

/**
 * 2022 siteplan update
 *
 * 1. Offset margin-top on checkboxes.
 * 2. Offset margin-top on related
 */

.development-status > .title,
.filter .search-field > .title {
    font-family: proxima-nova, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    border-color: #222222;
    font-size: 1.25rem;
    color: $primary;

    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
}

.development-status-toggles {
    width: 100%;

    & > .row {
        margin-top: -1rem; // [1]
    }
}

.development-status-toggle {
    margin-top: 1rem;

    & > .form-check-label {        
        color: $white;
        cursor: pointer;
        padding: .6rem 1rem;
        position: relative;
        text-align: center;
        width: 100%;

        &::before {
            background-color: $white;
            border-radius: 50%;
            content: '';
            height: 1.6rem;
            left: 1rem;
            position: absolute;
            width: 1.6rem;
            z-index: 1;
        }

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7489 2.25069C12.0837 2.58442 12.0837 3.12371 11.7489 3.45743L4.89134 10.2921C4.5565 10.6258 4.0154 10.6258 3.68056 10.2921L0.25105 6.87476C-0.0836833 6.54104 -0.0836833 6.00174 0.25105 5.66802C0.585836 5.33429 1.12855 5.33429 1.46339 5.66802L4.26185 8.4793L10.5381 2.25069C10.8729 1.91644 11.414 1.91644 11.7489 2.25069Z' fill='%2325283D'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 1.2rem;
            content: '';
            height: 1.6rem;
            left: 1rem;
            opacity: 0;
            position: absolute;
            width: 1.6rem;
            z-index: 2;
        }

        &.checked {
            &::after { 
                opacity: 1;
            }
        }
    }

    &.available {
        & > .form-check-label {
            background-color: $available;
        }
    }

    &.coming-soon {
        & > .form-check-label {
            background-color: $soon;
        }
    }

    &.reserved {
        & > .form-check-label {
            background-color: $reserved;
        }
    }

    &.sold {
        & > .form-check-label {
            background-color: $sold;
        }
    }
}

.siteplan-plots {
    .title {
        font-family: proxima-nova, sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.08rem;
        font-size: 1.5rem;
        color: $primary;
        margin-bottom: 2rem;
    }
}

.siteplan-plots-related {
    margin-top: -1rem; // [2]
}

.siteplan-plots-related-item {
    color: $white;
    cursor: pointer;
    display: block;
    font-family: proxima-nova, sans-serif;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-top: 1rem;
    padding: .6rem 1rem;
    position: relative;
    text-align: center;
    text-decoration: none ;
    transition: background-color 300ms ease-in-out;
    width: 100%;

    &:hover {
        color: $white;
        text-decoration: none;
    }

    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }

    &.available {
        background-color: $available;

        &:hover {
            background-color: darken($available, 10%);
        }
    }

    &.coming-soon {
        background-color: $soon;

        &:hover {
            background-color: darken($soon ,10%);
        }
    }

    &.reserved {
        background-color: $reserved;

        &:hover {
            background-color: darken($reserved ,10%);
        }
    }

    &.sold {
        background-color: $sold;

        &:hover {
            background-color: darken($sold ,10%);
        }
    }
}