.inspiration-header {
    color: $white;
    height: 96vh;
    @include media-breakpoint-up(xxl) {
        height: 92vh;
    }
    @include media-breakpoint-down(sm) {
        height: inherit;
        padding: 5rem 6rem 9rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 0 4rem 4rem;
    }
    aside {
        display: flex;
        @include media-breakpoint-down(sm) {
            display: block;
        }
    }
    h2 {
        color: $white;
        font-weight: 500;
        font-size: 3.5vw;
    }
    .sub-title-hoz {
        color: $primary;
        opacity: 1;
    }
    .copy {
        width: 60vw;
        margin-left: 8vw;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0;
        }
    }
}
.main-inspiation {
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: $primary;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                h2 {
                    color: $white;
                    margin-left: -50%;
                    padding-right: 50%;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $white;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
.kitchen-config-inspiation {
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: #000000;
                padding: 6vw 3vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                    padding: 6vw;
                }
                h2 {
                    color: $white;
                    margin-left: -25%;
                    padding-right: 50%;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $white;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
.inspiation-item {
    padding-top: 8vw;
    @include media-breakpoint-down(xl) {
        padding-top: 12vw;
    }
    @include media-breakpoint-down(ms) {
        padding-top: 19vw;
    }
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: transparent;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    padding-right: 0;
                }
                .author {
                    font-weight: 700;
                    font-size: 1.5rem;
                    margin-bottom: 2rem;
                }
                h2 {
                    color: $white;
                    margin-left: -50%;
                    padding-right: 50%;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $primary;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
.parent:hover .child, .parent:focus .child {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.parent:hover .child:before, .parent:focus .child:before {
    display: block;
}
.inspiration-intro {
    .description {
        margin-top: 2rem;
        font-size: 2.5rem;
        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
            margin-top: 1rem;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 0;
            font-size: 1.5rem;
        }
    }
}
.inspiration-list-item {
    margin-bottom: 2vw;
    @include media-breakpoint-down(lg) {
        margin-bottom: 4vw;
    }
    a {
        color: $text-color;
        &:hover {
            text-decoration: none;
            .card .inspiration-img {
                -ms-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -webkit-transform: scale(1.2);
                -o-transform: scale(1.2);
                transform: scale(1.2);
            }
        }
        .inspiration-img {
            position: relative;
            -webkit-transition: all 1.5s ease;
                -moz-transition: all 1.5s ease;
                -o-transition: all 1.5s ease;
                transition: all 1.5s ease;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: rgba($black, 0.4);
            }

        }
        .card {
            border-radius: 0;
            border: 0;
            background: transparent;
            display: flex;
            height: 40vw;
            margin-bottom: 4rem;
            overflow: hidden;
            position: relative;
            @include media-breakpoint-down(xxl) {
                height: 28vw;
            }
            @include media-breakpoint-down(xl) {
                height: 28vw;
            }
            @include media-breakpoint-down(lg) {
                height: 40vw;
            }
            @include media-breakpoint-down(md) {
                height: 44vw;
                margin-bottom: 2rem;
            }
            @include media-breakpoint-down(sm) {
                height: 80vw;
            }
            .text-area {
                position: absolute;
                color: $white;
                bottom: 0;
                right: 0;
                padding: 5rem;
                width: 90%;
                z-index: 1;
                text-align: right;
                @include media-breakpoint-up(xl) {
                    padding-left: 0;
                    padding-top: 0;
                }
                @include media-breakpoint-down(md) {
                    padding: 3rem;
                    width: 100%;
                }
                h3 {
                    color: $white;
                    margin-top: 0;
                    margin-bottom: 1.5rem;
                    @include media-breakpoint-down(sm) {
                        font-size: 2rem;
                    }
                }
                .description {
                    color: $white;
                    display: block;
                    margin: 1rem 0;
                    @include media-breakpoint-only(xl) {
                        font-size: 1.5rem;
                    }
                }
                .date {
                    font-weight: 700;
                    margin: 1rem 0;
                }
            }
            .promotion-tab {
                top: 60px;
            }
            .img-box {
                position: relative;
                margin: auto;
                overflow: hidden;
                width: 100%;
            }
            .card-header {
                background: transparent;
                border-radius: 0;
                border: 0;
                height: 60px;
                padding-left: 0;
                padding-right: 0;
            }
            .card-img, .card-img-top {
                border-radius: 0;
                max-width: 100%;
                transition: all 1s ease;
                display: block;
                width: 100%;
                height: auto;
                transform: scale(1);
            }
            .card-body {
                padding-left: 0;
                padding-right: 0;
                display: flex;
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
        .featured {
            .dev-details.dark {
                padding-left: 2rem;
                padding-right: 2rem;
                .item {
                    .item-inner {
                        color: $white;
                        img {
                            fill: #ffffff;
                        }
                    }
                }
                background: $primary;
                color: $white;
            }
            .card-body {
                padding: 2rem;
                background: $primary;
                color: $white;
            }
            .btn-primary {
                background: $primary;
                color: $white;
                padding-right: 3rem;
                &:after {
                    content: url(/media/images/arrow-right-light.svg);
                    right: 12px;
                }
            }
        }
        .btn-area {
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
        }
        .icon-area {
            width: 5rem;
            height: 5rem;
            background: $primary;
            position: absolute;
            text-align: center;
            color: $white;
            font-size: 2.5rem;
            top: 0;
            right: 0;
            padding: 0.5rem;
            i {
                width: 50%;
                margin: auto;
                position: absolute;
                top: 50%; left: 50%;
                -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
            }
        }
        .btn-primary {
            padding: 14px 20px;
            height: 40px;
            left: 0;
            transition: left 0.3s ease;
            &:before {
                display: none;
            }
            &:hover {
                left: 5px;
            }
        }
    }
    .title {
        h3 {
            @include font-sans;
            color: $text-color;
            @include media-breakpoint-down(ms) {
                font-size: 2rem;;
            }
        }
    }
}
.hide-third-lg .inspiration-list-item {
    @include media-breakpoint-only(lg) {
        &:nth-child(3) a .card {
            display: none;
        }
    }
    @include media-breakpoint-only(md) {
        &:nth-child(3) a .card {
            display: none;
        }
    }
}
.inspiration-filter {
    .custom-select {
        // border-bottom: 1px solid rgba($white, 0.2);
    }
}
.inspiration-view {
    hr {
        border-color: darken($border, 50%);
        margin: 6rem 0;
        @include media-breakpoint-down(sm) {
            margin: 3rem 0;
        }
    }
    ul.social-list {
       position: static;
       &:after {
            display: none;
       }
       li:after {
            display: none;
       }
    }
}