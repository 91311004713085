ul.social-list {
    @include small-caps;
    position: absolute;
    top: 3rem;
    right: 15rem;
    width: 330px;
    @include media-breakpoint-down(sm) {
        top: 1.875rem;
        right: 12rem;
    }
    @include media-breakpoint-down(lg) {
        width: 323px;
    }
    @include media-breakpoint-down(md) {
        width: 330px;
        right: 12.5rem;
    }
    @include media-breakpoint-down(sm) {
        width: auto;
    }
    @include media-breakpoint-down(ms) {
        right: 9.5rem;
    }
    &:after {
        content: '';
        border-right: 1px solid rgba($white, $alpha);
        margin-left: 2rem;
        @include media-breakpoint-down(sm) {
            margin-left: 2rem;
        }
        @include media-breakpoint-down(ms) {
            margin-left: 1rem;
        }
    }
    li {
        &:not(:last-child) {
            margin-right: 2rem;
            @include media-breakpoint-down(sm) {
                margin-right: 1rem;
            }
            @include media-breakpoint-down(ms) {
                margin-right: 0.5rem;
            }
        }
        a {
            color: $white;
            text-decoration: none;
            position: relative;
            left: 0;
            opacity: 1;
            transition: left 0.3s ease,
                        opacity 0.3s ease;
            &:hover {
                left: 3px;
                opacity: 0.5;
            }
        }
        &.text {
            &:after {
                content: '';
                border-right: 1px solid rgba($white, $alpha);
                margin-left: 2rem;
                @include media-breakpoint-down(sm) {
                    margin-left: 1rem;
                }
            }
        }
    }
    &.primary {
        li {
            a {
                color: $primary;
            }
        }
    }
    &.bigger {
        li {
            a i {
                font-size: 2.5rem;
                @include media-breakpoint-down(sm) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}