ul.tick-list {
    position: relative;
    margin: 6rem 0 6rem 3rem;
    @include media-breakpoint-down(ms) {
        padding-left: 3rem;
        margin: 3rem 0;
    }
    li {
        list-style-type: none;
        font-weight: 700;
        font-size: 2.25rem;
        @include media-breakpoint-down(ms) {
            font-size: 1.75rem;
        }
        &:before {
            @include font-awesome;
            content: '\f058';
            color: $primary;
            font-size: 23px;
            position: absolute;
            left: 0;
            @include media-breakpoint-down(ms) {
                font-size: 18px;
            }
        }
    }
}
.page-wrapper {
    // padding-left: 0;
    // padding-top: 0;
    // padding-bottom: 0;
    @include media-breakpoint-down(xl) {
        // padding-top: 12vw;
    }
    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
    @include media-breakpoint-down(sm) {
        padding-left: 8vw;
        padding-top: 8vw;
    }
    @include media-breakpoint-down(ms) {
        padding-top: 8vw;
    }
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
                .content {
                    background: center top / cover no-repeat;
                    background-color: $primary;
                    background-blend-mode: soft-light;
                    opacity: 0.9;
                }
            }
            &.image:after {
                // content:'';
                // background-color: $primary;
                // background-blend-mode: multiply;
                // position: absolute;
                // top: 0;
                // right: 0;
                // bottom: 0;
                // left: 0;
                // z-index: 1;
                // @include media-breakpoint-down(sm) {
                //     display: none;
                // }
            }
            &.text {
                width: 50%;
                height: auto;
                background: transparent;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    padding-right: 0;
                }
                h2 {
                    color: $text-color;
                    margin-left: -50%;
                    padding-right: 50%;
                    mix-blend-mode: exclusion;
                    // text-shadow: 1px 1px 35px rgba(150, 150, 150, 0.7);
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $primary;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
.author {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
/******** LIST ITEMS ********/
.page-list-item {
    margin-bottom: 5rem;
    @include media-breakpoint-down(ms) {
        margin-bottom: 3rem;
    }
    .card {
        border-radius: 0;
        border: 0;
        .tab-area {
            position: relative;
            .tab-tag {
                position: absolute;
                right: 0;
                width: 89px;
                height: 89px;
                background: $primary;
                color: $white;
                bottom: 0;
                font-size: 2.5rem;
                text-align: center;
                line-height: 2;
                @include media-breakpoint-down(ms) {
                    width: 50px;
                    height: 50px;
                    font-size: 1.5rem;
                }
            }
        }
        .image {
            @include media-breakpoint-down(md) {
                display: block;
                height: 80vw !important;
            }
            @include media-breakpoint-down(ms) {
                height: 65vw !important;
            }
        }
        .card-img {
            border-radius: 0;
        }
        .card-body {
            text-align: center;
            background: $white;
            padding: 15rem 7rem;
            @include media-breakpoint-down(md) {
                padding: 3rem;
            }
            @include media-breakpoint-down(ms) {
                padding: 4rem;
            }
            h5.card-title {
                font-size: 2rem;
                @include media-breakpoint-down(ms) {
                    font-size: 1.5rem;
                }
            }
            .details {
                @include small-caps;
                margin-bottom: .75rem;
                a {
                    color: $text-color;
                    font-weight: 600;
                    color: $secondary;
                }
            }
        }
        .card-footer {
            background: transparent;
            border: 0;
        }
    }
}
.page-list-item {
    .card {
        .card-body {
            text-align: left;
            background: $light;
        }
        .card-footer {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.page-list .page-list-item:nth-of-type(even) .card .row,
.news-list .news-list-item:nth-of-type(even) .card .row {
    -webkit-flex-direction: row-reverse;
    display: -webkit-flex;
    flex-direction: row-reverse;
    display: flex;
}
.nk-pagination {
    .page-item.disabled .page-link {
        border: 0;
        border-radius: 0;
    }
    .page-link {
        @include small-caps;
        border-top: 0;
        border-bottom: 0;
        color: $text-color;
    }
    .page-item:last-child .page-link {
        border-radius: 0;
        border-right: 0;
    }
    li {
        list-style: none;
    }
}