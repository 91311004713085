.bg-extra-light {
    background: $extra-light;
}
.bb-light {
    border-bottom: 20vw solid $light;
}
// .bg-light {
//     .highlight {
//         background: $extra-light;
//         .title {
//             color: $primary;
//         }
//         .checkbox {
//             color: $text-color;
//         }
//     }
// }
.bg-light {
    background: $light;
    &.gallery.script-text:after {
        opacity: 0.05;
    }
    .swiper-container.gallery-slider .swiper-slide.gallery-item h3 {
        color: $text-color;
    }
    aside .sub-title {
        color: $text-color;
    }
}
.bg-med {
    background: #d0c9c2;
    &.gallery.script-text:after {
        opacity: 0.05;
    }
    .swiper-container.gallery-slider .swiper-slide.gallery-item h3 {
        color: $text-color;
    }
}
.bg-gold {
    background: $primary;
    &.gallery.script-text:after {
        opacity: 0.05;
    }
}
.bg-white {
    aside .sub-title {
        color: $text-color;
    }
}
.bg-dark {
    color: $white;
    a {
        color: $primary;
        transition: color 0.3s ease;
        &:hover {
            color: rgba($white, 0.3);
            text-decoration: none;
        }
    }
    .btn-white {
        color: $white;
    }
    hr {
        border-color: rgba($primary, 0.2);
    }
    .share-list ul.social-list li.text:after,
    .share-list ul.social-list:after {
        border-color: rgba($primary, 0.5);
    }
}
.bg-darker {
    background: darken($secondary, 5%);
    color: $white;
}
.bg-primary {
    color: $white;
    h1,h2,h3,h4,h5,h6 {
        color: $white;
    }
    a {
        color: $white;
        transition: opacity 0.3s ease;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
}
.white {
    color: $white;
}
.white-important {
    color: $white !important;
}
.primary {
    color: $primary;
}