.plot-details,
.dev-details {
    .item {
        @include small-caps;
        color: $white;
        display: inline;
        font-weight: 600;
        font-size: 0.6vw;
        text-align: center;
        padding: 1.5rem 0;
        line-height: 2.5;
        @include media-breakpoint-down(lg) {
            font-size: 1vw;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.75vw;
        }
        @include media-breakpoint-down(ms) {
            font-size: 2vw;
        }
        &.link {
            background: $secondary;
            .item-inner {
                &:after {
                    position: absolute;
                    right: 0;
                    content: '';
                    font-family: proxima-nova, sans-serif;
                }
            }
            a {
                color: $primary;
                font-size: 0.7vw;
                &:hover {
                    color: $white;
                }
                &:after {
                    height: 0.4vw;
                    width: 0.4vw;
                    right: 7px;
                    @include media-breakpoint-down(lg) {
                        height: 0.6vw;
                        width: 0.6vw;
                    }
                    @include media-breakpoint-down(ms) {
                        height: 1vw;
                        width: 1vw;
                    }
                }
                @include media-breakpoint-down(lg) {
                    font-size: 1vw;
                }
                @include media-breakpoint-down(md) {
                    font-size: 1.5vw;
                }
                @include media-breakpoint-down(ms) {
                    font-size: 2vw;
                }
            }
        }
        img {
            display: inline;
            height: 1.5rem;
            @include media-breakpoint-down(md) {
                height: 1.25rem;
            }
            @include media-breakpoint-down(ms) {
                // height: 2.5vw;
            }
        }
        .item-inner {
            position: relative;
            &:after {
                content: '|';
                font-family: proxima-nova, sans-serif;
                font-size: 1.5rem;
                position: absolute;
                top: 50%;
                right: 0;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
            &.no-after {
                &:after {
                    display: none;
                }
            }
        }
        &:nth-child(3) .item-inner {
            // &:after {
            //     position: absolute;
            //     right: 0;
            //     content: '';
            //     font-family: proxima-nova, sans-serif;
            // }
        }
        &:nth-child(4) .item-inner {
            @include media-breakpoint-down(md) {
                &:after {
                    position: absolute;
                    right: 0;
                    content: '';
                    font-family: proxima-nova, sans-serif;
                }
            }
        }
    }
    &.dark {
        .item {
            color: $text-color;
            .item-inner {
                border-color: $border;
            }
        }
    }
}
.plot-details {
    .item {
        &:nth-child(3) .item-inner {
            // &:after {
            //     content: '';
            //     font-family: proxima-nova, sans-serif;
            //     font-size: 1.5rem;
            //     position: absolute;
            //     top: 50%;
            //     right: 0;
            //     -ms-transform: translateY(-50%);
            //     transform: translateY(-50%);
            // }
        }
        &:nth-child(4) .item-inner {
            &:after {
                position: absolute;
                right: 0;
                content: '';
                font-family: proxima-nova, sans-serif;
            }
        }
        &:nth-child(5) .item-inner {
            &:after {
                position: absolute;
                right: 0;
                content: '';
                font-family: proxima-nova, sans-serif;
            }
        }
        .item-inner {
            // &:after {
            //     position: absolute;
            //     right: 0;
            //     content: '';
            //     font-family: proxima-nova, sans-serif;
            // }
        }
        .status {
            margin-left: 0;
        }
    }
}
.dev-sticky-menu {
    .plot-details,
    .dev-details {
        .item {
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include media-breakpoint-up(xxl) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-down(xl) {
                font-size: 1rem;
            }
            @include media-breakpoint-down(lg) {
                font-size: 1vw;
            }
            @include media-breakpoint-down(md) {
                height: 50px;
                font-size: 1.2rem;
            }
            @include media-breakpoint-down(ms) {
                font-size: 1rem;
            }
            .item-inner {
                color: $text-color;
                border-color: $text-color;
                width: 100%;
                padding: 0 1.5rem;
                .title {
                    color: $primary;
                    font-weight: 800;
                    line-height: 1.2;
                    display: block
                }
            }
            &:nth-child(3) .item-inner {
                content: '|';
                font-family: proxima-nova, sans-serif;
                // border-right: 1px solid $text-color;
            }
            &:nth-child(4) {
                content: '|';
                font-family: proxima-nova, sans-serif;
                // border-right: 1px solid $border;
                @include media-breakpoint-down(md) {
                    border-right: 0;
                }
            }
            a {
                padding: 0 1.5rem;
                line-height: 1;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        &.left-content {
            @include media-breakpoint-only(md) {
                border-top: 1px solid $border;
            }
        }
    }
    .plot-details {
        .item {
            &:nth-child(4) {
                &:after {
                    content: '0';
                    font-family: proxima-nova, sans-serif;
                }
            }
            &:nth-child(5) {
                &:after {
                    content: '|';
                    font-family: proxima-nova, sans-serif;
                    font-size: 1.5rem;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
                @include media-breakpoint-down(md) {
                    &:after {
                        content: '0';
                        font-family: proxima-nova, sans-serif;
                    }
                }
            }
            &:nth-child(4) .item-inner {
                &:after {
                    content: '|';
                    font-family: proxima-nova, sans-serif;
                    font-size: 1.5rem;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
            &:nth-child(5) .item-inner {
                border: 0;
            }
        }
    }
    .big-btn {
        // background: $secondary;
        height: 90px;
        display: flex;
        align-content: center;
        justify-content: center;
        @include media-breakpoint-down(md) {
            height: $secondary-nav-height-small / 2;
        }
        @include media-breakpoint-down(ms) {
            height: 46px;
        }
        .btn-sticky {
            @include small-caps;
            background: $secondary;
            font-size: 1.1rem;
            opacity: 1;
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: center;
            position: relative;
            border: 0;
            border-radius: 0;
            color: $white;
            padding: 1rem 0.5rem;
            line-height: 1;
            vertical-align: middle;
            width: 100%;
            transition: background 0.3s ease,
                        color 0.3s ease;
            @include media-breakpoint-down(sm) {
                font-size: 1.125rem;
            }
            &.enquire {
                background: $primary;
                &:hover {
                    background: $secondary;
                    color: $primary;
                }
            }
            &.book {
                background: $silver;
                &:hover {
                    background: $white;
                    color: $primary;
                }
            }
            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }
}
.status {
    position: relative;
    margin-left: 0;
    &:before {
        position: absolute;
        content: '';
        border-radius: 50%;
        background: $primary;
        width: 1vw;
        height: 1vw;
        left: -24px;
        display: inline-block;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        @include media-breakpoint-down(lg) {
            left: -15px;
        }
        @include media-breakpoint-down(md) {
            width: 1rem;
            height: 1rem;
        }
        @include media-breakpoint-down(ms) {
            left: -21px;
        }
    }
    &.available:before {
        background-color: $available;
    }
    &.sold:before {
        background-color: $sold;
    }
    &.reserved:before {
        background-color: $reserved;
    }
    &.soon:before {
        background-color: $soon;
    }
    &.showhome:before {
        background-color: $showhome;
    }
    &.hold:before {
        background-color: $teal;
    }
    &.apartment:before {
        background-color: $apartment;
    }
}
.development-intro-booking {
    .address {
        border-right: 1px solid #707070;
        border-left: 0 !important; 
        padding-left: 0 !important; 
        padding-right: 5rem;
        font-size: 1.5rem;
        @include media-breakpoint-down(md) {
            border-right: 0;
            padding-right: 0;
            text-align: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 5rem;
        }
        @include media-breakpoint-down(ms) {
            font-size: 1.25rem;
        }
        .logo {
            max-width: 200px;
            @include media-breakpoint-down(md) {
                max-width: 150px;
            }
             @include media-breakpoint-down(sm) {
                max-width: 100px;
            }
        }
        .times {
            font-weight: 700;
        }
        .email {
            color: $primary;
            i {
                margin-right: 1rem;
            }
        }
        .tel {
            color: $primary;
            i {
                margin-right: 1rem;
            }
        }
        a {
            position: relative;
            left: 0;
            transition: color 0.3s ease,
                        left 0.3s ease;
            &:hover {
                color: rgba($primary, 0.7);
                text-decoration: none;
                left: 10px;
            }
        }
    }
}
.development-intro, .development-intro-booking {
    .p-big {
        padding-top: 0;
    }
    h1 {
        font-size: 2.5rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.75rem;
        }
    }
    h2 {
        font-size: 2rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
    }
    h3 {
        font-size: 1.75rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.25rem;
        }
    }
    h4 {
        font-size: 1.5rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.25rem;
        }
    }
    h5 {
        font-size: 1.25rem;
        @include media-breakpoint-down(sm) {
            font-size: 1.125rem;
        }
    }
    h6 {
        font-size: 1rem;
        @include media-breakpoint-down(sm) {
            font-size: 0.75rem;
        }
    }
    .address {
        border-left: 1px solid #707070;
        padding-left: 5rem;
        font-size: 1.5rem;
        @include media-breakpoint-down(md) {
            border-left: 0;
            padding-left: 0;
            text-align: center;
        }
        @include media-breakpoint-down(ms) {
            font-size: 1.25rem;
        }
        .times {
            font-weight: 700;
        }
        .email {
            color: $primary;
            i {
                margin-right: 1rem;
            }
        }
        .tel {
            color: $primary;
            i {
                margin-right: 1rem;
            }
        }
        a {
            position: relative;
            left: 0;
            transition: color 0.3s ease,
                        left 0.3s ease;
            &:hover {
                color: rgba($primary, 0.7);
                text-decoration: none;
                left: 10px;
            }
        }
    }
    .dev-logo {
        max-height: 8vw;
        @include media-breakpoint-down(lg) {
            max-height: 11vw;
            margin-bottom: 3rem;
        }
        @include media-breakpoint-down(sm) {
            max-height: 15vw;
            margin-bottom: 3rem;
        }
    }
}
ul.incentive-list {
    padding: 0;
    margin: 0;
    @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
    }
    li {
        list-style: none;
        margin: 0;
        margin-bottom: 1rem;
        @include media-breakpoint-down(md) {
            display: inline-block;
            margin-right: 2vw;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
            // @include media-breakpoint-down(sm) {
            //     margin-right: 0;
            // }
        }
        img {
            @include media-breakpoint-down(md) {
                width: 6vw;
            }
            @include media-breakpoint-down(ms) {
                width: 8vw;
            }
        }
    }
}
.housetypes {
    ul.social-list {
        position: relative;
        z-index: 2;
        top: 0;
        right: 0;
        margin-bottom: 0;
        li {
            a {

            }
        }
    }
    .filter {
        .custom-select {
            border-color: $primary;
            @include media-breakpoint-only(lg) {
                font-size: 1rem;
                background-position: right 1rem center;
                background-size: 10px;
            }
            @include media-breakpoint-down(md) {
                border-right: 0;
            }
        }
        .btn-clear {
            @include media-breakpoint-only(lg) {
                font-size: 1rem;
            }
            @include media-breakpoint-down(md) {
                position: relative;
                background: lighten($primary, 15%);
                color: $white;
                padding: 1rem;
                width: 100%;
                text-align: center;
                margin: 6px 0 0;
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
            &:after {
                display: none;
            }
        }
        .btn-primary {
            @include media-breakpoint-only(lg) {
                font-size: 1rem;
            }
            @include media-breakpoint-down(md) {
                position: relative;
                background: $primary;
                color: $white;
                padding: 1rem;
                width: 100%;
                text-align: center;
                margin: 6px 0 0;
                top: 0;
                transform: translateY(0);
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
        }
    }
}
.upcoming-development-list-item,
.development-list-item {
    margin-bottom: 3rem;
    .text-image-box {
        .promotion-tab {
            z-index: 2;
        }
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                display: flex;
                margin-top: 0;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-top: 0;
                    height: 70vw;
                }
            }
            &.text {
                width: 50%;
                color: $text-color;
                height: auto;
                background: $white;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                .dev-logo {
                    padding: 1vw 0;
                    @include media-breakpoint-down(md) {
                        padding: 0 0 2vw;
                        margin: auto;
                    }
                    @include media-breakpoint-down(sm) {
                        height: inherit;
                        padding: 0 25vw 4vw;
                    }
                }
                h2 {
                    color: $primary;
                    font-size: 1.5vw;
                    margin-bottom: 1.25rem;
                    @include media-breakpoint-down(md) {
                        font-size: 2vw;
                        margin-bottom: 2vw;
                        margin-left: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 3vw;
                        margin-bottom: 4vw;
                    }
                    @include media-breakpoint-down(ms) {
                        font-size: 5vw;
                    }
                }
                .sub-title {
                    color: $primary;
                    opacity: 1;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            .description {
                border-top: 1px solid rgba($primary, 0.2);
                margin-top: 2rem;
                padding-top: 2rem;
                font-size: 1.5rem;
                @include media-breakpoint-down(xl) {
                    font-size: 1.25rem;
                }
                @include media-breakpoint-down(ms) {
                    margin-top: 0rem;
                }
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
    }
}
.upcoming-development-list-item:last-of-type,
.development-list-item:last-of-type {
    @include media-breakpoint-down(ms) {
        margin-bottom: 0rem;
    }
}
.upcoming-development-list-item {
    .text-image-box {
        flex-direction: row-reverse;
        .content-area {
            &.text {
                background: $white;
            }
        }
    }
}
ul.dev-details-list {
    @include small-caps;
    color: $text-color;
    display: inline;
    font-weight: 600;
    padding: 1.5rem 0;
    @include media-breakpoint-down(lg) {
        font-size: 1vw;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.75vw;
    }
    @include media-breakpoint-down(ms) {
        font-size: 2vw;
    }
    li.item {
        border-right: 1px solid rgba($primary, 0.2);
        padding-right: 1rem;
        margin-right: 1rem;
        img {
            display: inline;
            height: 1vw;
            @include media-breakpoint-down(sm) {
                height: 1.5rem;
            }
            @include media-breakpoint-down(ms) {
                height: 1rem;
            }
        }
        &:last-of-type {
            border-right: 0;
        }
    }
}
.development-list-item:nth-of-type(even) {
    .text-image-box {
        flex-direction: row-reverse;
        @include media-breakpoint-down(sm) {
            flex-direction: row;
        }
        .promotion-tab {
            transform: rotate(90deg);
            transform-origin: 50% 25%;
            @include media-breakpoint-down(sm) {
                transform: rotate(0);
            }
        }
        .content-area.text {
            background: $white;
        }
    }
}
.upcoming-development-list-item:nth-of-type(even) {
    .text-image-box {
        @include media-breakpoint-down(sm) {
            flex-direction: row;
        }
        .promotion-tab {
            transform: rotate(90deg);
            transform-origin: 50% 25%;
            @include media-breakpoint-down(sm) {
                transform: rotate(0);
            }
        }
        .content-area.text {
            background: $white;
        }
    }
}
.house-type {
    margin-bottom: 2vw;
    @include media-breakpoint-down(lg) {
        margin-bottom: 4vw;
    }
    a {
        // display: block;
        color: $text-color;
        &:hover {
            text-decoration: none;
            color: $text-color;
            .img-box img {
                transform: scale(1.2);
            }
        }
        .card {
            border-radius: 0;
            border: 0;
            background: transparent;
            display: flex;
            .promotion-tab {
                top: 60px;
            }
            .img-box {
                position: relative;
                margin: auto;
                overflow: hidden;
                width: 100%;
            }
            .card-header {
                background: transparent;
                border-radius: 0;
                border: 0;
                height: 60px;
                padding-left: 0;
                padding-right: 0;
            }
            .card-img, .card-img-top {
                border-radius: 0;
                max-width: 100%;
                transition: all 1s ease;
                display: block;
                width: 100%;
                height: auto;
                transform: scale(1);
            }
            .card-body {
                padding-left: 0;
                padding-right: 0;
                display: flex;
                flex-direction: column;
                font-size: 1.5rem;
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
                .status-inner {
                    span {
                        display: inline;
                    }
                }
            }
        }
        .featured {
            .dev-details.dark {
                padding-left: 2rem;
                padding-right: 2rem;
                .item {
                    .item-inner {
                        color: $white;
                        img {
                            fill: #ffffff;
                        }
                    }
                }
                background: $primary;
                color: $white;
            }
            .card-body {
                padding: 2rem;
                background: $primary;
                color: $white;
            }
            .btn-primary {
                background: $primary;
                color: $white;
                padding-right: 3rem;
                &:after {
                    content: url(/media/images/arrow-right-light.svg);
                    right: 12px;
                }
            }
        }
        .btn-area {
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
        }
        .icon-area {
            width: 5rem;
            height: 5rem;
            background: $primary;
            position: absolute;
            text-align: center;
            color: $white;
            font-size: 2.5rem;
            top: 0;
            right: 0;
            padding: 0.5rem;
            i {
                width: 50%;
                margin: auto;
                position: absolute;
                top: 50%; left: 50%;
                -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
            }
        }
        .btn-primary {
            background: $white;
            padding: 14px 20px;
            height: 40px;
            left: 0;
            transition: left 0.3s ease;
            &:before {
                display: none;
            }
            &:hover {
                left: 5px;
            }
        }
    }
    .title {
        h3 {
            @include font-sans;
            color: $text-color;
            font-size: 2.25rem;
            @include media-breakpoint-down(ms) {
                font-size: 2rem;;
            }
        }
    }
}
.swiper-slide {
    .house-type {
        .title {
            h3 {
                color: $white;
            }
        }
        .card-header {
            background: $secondary;
        }
        .card {
            background: $white;
            .promotion-tab {
                top: 0;
            }
            .dev-details {
                background: $white;
                padding: 0 3rem;
                .item {
                    @include media-breakpoint-down(md) {
                        font-size: 1.5rem;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 1rem;
                    }
                }
            }
            .card-body {
                background: $white;
                padding: 0 3rem 3rem;
                font-size: 1.25rem;
                @include media-breakpoint-down(md) {
                    font-size: 1.75rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 1.5rem;
                }
            }
            .card-footer {
                background: $white;
                padding: 1.5rem 3rem;
                color: $primary;
                font-size: 1.25rem;
                border-radius: 0;
                border: 0;
            }
            .img-box {
                .btn-area {
                    .btn-primary {
                        padding-left: 30px;
                        padding-right: 30px;
                        font-size: 1.25rem;
                        &:after {
                            right: 14px;
                        }
                    }
                }
            }
        }
    }
}
.specification {

}