/* Hide arrows from number input - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows from number input - Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
textarea.form-control {
    background: $light;
    border-bottom: 0;
    padding: 1.5rem;
}
.contact-form textarea.form-control {
    background: $white;
    border-bottom: 0;
    padding: 1.5rem;
}
.custom-select {
    @include font-reg;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.636' height='11.015' viewBox='0 0 17.636 11.015'%3E%3Cg transform='translate(0 11.015) rotate(-90)'%3E%3Crect width='12.462' height='3.116' transform='translate(0 8.812) rotate(-45)'/%3E%3Crect width='12.462' height='3.116' transform='translate(2.203 6.621) rotate(45)'/%3E%3C/g%3E%3C/svg%3E")
    no-repeat;
    border-radius: 0;
    border: 0;
    font-size: 1.5rem;
    padding: 0 2rem 0 6rem;
    border-right: 1px solid $border;
    background-position: right 2.5rem center;
    background-size: 12px;
    @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
        background-position: right 1rem center;
        padding: 0 2rem 0 5rem;
    }
    @include media-breakpoint-down(md) {
        &:nth-of-type(2n+2) {
            border-right: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        padding-left: 4rem;
        font-size: 1.125rem;
        background-position: right 1rem center;
    }
}
.filter {
    margin: 5rem 0;
    position: relative;
    z-index: 2;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $primary;
    @include media-breakpoint-down(md) {
        margin: 3rem 0;
        padding-bottom: 1rem;
    }
    .search-field {
        // border-bottom: 1px solid $primary;
        // padding-bottom: 2rem;
            padding-left: 0 !important;
            padding-right: 0 !important;
    }
    .custom-select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 17.6 11' style='enable-background:new 0 0 17.6 11;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23A6815A;%7D%0A%3C/style%3E%3Cg id='Group_908' transform='translate(0 11.015) rotate(-90)'%3E%3Crect id='Rectangle_906' x='3.9' y='-0.7' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 5.5076 13.2967)' class='st0' width='3.1' height='12.5'/%3E%3Crect id='Rectangle_907' x='-0.7' y='10.6' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 0.8253 24.5992)' class='st0' width='12.5' height='3.1'/%3E%3C/g%3E%3C/svg%3E");
        @include small-caps;
        font-size: 1.25rem;
        padding: 0 2rem;
        color: $primary;
        border-color: $primary;
        padding-left: 0;
        background-position: right 1.5rem center;
        @include media-breakpoint-only(xxl) {
            font-size: 1.25rem;
        }
        @include media-breakpoint-only(xl) {
            // font-size: 0.875vw;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.25rem;
            padding-left: 0;
            // border-right: 0;
            border-bottom: 1px solid $border;
        }
        @include media-breakpoint-down(sm) {
            // font-size: 2.5vw;
            background-size: 9px;
            padding-left: 0;
            border-right: 0;
            border-bottom: 1px solid rgba($white, 0.2);
            background-position: right 0 center;
        }
    }
    .btn-clear {
        font-size: 1.25rem;
        padding-right: 0;
        @include media-breakpoint-down(sm) {
            position: relative;
            background: lighten($primary, 15%);
            color: $white;
            padding: 1rem;
            width: 100%;
            text-align: center;
            margin: 6px 0 0;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
        &:after {
            display: none;
        }
    }
    .btn-primary {
        font-size: 1.25rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &:after {
            width: 7px;
            height: 7px;
        }
        @include media-breakpoint-down(sm) {
            position: relative;
            background: $primary;
            color: $white;
            padding: 1rem;
            width: 100%;
            text-align: center;
            margin: 6px 0 0;
            top: 0;
            transform: translateY(0);
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    &.inspiration-filter {
        .custom-select {
            border-bottom: 0;
        }
    }
}
.search .search-field:nth-child(1) .custom-select {
    @include media-breakpoint-down(md) {
        border-bottom: 1px solid $border;
    }
}
.search .search-field:nth-child(2) .custom-select {
    @include media-breakpoint-down(md) {
        border-right: 0;
        border-bottom: 1px solid $border;
    }
}
.search .search-field:nth-child(3) .custom-select {
    border-right: 0;
    @include media-breakpoint-down(md) {
        border-right: 1px solid $border;
    }
}
.search .search-field:nth-child(4) .custom-select {
    border-right: 0;
}
.search {
    .custom-select {
        // align-self: center;
        @include media-breakpoint-down(md) {
            align-self: center;
            display: flex;
            height: 100%;
        }
    }
    .search-field {
        // height: $secondary-nav-height;
        @include media-breakpoint-down(md) {
            height: $secondary-nav-height-small / 2;
            align-self: center;
        }
        @include media-breakpoint-down(ms) {
            height: 46px;
        }
    }
    .search-icon {
        &:before {
            position: absolute;
            top: 0;
            left: 27px;
            z-index: 9;
            width: 36px;
            height: 31px;
            content: " ";
            background-repeat: no-repeat;
            background-position: center;
            @include media-breakpoint-down(lg) {
                left: 15px;
            }
            @include media-breakpoint-down(md) {
                display: flex;
                height: 100%;
            }
            @include media-breakpoint-down(ms) {
                width: 30px;
            }
        }
        &.development {
            .custom-select {
                padding-left: 7rem;
                @include media-breakpoint-down(sm) {
                    padding-left: 6rem;
                }
                @include media-breakpoint-down(ms) {
                    padding-left: 5rem;
                }
            }
        }
        &.development:before {
            background-image: url(/media/images/icon-development.svg);
        }
        &.rooms:before {
            background-image: url(/media/images/icon-bed.svg);
            width: 20px;
            height: 36px;
            @include media-breakpoint-down(lg) {
                height: 29px;
            }
            @include media-breakpoint-down(md) {
                align-self: center;
                display: flex;
                height: 100%;
            }
        }
        &.price:before {
            background-image: url(/media/images/icon-money.svg);
            width: 20px;
            height: 36px;
            @include media-breakpoint-down(lg) {
                height: 29px;
            }
            @include media-breakpoint-down(md) {
                align-self: center;
                display: flex;
                height: 100%;
            }
        }
    }
    .search-btn {
        background: $secondary;
        height: 90px;
        display: flex;
        align-content: center;
        justify-content: center;
        @include media-breakpoint-down(md) {
            height: $secondary-nav-height-small / 2;
        }
        @include media-breakpoint-down(ms) {
            height: 46px;
        }
        .btn {
            position: relative;
            left: 0;
            transition: left 0.3s ease;
        }
        .btn:hover {
            color: $primary;
            left: 5px;
        }
    }
}
.btn-search {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $primary;
    padding: 1rem 2rem 1rem 1rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    @include media-breakpoint-down(sm) {
        font-size: 1.125rem;
    }
    &:after {
        content: url(/media/images/arrow-right.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        right: 4px;
    }
    &:before {
        content: url(/media/images/icon-search.svg);
        width: 29px;
        height: 29px;
        position: absolute;
        left: -32px;
        top: 30px;
        @include media-breakpoint-down(lg) {
            width: 19px;
            height: 19px;
            left: -17px;
            top: 34px;
        }
        @include media-breakpoint-down(md) {
            top: 14px;
        }
        @include media-breakpoint-down(ms) {
            width: 12px;
            height: 12px;
            top: 14px;
            left: -7px;
        }
    }
}
.form-control {
    @include small-caps;
    height: calc(1.5em + 3rem + 2px);
    color: $text-color;
    background: transparent;
    font-size: 1.25rem;
    border-top: 0;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-color: $text-color;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-down(ms) {
        font-size: 1rem;;
    }
    &:focus {
        background: transparent;
    }
    &.select {
        color: $primary;
    }
    &.text-area {
        // border: 1px solid $text-color;
        // padding: 2rem;
    }
}
label {
    @include small-caps;
    color: $primary;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
    &.checkbox {
        @include font-body;
        font-size: 1.25rem;
        text-transform: none;
        letter-spacing: normal;
        color: $text-color;
        margin-bottom: 0;
        @include media-breakpoint-down(ms) {
            font-size: 1rem;
        }
        a {
            font-weight: 800;
            color: $text-color;
        }
    }
}
.dark {
    .form-control {
        border-color: $primary;
        color: $primary;
        &::-webkit-input-placeholder { /* Edge */
          color: $white;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $white;
        }

        &::placeholder {
          color: $white;
        }
    }
    label.checkbox {
        color: $white;
        a {
            color: $primary;
        }
    }
}
.bg-primary {
    .form-control {
        color: $white;
        border-color: $white;
        &::-webkit-input-placeholder {
          color: $white;
        }
        &:-ms-input-placeholder {
          color: $white;
        }
        &::placeholder {
          color: $white;
        }
    }
    label {
        color: $white;
        a {
            color: $white;
        }
    }
}
.google-text {
    @include font-body;
    font-size: 1.25rem;
    text-transform: none;
    letter-spacing: normal;
    color: $text-color;
    margin-bottom: 0;
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
    a {
        font-weight: 800;
        color: $text-color;
    }
}
.highlight {
    background: $primary;
    padding: 2rem 2.5rem;
    margin: 1.5rem 0;
    color: $white;
    .checkbox {
        color: $white;
    }
    .title {
        @include small-caps;
        text-align: center;
        color: $white;
    }
    h4 {
        color: $white;
        margin-bottom: 0;
        margin-top: 0;
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        @include media-breakpoint-down(ms) {
        }
    }
    i {
        color: $white;
        font-size: 7rem;
        @include media-breakpoint-down(sm) {
            font-size: 5rem;
        }
    }
}
.modal .highlight i {
    @include media-breakpoint-only(md) {
        font-size: 4rem;
    }
}
.modal.light .modal-content .modal-body .highlight label.checkbox {
    color: $white;
}