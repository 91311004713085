.spec-logo {
    max-width: 160px;
    @include media-breakpoint-down(md) {
        margin-bottom: 6rem;
    }
    @include media-breakpoint-down(ms) {
        max-width: 90px;
    }
}
.specification {
    h2 {
        @include media-breakpoint-down(ms) {
            margin-bottom: 1rem;
        }
    }
    .nav.nav-arrows-wrapper {
        margin-bottom: 0;
        .nav-arrows {
            position: absolute;
            right: 0;
            right: 20px;
            top: -50px;
            z-index: 1;
            a {
                display: inline-block;
                &:hover {
                    text-decoration: none;
                }
                .nav-arrow {
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }
    .small-caps {
        color: $primary;
        margin-bottom: 2rem;
    }
    .nav {
        @include media-breakpoint-down(md) {
            margin-bottom: 6rem;
        }
        @include media-breakpoint-down(ms) {
            margin-bottom: 2rem;
        }
        .nav-link {
            position: relative;
            @include small-caps;
            color: $text-color;
            background: transparent;
            border-radius: 0;
            padding-left: 30px;
            @include media-breakpoint-down(sm) {
                font-size: 1rem;
            }
            &.active {
                color: $primary;
                &:before {
                    content: url(/media/images/checkbox-active.svg);
                    width: 23px;
                    height: 23px;
                    position: absolute;
                    left: 0;
                }
            }
            &:before {
                content: url(/media/images/checkbox.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
            }
        }
    }
    .arrows {
        top: -60px;
        right: 0;
        left: inherit;
        .swiper-button-next, .swiper-button-prev {
            margin: 0;
            top: 0 !important;
            height: 30px !important;
            width: 30px !important;
            background-size: 30px 30px !important;
        }
        .swiper-button-next {
            left: -9rem !important;
        }
        .swiper-button-prev {
            left: -5rem !important;
        }
    }
}
.specification-swiper-mobile {
    .swiper-pagination {
        bottom: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        .swiper-pagination-bullet {
            margin: 0 10px;
            &.swiper-pagination-bullet-active {
                background-color: $primary;
            }
        }
    }
    .specification-swiper-mobile-next {
        left: 0 !important;
        top: calc(25% - 1rem) !important;
    }
    .specification-swiper-mobile-prev {
        right: 25px !important;
        top: calc(25% - 1rem) !important;
    }
}