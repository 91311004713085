.customer-portal {
    &.intro {
        h1 {
            @include media-breakpoint-only(xl) {
                font-size: 3rem;
            }
            @include media-breakpoint-only(lg) {
                font-size: 2.5rem;
            }
        }
        .copy {
            padding-right: 0;
            @include media-breakpoint-only(xl) {
                font-size: 1.75rem;
            }
            @include media-breakpoint-only(lg) {
                font-size: 1.5rem;
            }
        }
    }
}
.customer-portal-navigation {
    background-color: $white;
    @include media-breakpoint-down(sm) {
        padding: 2.5rem;
    }
    .nav-item {
        margin: 0 1.5vw;
        @include media-breakpoint-down(sm) {
            margin: 1rem 0 0 0;
            width: 100%;
        }
        &:nth-of-type(1) {
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
        .nav-link {
            @include small-caps;
            background: $primary;
            padding: 2vw 1vw;
            color: $white;
            width: 100%;
            text-align: center;
            line-height: 1;
            font-size: 1vw;
            border-radius: 0;
            transition: background 0.3s ease;
            &.active {
                background: $light;
                color: $primary;
            }
            &:hover {
                background: darken($primary, 15%);
            }
            @include media-breakpoint-only(xxl) {
                padding: 1.5vw 1vw;
            }
            @include media-breakpoint-down(sm) {
                font-size: 2vw;
                padding: 3vw 1vw;
            }
            @include media-breakpoint-down(ms) {
                font-size: 3vw;
            }
            i {
                font-size: 2rem;
                margin-right: 0.5rem;
                vertical-align: middle;
                @include media-breakpoint-down(sm) {
                   font-size: 2rem;
                }
            }
        }
    }
}
.sticky-top {
    top: 16vw;
    @include media-breakpoint-down(sm) {
       top: 23vw;
    }
}
.customer-portal-sub-navigation {
    z-index: 2;
    &.navbar {
        padding: 0;
        position: absolute;
        @include media-breakpoint-down(sm) {
           left: -50px;
        }
    }
    &.navbar-light {
        .navbar-collapse {
            @include media-breakpoint-down(sm) {
               margin-left: 33px;
            }
            @include media-breakpoint-down(ms) {
               margin-left: 27px;
            }
            .navbar-nav {
                padding: 2rem;
                background: $white;
            }
        }
        .navbar-toggler {
            background: $white;
            border-radius: 50px;
            padding: 0.5rem 1rem;
            border: 0;
            box-shadow:
              0 0px 2.2px rgba(0, 0, 0, 0.02),
              0 0px 5.3px rgba(0, 0, 0, 0.028),
              0 0px 10px rgba(0, 0, 0, 0.035),
              0 0px 17.9px rgba(0, 0, 0, 0.042),
              0 0px 33.4px rgba(0, 0, 0, 0.05),
              0 0px 80px rgba(0, 0, 0, 0.07)
            ;
            @include media-breakpoint-down(sm) {
               padding-left: 3rem;
               margin-left: -33px;
            }
            @include media-breakpoint-down(ms) {
               margin-left: -27px;
            }
            .navbar-toggler-icon {
                width: 2rem;
                height: 2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 36 23' style='enable-background:new 0 0 36 23;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D%0A%3C/style%3E%3Cg id='Group_1171' transform='translate(-1763 -90)'%3E%3Crect id='Rectangle_914' x='1763' y='90' class='st0' width='36' height='3'/%3E%3Crect id='Rectangle_914-2' x='1763' y='100' class='st0' width='36' height='3'/%3E%3Crect id='Rectangle_914-3' x='1763' y='110' class='st0' width='36' height='3'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
    .navbar-brand {
        color: $white;
        background: $primary;
        border-radius: 50%;
        font-size: 16px;
        margin: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 4px;
        text-align: center;
        margin-left: 5px;
        @include media-breakpoint-down(sm) {
           display: none;
        }
        &:hover {
            color: $white;
        }
    }
    .navbar-nav {
        box-shadow:
          0 0px 2.2px rgba(0, 0, 0, 0.02),
          0 0px 5.3px rgba(0, 0, 0, 0.028),
          0 0px 10px rgba(0, 0, 0, 0.035),
          0 0px 17.9px rgba(0, 0, 0, 0.042),
          0 0px 33.4px rgba(0, 0, 0, 0.05),
          0 0px 80px rgba(0, 0, 0, 0.07)
        ;
        margin-top: 20px;
        @include media-breakpoint-down(ms) {
            margin-left: 25px;;
        }
        .nav-item {
            @include media-breakpoint-down(ms) {
                width: inherit;
            }
            &.active {
                .nav-link {
                    background: $primary;
                    color: $white;
                }
            }
            .nav-link {
                background: $white;
                padding: 0.75rem;
                color: $primary;
                width: 100%;
                font-weight: 700;
                line-height: 1;
                font-size: 1.5rem;
                transition: color 0.3s ease,
                            background 0.3s ease;
                &:hover {
                    background: $primary;
                    color: $white;
                }
                @include media-breakpoint-only(xxl) {
                    padding: 1vw 1vw;
                }
                i {
                    font-size: 2rem;
                    margin-right: 0.5rem;
                    vertical-align: middle;
                    @include media-breakpoint-down(sm) {
                       font-size: 1.25rem;
                    }
                }
            }
        }
    }
}
a.anchor {
    display: block;
    position: relative;
    top: -70px;
    visibility: hidden;
}
.portal .menu-area {
    background: $white;
    ul.local-area-list {
        @include media-breakpoint-down(xl) {
           padding: 0 0 0 3rem;
        }
    }
}
.portal-spec {
    .bg-white {
        background: $light !important;
    }
    .rotate-text aside {
        border-left: 0;
        @include media-breakpoint-down(sm) {
           margin-top: 0;
        }
        h1 {
            display: none;
        }
        .sub-title {
            display: none;
        }
        .offset-left-6 {
            margin-left: 0 !important;
        }
    }
    .rotate-text.p-big {
        padding-top: 0;
    }
}
.section-intro {
    margin-bottom: 5rem;
    margin-left: 100px;
    @include media-breakpoint-down(sm) {
       margin-left: 20px;
       margin-top: 10px;
    }
}
.sub-nav-margin {
    margin-left: 100px;
    @include media-breakpoint-down(sm) {
       margin-left: 0;
    }
}
.sub-nav-margin-row {
    margin-left: calc(100px - 15px);
    @include media-breakpoint-down(sm) {
       margin-left: -15px;
    }
}
.accordion {
    .card {
        border-radius: 0;
        border: 0;
        background: transparent;
        .card-header {
            background: transparent;
            border-radius: 0;
            padding: 1.5rem 0;
            border-bottom: 1px solid $text-color;
            margin-bottom: 0;
            position: relative;
            cursor: pointer;
            display: block;
            &:after {
                content: '\f077';
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                color: $primary;
                right: 0;
                top: 75%;
                margin-top: -25px;
                font-size: 2rem;
                font-weight: 700;
            }
            &.collapsed:after {
                content: '\f078';
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                color: $primary;
                right: 0;
                top: 75%;
                margin-top: -25px;
                font-size: 2rem;
                font-weight: 700;
            }
            h3 {
                @include small-caps;
                margin-top: 0;
                color: $primary;
                font-size: 1.5rem;
                padding: 0;
                @include media-breakpoint-down(ms) {
                    font-size: 0.75rem;
                    font-weight: 700;
                }
            }
        }
        .card-body {
            padding: 1.5rem 0;
            ul {
                padding-left: 30px;
                li {
                    list-style: circle;
                }
            }
        }
    }
}
.url-link {
    display: block;
    background: $white;
    padding: 1.5rem 3rem;
    margin-bottom: 1rem;
    position: relative;
    transition: background 0.3s ease;
    &:after {
        content: '\f0c1';
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        right: 3rem;

    }
    &:hover {
        background: darken($light, 5%);
        text-decoration: none;
    }
}
.details-card,
.business-card {
    height: 100%;
    background: $white;
    padding: 4rem;
    font-size: 1.5rem;
    position: relative;
    margin-bottom: 3rem;
    box-shadow:
        0 0.9px 2.2px rgba(0, 0, 0, 0.02),
        0 2.2px 5.3px rgba(0, 0, 0, 0.028),
        0 4.1px 10px rgba(0, 0, 0, 0.035),
        0 7.4px 17.9px rgba(0, 0, 0, 0.042),
        0 13.8px 33.4px rgba(0, 0, 0, 0.05),
        0 33px 80px rgba(0, 0, 0, 0.07);
    .card-icon {
        position: absolute;
        width: 89px;
        right: 30px;
        bottom: 30px;
        font-size: 5vw;
    }
    @include media-breakpoint-down(xl) {
       padding-bottom: 9rem;
    }
    &.emergency {
        background: $red;
        color: $white;
        h5 {
            color: $white;
        }
        a {
            color: $white;
            text-decoration: underline;
        }
        &:after {
            content: '\f071';
            @include font-awesome;
            position: absolute;
            right: 30px;
            bottom: 15px;
            font-size: 4vw;
            font-weight: 300;
            @include media-breakpoint-down(sm) {
               font-size: 8vw;
            }
            @include media-breakpoint-down(ms) {
                bottom: 15px;
                font-size: 12vw;
            }
        }
    }
    &.gas {
        &:after {
            content: '\f46a';
            @include font-awesome;
            position: absolute;
            right: 30px;
            bottom: 15px;
            font-size: 4vw;
            font-weight: 300;
            color: $primary;
            @include media-breakpoint-down(sm) {
               font-size: 8vw;
            }
            @include media-breakpoint-down(ms) {
                bottom: 15px;
                font-size: 12vw;
            }
        }
    }
    &.electricity {
        &:after {
            content: '\f0e7';
            @include font-awesome;
            position: absolute;
            right: 30px;
            bottom: 15px;
            font-size: 4vw;
            font-weight: 300;
            color: $primary;
            @include media-breakpoint-down(sm) {
               font-size: 8vw;
            }
            @include media-breakpoint-down(ms) {
                bottom: 15px;
                font-size: 12vw;
            }
        }
    }
    &.water {
        &:after {
            content: '\f748';
            @include font-awesome;
            position: absolute;
            right: 30px;
            bottom: 15px;
            font-size: 4vw;
            font-weight: 300;
            color: $primary;
            @include media-breakpoint-down(sm) {
               font-size: 8vw;
            }
            @include media-breakpoint-down(ms) {
                bottom: 15px;
                font-size: 12vw;
            }
        }
    }
}
.customer-portal-house-type {
    margin-bottom: 2vw;
    @include media-breakpoint-down(lg) {
        margin-bottom: 4vw;
    }
    @include media-breakpoint-down(ms) {
        margin-bottom: 0;
    }
    color: $text-color;
    &:hover {
        text-decoration: none;
        .img-box img {
            transform: scale(1.2);
        }
    }
    .card {
        border-radius: 0;
        border: 0;
        background: transparent;
        display: flex;
        font-size: 1.5rem;
        @include media-breakpoint-down(ms) {
            font-size: 1.25rem;
        }
        .promotion-tab {
            top: 60px;
        }
        .img-box {
            position: relative;
            margin: auto;
            overflow: hidden;
            width: 100%;
        }
        .card-header {
            background: transparent;
            border-radius: 0;
            border: 0;
            height: 60px;
            padding-left: 0;
            padding-right: 0;
        }
        .card-img, .card-img-top {
            border-radius: 0;
            max-width: 100%;
            transition: all 1s ease;
            display: block;
            width: 100%;
            height: auto;
            transform: scale(1);
        }
        .card-body {
            padding-left: 0;
            padding-right: 0;
            .small-caps {
                font-size: 1.5rem;
                color: $primary;
                @include media-breakpoint-down(ms) {
                    font-size: 1.125rem;
                }
            }
        }
        .plot-details {
            display: flex;
            .item {
                .item-inner {
                    font-size: 0.5vw;
                    @include media-breakpoint-down(md) {
                        font-size: 1rem;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 0.75rem;
                    }
                    @include media-breakpoint-down(ms) {
                        font-size: 0.5rem;
                    }
                }
            }
            span {
                display: block;
            }
            span .primary {
                display: inline;
            }
        }
        .plot-details .item:nth-child(4) .item-inner {
            border-right: 1px solid $border;
        }
    }
    .featured {
        .dev-details.dark {
            padding-left: 2rem;
            padding-right: 2rem;
            .item {
                .item-inner {
                    color: $white;
                    img {
                        fill: #ffffff;
                    }
                }
            }
            background: $primary;
            color: $white;
        }
        .card-body {
            padding: 2rem;
            background: $primary;
            color: $white;
        }
        .btn-primary {
            background: $primary;
            color: $white;
            padding-right: 3rem;
            &:after {
                content: url(/media/images/arrow-right-light.svg);
                right: 12px;
            }
        }
    }
    .btn-area {
        position: absolute;
        display: block;
        bottom: 0;
        right: 0;
    }
    .icon-area {
        width: 5rem;
        height: 5rem;
        background: $primary;
        position: absolute;
        text-align: center;
        color: $white;
        font-size: 2.5rem;
        top: 0;
        right: 0;
        padding: 0.5rem;
        i {
            width: 50%;
            margin: auto;
            position: absolute;
            top: 50%; left: 50%;
            -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
        }
    }
    .btn-primary {
        background: $white;
        padding: 14px 20px;
        height: 40px;
        left: 0;
        transition: left 0.3s ease;
        &:before {
            display: none;
        }
        &:hover {
            left: 5px;
        }
    }
    .title {
        h3 {
            @include font-sans;
            color: $text-color;
            @include media-breakpoint-down(ms) {
                font-size: 2rem;;
            }
        }
    }
}
.welcome-handover-item {
    margin-bottom: 6rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 3rem;
    }
    iframe {
        border: 0;
    }
    .doc {
        background: $white;
            margin: auto;
        img {
        }
    }
    .doc-title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 2rem;
        padding: 0 1rem;
    }
    .doc-details {
        font-size: 1.5rem;
        padding: 0 1rem;
        i {
            color: $primary;
            font-size: 2.5rem;
        }
    }
}
.box {
    background: $white;
    padding: 4rem;
    box-shadow:
        0 0.9px 2.2px rgba(0, 0, 0, 0.02),
        0 2.2px 5.3px rgba(0, 0, 0, 0.028),
        0 4.1px 10px rgba(0, 0, 0, 0.035),
        0 7.4px 17.9px rgba(0, 0, 0, 0.042),
        0 13.8px 33.4px rgba(0, 0, 0, 0.05),
        0 33px 80px rgba(0, 0, 0, 0.07);
}

// Aftercare Portal card

.aftercare-portal {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 1410px;

    &-image {
        width: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    
    &-content {
        background-color: $primary;
        padding: 2rem;
        width: 100%;
        
        h3, p, a {
            color: $white!important;
        }
    }

    &-create-logo {
        height: 24px;
        width: auto;
    }

    &-footer {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-top: 4.8rem;

        .btn-primary {
            margin-bottom: 1.6rem;
            
            &::before {
                background-color: rgba(255, 255, 255, .1);
            }
            
            &:after {
                content: url(/media/images/arrow-right-light.svg);
            }
        }

        & > svg {
            height: 24px;
            width: auto;
        }
    }

    &-icon {
        align-items: center;
        background-color: $primary;
        border-top: 1px solid $white;
        display: flex;
        justify-content: center;
        padding: 2rem;
        width: 100%; 

        svg {
            height: 64px;
            width: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        height: 400px;

        &-image {
            width: 50%;
        }

        &-content {
            display: flex;
            flex-direction: column;
            width: calc(50% - 144px);
        }

        &-footer {
            margin-top: auto;
        }

        &-icon {
            border-left: 1px solid $white;
            border-top: 0;
            justify-content: flex-start;
            overflow: hidden;
            padding-left: 0;
            width: 144px;

           svg {
               height: 144px;
               margin-left: -2px;
               overflow: hidden;
           }
        }
    }
}