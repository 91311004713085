.book-viewing-dev-container {
    .logo {
        width: 150px;
    }
}
.make-a-booking-scroll-margin {
    scroll-margin-top: 100px;
}
.no-before {
     &:before {
        display: none !important;
    }
}