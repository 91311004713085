.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus, .btn-primary.focus {
    box-shadow: none;
    background-color: rgba($primary, 0.3);
    color: $primary;
}
.btn-default {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $primary;
    padding: 0;
    line-height: 1;
    vertical-align: middle;
    padding-right: 2rem;
    &:after {
        content: url(/media/images/arrow-right.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        right: 4px;
    }
}
.btn-map-info {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $white;
    padding: 0;
    line-height: 1;
    vertical-align: middle;
    padding: 0.5rem;
    width: 100%;
    background: $primary;
    text-align: center;
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
        font-weight: 700;
    }
    &:hover {
        background: $light;
        color: $text-color;
    }
}
.bg-dark a.btn-map-info {
    color: $white;
    &:hover {
        color: $text-color;
    }
}
.btn-primary {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $primary;
    // padding: 1rem 2rem 1rem 1rem;
    padding-right: 20px;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    @include media-breakpoint-down(ms) {
        padding: 0.5rem 2rem 0.5rem 1rem;
    }
    &:after {
        content: url(/media/images/arrow-right.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        right: 4px;
        @include media-breakpoint-down(ms) {
            width: 6px;
            height: 6px;
            right: 8px;
        }
    }
    &:before {
        content: '';
        background: rgba($primary, 0.3);
        position: absolute;
        width: 108px;
        transform: scaleX(0.24);
        height: auto;
        bottom: 0;
        left: -3px;
        top: 0px;
        transform-origin: center left;
        transition: transform 0.25s ease-out;
        line-height: 1;
        @include media-breakpoint-down(ms) {
            height: 21px;
            top: -1px;
            width: 84px;
        }
    }
    &:hover {
        background: transparent;
        color: $primary;
        &:before {
           transform: scaleX(1.04);
           transform-origin: center left;
           width: 100%;
        }
    }
    @include media-breakpoint-down(ms) {
        font-size: 1.25rem;
    }
}
.btn-primary.btn-danger {
    color: $red;
    padding-left: 20px;
    padding-right: 20px;
    &:after {
        display: none;
    }
    &:before {
        background: rgba($red, 0.3);
    }
    &:hover {
        color: $red;
        &:before {
            width: 100%;
           // transform: scaleX(1);
        }
    }
}
.btn-primary.btn-alt {
    color: $black;
    &:after {
        display: none;
    }
    &:before {
        background: rgba($black, 0.3);
    }
    &:hover {
        color: $black;
        &:before {
            width: 100%;
           // transform: scaleX(1);
        }
    }
}
.btn-logout {
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
        padding: 0.5rem 2rem 0.5rem 1rem;
    }
    &:before {
        @include media-breakpoint-down(sm) {
            height: 18px;
            top: 1px;
        }
    }
    &:after {
        @include media-breakpoint-down(sm) {
            width: 6px;
            height: 6px;
            right: 8px;
        }
    }
}
.btn-back {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: $primary;
    line-height: 1;
    vertical-align: middle;
    position: relative;
    padding: 1rem 1rem 1rem 2rem;
    transition: opacity 0.3s ease;
    &:before {
        content: url(/media/images/arrow-left.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        left: 4px;
    }
    &:after {
        display: none;
    }
    &:hover {
        color: $primary;
        opacity: 0.5;
    }
}
.btn-back-white {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: $white;
    line-height: 1;
    vertical-align: middle;
    position: relative;
    padding: 1rem 1rem 1rem 2rem;
    transition: opacity 0.3s ease;
    &:before {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/arrow-left-white.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        left: 4px;
    }
    &:after {
        display: none;
    }
    &:hover {
        color: $white;
        opacity: 0.5;
    }
}
.btn-black {
    color: $text-color;
    &:after {
        content: url(/media/images/arrow-right.svg);
    }
    &:before {
        background: rgba($text-color, 0.3);
    }
    &:hover {
        color: $text-color;
    }
}
.btn-white {
    color: $white;
    &:after {
        content: url(/media/images/arrow-right-light.svg);
    }
    &:before {
        background: rgba($white, 0.3);
    }
    &:hover {
        color: $white;
    }
}
.btn-offset {
    position: absolute;
    right: 2vw;
    top: 73%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
}
.btn-square {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: $primary;
    position: relative;
    border: 0;
    border-radius: 0;
    color: rgba($white, 1);
    padding: 3rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    right: 0;
    transition: right 0.3s ease,
                color 0.3s ease;
    &:hover {
        right: 1vw;
        color: rgba($white, $alpha);
    }
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
}
.btn-square.btn-secondary {
    background: $secondary;
}
.btn-full-width {
    width: 100%;
}
.btn-square-small {
    @include small-caps;
    text-align: center;
    display: inline-block;
    background: $primary;
    position: relative;
    border: 0;
    border-radius: 0;
    color: rgba($white, 1);
    padding: 1rem 0.5rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    right: 0;
    transition: right 0.3s ease,
                color 0.3s ease;
    &:hover {
        right: 1vw;
        color: rgba($white, $alpha);
    }
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
    &.dark {
        background: $secondary;
        color: $primary;
    }
}
.btn-play {
    padding-right: 5rem;
    line-height: 1.2;
    &:after {
        content: url(/media/images/icon-play.svg);
        width: 17px;
        height: 17px;
        position: absolute;
        right: 23px;
    }
}
a.underline {
    text-decoration: underline;
}
.plain-link {
    position: relative;
    bottom: 0;
    transition: bottom 0.3s ease,
                color 0.3s ease;
    &:hover {
        text-decoration: none;
        bottom: 5px;
    }
}
.btn-primary-alt {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $primary;
    padding: 1rem 2rem 1rem 1rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    &:after {
        content: url(/media/images/arrow-left.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        left: -4px;
    }
    &:before {
        content: '';
        background: rgba($primary, 0.3);
        position: absolute;
        width: 100%;
        transform: scaleX(0.24);
        height: 28px;
        bottom: 0;
        left: -3px;
        top: 2px;
        transform-origin: center left;
        transition: transform 0.25s ease-out;
    }
    &:hover {
        background: transparent;
        color: $primary;
        &:before {
           transform: scaleX(1.06);
           transform-origin: center left;
        }
    }
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
}
.btn-primary-alt-wo {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $white;
    padding: 1rem 2rem 1rem 1rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    &:after {
        content: url(/media/images/arrow-left-wo.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        left: -4px;
    }
    &:before {
        content: '';
        background: rgba($white, 0.3);
        position: absolute;
        width: 100%;
        transform: scaleX(0.24);
        height: 28px;
        bottom: 0;
        right: 4px;
        top: 2px;
        transform-origin: center right;
        transition: transform 0.25s ease-out;
    }
    &:hover {
        background: transparent;
        color: $white;
        &:before {
           transform: scaleX(1.06);
           transform-origin: center right;
        }
    }
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
}
.btn-primary-alt-black {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $black;
    padding: 1rem 2rem 1rem 1rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    &:after {
        content: url(/media/images/arrow-left-black.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        left: -4px;
    }
    &:before {
        content: '';
        background: rgba($black, 0.3);
        position: absolute;
        width: 100%;
        transform: scaleX(0.24);
        height: 28px;
        bottom: 0;
        right: 4px;
        top: 2px;
        transform-origin: center right;
        transition: transform 0.25s ease-out;
    }
    &:hover {
        background: transparent;
        color: $black;
        &:before {
           transform: scaleX(1.06);
           transform-origin: center right;
        }
    }
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
}
.btn-primary-wo {
    @include small-caps;
    text-align: left;
    display: inline-block;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 0;
    color: $white;
    padding: 1rem 2rem 1rem 1rem;
    line-height: 1;
    vertical-align: middle;
    margin-left: 3px;
    &:after {
        content: url(/media/images/arrow-right-light.svg);
        width: 9px;
        height: 9px;
        position: absolute;
        right: 4px;
    }
    &:before {
        content: '';
        background: rgba($white, 0.3);
        position: absolute;
        width: 100%;
        transform: scaleX(0.24);
        height: 28px;
        bottom: 0;
        left: -3px;
        top: 2px;
        transform-origin: center left;
        transition: transform 0.25s ease-out;
    }
    &:hover {
        background: transparent;
        color: $white;
        &:before {
           transform: scaleX(1.06);
           transform-origin: center left;
        }
    }
    @include media-breakpoint-down(ms) {
        font-size: 1rem;
    }
}