.block-margin {
    margin: 8rem 0;
    @include media-breakpoint-down(md) {
        margin: 5rem 0;
    }
    @include media-breakpoint-down(sm) {
        margin: 3rem 0;
    }
    @include media-breakpoint-down(ms) {
        margin: 2.5rem 0;
    }
}
.divider-space {
    margin: 7rem 0;
    @include media-breakpoint-down(md) {
        margin: 3rem 0
    }
    @include media-breakpoint-down(sm) {
        margin: 4rem 0
    }
}
/*** PADDING ***/
.p-massive {
    padding: 18rem 8rem;
    @include media-breakpoint-down(xl) {
        padding: 10rem 8rem;
    }
    @include media-breakpoint-down(md) {
        padding: 5rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 3rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 2rem;
    }
    &.alt {
        @include media-breakpoint-down(md) {
            padding: 5rem 4rem;
        }
        @include media-breakpoint-down(ms) {
            padding: 4rem 2rem;
        }
    }
}
.p-big {
    padding: 8rem;
    @include media-breakpoint-down(md) {
        padding: 5rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 3rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 2.5rem;
    }
    &.alt {
        @include media-breakpoint-down(md) {
            padding: 5rem 4rem;
        }
        @include media-breakpoint-down(ms) {
            padding: 4rem 2rem;
        }
    }
}
.sticky-intro {
    .p-big {
        padding-top: 0;
    }
}
.p-big.inspiration,
.p-big.offset-slider {
    padding: 14rem 0 1rem 8rem;
    @include media-breakpoint-down(md) {
        padding: 5rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 3rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 2.5rem;
    }
}
.p-big.inspiration {
    padding-bottom: 14rem;
    @include media-breakpoint-down(md) {
        padding: 5rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 3rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 2.5rem;
    }
}
.p-big.upcoming-slider {
    padding: 10rem 8rem 3rem;
    @include media-breakpoint-down(md) {
        padding: 9rem 5rem 3rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 3rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 2.5rem;
    }
}
.p-med {
    padding: 4rem 8rem;
    @include media-breakpoint-down(md) {
        padding: 4rem 5rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 2rem 3rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 1.75rem;
    }
}
.p-small {
    padding: 3rem;
}
.overlap-padding-bottom {
    padding-bottom: 17rem;
    @include media-breakpoint-down(xl) {
        padding-bottom: 12rem;
    }
    @include media-breakpoint-down(lg) {
        padding-bottom: 9rem;
    }
    @include media-breakpoint-down(sm) {
        padding-bottom: 8rem;
    }
    @include media-breakpoint-down(ms) {
        padding-bottom: 8rem;
    }
    &.our-process {
        padding-bottom: 27rem;
    }
}
.overlap-padding-bottom.conference {
    padding-bottom: 23rem;
    @include media-breakpoint-down(xl) {
        padding-bottom: 1rem;
    }
}
.overlap-padding-top {
    padding-top: 7vw;
    @include media-breakpoint-down(lg) {
        padding-top: 7vw;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
    }
}
.offset-left-6 {
    margin-left: 6vw !important;
    @include media-breakpoint-down(sm) {
        margin-left: 0 !important;
    }
}
.offset-right-6 {
    margin-right: 6vw !important;
    @include media-breakpoint-down(sm) {
        margin-right: 0 !important;
    }
    &.content {
        @include media-breakpoint-down(sm) {
            margin-right: 6vw !important;
        }
    }
}
.offset-top-6 {
    margin-top: -6vw !important;
    @include media-breakpoint-down(ms) {
        margin-top: 0 !important;
    }
}
.offset-bottom-6 {
    margin-bottom: -6vw !important;
    @include media-breakpoint-down(ms) {
        margin-bottom: 0 !important;
    }
}
.offset-top-9 {
    margin-top: -9vw !important;
    @include media-breakpoint-down(ms) {
        margin-top: 0 !important;
    }
}
.offset-bottom-9 {
    margin-bottom: -9vw !important;
    @include media-breakpoint-down(ms) {
        margin-bottom: 0 !important;
    }
}
.offset-top-10 {
    margin-top: -10vw !important;
    @include media-breakpoint-down(ms) {
        margin-top: 0 !important;
    }
}
.offset-bottom-10 {
    margin-bottom: -10vw !important;
    @include media-breakpoint-down(ms) {
        margin-bottom: 0 !important;
    }
}
.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
    @include media-breakpoint-down(lg) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    @include media-breakpoint-down(md) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}