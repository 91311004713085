.tool {
    background-color: $light;
    margin: 6rem 0;
    @include media-breakpoint-down(md) {
        margin: 6rem 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 6rem 0 0;
    }
    @include media-breakpoint-down(ms) {
        padding: 0;
        margin-bottom: 1rem;
        margin-top: 9rem;
    }
    h3 {
        color: $text-color;
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
            font-size: 1.875rem;
            margin-bottom: 1rem;
        }
    }
    .description {
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }
    img {
        max-width: 170px;
    }
}

.absolute-title-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 230px;
    z-index: 2;
    h1 {
        @include media-breakpoint-down(ms) {
            font-size: 1.5rem;
        }
    }
}