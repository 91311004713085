$font-size-base: 1.75rem;
// Body
$body-bg: #f8fafc;

// Colors
$primary: #AF8657;
$primary-dark: #72532E;
$modal: #6E5438;
$secondary: #222222;
$secondary-light: #3E3E3E;
$dark: $secondary;
$light: #EFECE7;
$extra-light: #FAF8F4;
$light-grey: #EFEFEF;
$reserved: #69B6D8;
$sold: #DE6789;
$soon: #882370;
$available: #FF9D00;
$apartment: #c5ca49;
$showhome: #D9E7A0;
$on-hold: #C1C1C1;
$border: #C3C3C3;
$mid-grey: #D9D9D9;
$grey: #cccccc;
$dark-grey: #343434;
$danger: #BA2B5D;
$black: #000000;
$info: #c8c8c8;
$silver: #C3C3C3;
$white: #ffffff;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #882370;
$pink: #f66D9b;
$red: #e3342f;
$orange: #BE4407;
$yellow: #E3B140;
$green: #38c172;
$teal: #387780;
$cyan: #6cb2eb;
$text-color: #222222;

$house-type-opacity: 0.8;

$icon-border-width: 2px;

// Alpha
$solid: 1.0;
$alpha: 0.8;

// FORMS
$input-placeholder-color: $primary;

// NAVIGATION

// colors

$color-1: #25283D; // YANKEES BLUE
$color-2: #8F3985; // PLUM
$color-3: $primary;
$color-4: #ECF0F1; // ANTI-FLASH WHITE

// fonts

$primary-font: caecilia, sans-serif;
$secondary-font: proxima-nova, sans-serif;

// header size

$header-height-small: 60px;
$header-height: 80px;

$secondary-nav-height-small: 100px;
$secondary-nav-height: 90px;

$secondary-margin-offset: 55px;





@mixin grad {
  background: rgba(29,84,149,1);
  background: -moz-linear-gradient(top,  rgba(0,181,226,1) 0%, rgba(29,84,149,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,181,226,1) 0%,rgba(29,84,149,1) 100%);
  background: linear-gradient(to bottom,  rgba(0,181,226,1) 0%,rgba(29,84,149,1) 100%);
}

@mixin grad-dark {
  background: rgb(29,84,149);
  background: -moz-linear-gradient(top,  rgba(29,84,149,1) 0%, rgba(15,42,75,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(29,84,149,1) 0%,rgba(15,42,75,1) 100%);
  background: linear-gradient(to bottom,  rgba(29,84,149,1) 0%,rgba(15,42,75,1) 100%);
}

@mixin grad-grey {
  background: rgb(226,226,226);
  background: -moz-linear-gradient(top,  rgba(226,226,226,1) 0%, rgba(193,192,192,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(193,192,192,1) 100%);
  background: linear-gradient(to bottom,  rgba(226,226,226,1) 0%,rgba(193,192,192,1) 100%);
}

@mixin grad-red {
  background: rgb(186,43,93);
  background: -moz-linear-gradient(top,  rgba(186,43,93,1) 0%, rgba(118,25,57,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(186,43,93,1) 0%,rgba(118,25,57,1) 100%);
  background: linear-gradient(to bottom,  rgba(186,43,93,1) 0%,rgba(118,25,57,1) 100%);
}

@mixin transition {
    transition: background 0.3s ease,
                   opacity 0.3s ease,
                     color 0.3s ease,
                      left 0.3s ease;
}

@mixin boxshadow {
  -webkit-box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.2);
  box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.2);

}

$grid-breakpoints: (
  xs: 0,
  ms: 415px,
  plus: 415px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

$container-max-widths: (
  ms: 430px,
  plus: 430px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1410px
);

$cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3
);

$two-cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
);

$three-cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3
);

$four-cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3,
    xxl: 4
);

