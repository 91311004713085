* {
    margin: 0; padding: 0;
    box-sizing: border-box;
}

// Variables
$radius: 0;

html,body {
    background: #f7f8fb;
}

.month {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 1em;
}
.date-selection {
    background: darken($light, 10%);
    padding: 2rem;
    .sub-title {
        margin-bottom: 0.5rem;
        color: $primary;
    }
}
.calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-only(xl) {
        width: 311px;
    }
    @include media-breakpoint-only(plus) {
        width: 320px;
        margin: 0 auto;
    }
    &_left {
        background: $primary;
        width: 100%;
        height: 410px;
        padding: 2em;
        z-index: 1;
        color: $primary;
        @include media-breakpoint-down(xl) {
            padding: 2rem;
        }
        @include media-breakpoint-down(lg) {
            padding: 2rem;
            height: auto;
        }
        @include media-breakpoint-down(md) {
            padding: 2rem;
        }
        @include media-breakpoint-down(sm) {
            padding: 2rem;
        }
        @include media-breakpoint-only(ms) {
            padding: 4.5rem;
        }
        @include media-breakpoint-down(ms) {
            padding: 2rem;
        }
        .header {
            display: flex;
            justify-content: space-around;
            margin-bottom: 2em;
            color: #FFF;
            font-size: 0.7em;
            h1 {
                line-height: 1em;
            }
            i {
                cursor: pointer;
                @include media-breakpoint-down(ms) {
                    line-height: 3;
                }
            }
        }
    }
    &_right {
        background: darken($primary, 5%);
        width: 350px;
        height: 415px;
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
        position: relative;
        transform: scale(0.95) translateX(-10px);
        z-index: 0;
        .list {
            height: 351px;
            overflow-y: scroll;
            padding: 1em;
            ul {
                padding: 2.25em;
            }
            li {
                padding: 1em;
                width: 180px;
                color: #FFF;
                transform: translateX(-700px);
            }
            .description {
                font-size: 12px;
            }
        }
        form {
            position: absolute;
            bottom: 0;
            display: flex;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
        }
        input {
            background: darken($primary, 7%);
            border: none;
            padding: 1.2em;
            flex: 2;
            outline: none;
            color: #FFF;
            border-bottom-right-radius: $radius;
        }
        select {
            background: darken($primary, 11%);
            border: none;
            padding: 1.2em;
            outline: none;
            color: #FFF;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: $radius;
            appearance: none;
        }
    }
}

.days {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .day_item {
        color: $white;
        width: 14.28%;
        text-align:center;
        padding-bottom: 1em;
        @include media-breakpoint-only(xl) {
            font-size: 0.75rem;
        }
        @include media-breakpoint-down(lg) {
            height: 47px;
        }
        @include media-breakpoint-down(ms) {
            width: 14.28%;
        }
    }
}

.dates {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .date_item {
        color: $white;
        width: 14.28%;
        font-size: 1.25rem;
        text-align:center;
        height: 50px;
        padding: 1em;
        line-height: 1.5;
        cursor: pointer;
        border-radius: 100%;
        @include media-breakpoint-down(xl) {
            height: 32px;
        }
        @include media-breakpoint-down(lg) {
            height: 47px;
            line-height: 1.4;
        }
        @include media-breakpoint-only(plus) {
            height: 36px;
            padding: 0;
        }
        @include media-breakpoint-down(ms) {
            height: 35px;
            width: 14.28%;
            line-height: 0.5;
            padding: 0;
        }
    }
}
.times {
    .form-check {
        padding-left: 2rem;
    }
    .form-check-input {
        margin-left: -2rem;
    }
}
.selected {
    background: $white;
    transform: scale(0.7);
    border-radius: 50px;
    padding: 0.95em !important;
    color: $primary !important;
    z-index: 0;
    box-shadow: 5px 5px 15px lighten($primary, 1%);
    animation: bounce-button-in .45s 0s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
    @include media-breakpoint-only(xl) {
        left: 5px;
        padding: 0 !important;
        padding-top: 5px !important;
        position: relative;
        top: 9px;
    }
    @include media-breakpoint-down(ms) {
        padding: 12px !important;
    }
}

.inactive {
    opacity: 0.3;
    cursor: default !important;
}

// Browser Specific Overwrites
::-webkit-scrollbar {
    display: none;
}

::-webkit-input-placeholder {
     color: $primary;
}

.booking-list {
    text-align: center;
    font-size: 1rem;
    @include media-breakpoint-down(lg) {
        border-top: 1px solid $grey;
    }
    @include media-breakpoint-down(lg) {
        text-align: left;
    }
    .title-row {
        background: $primary !important;
        color: $white;
        padding: 1.25rem 0;
        &.alt {
            background: $secondary !important;
        }
        .title {
            font-weight: 700;
        }
    }
    .booking-row {
        background: lighten($grey, 10%);
        padding: 1rem 0;
        @include media-breakpoint-down(lg) {
            font-size: 1rem;
            border-bottom: 1px solid $grey;
        }
        div {
            @include media-breakpoint-down(lg) {
                padding: 0.5rem;
            }
        }
        .btn {
            @include media-breakpoint-down(lg) {
                float: right;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-top: 1rem;
            }
        }
    }
    .mobile-title {
        font-weight: 700;
    }
}
.booking-list .booking-row:nth-of-type(even) {
    background: $white;
}
// Animations
.bounce-in.ng-animate {
    animation: none 0s;
}

.bounce-in {
    animation: bounce-in .9s 0s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
}

@keyframes bounce-in {
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(7px);
    }
    100% {
        transform: translateX(2px);
    }
}

@keyframes bounce-button-in {
    0% {
        transform: translateZ(0) scale(0);
    }
    100% {
        transform: translateZ(0) scale(0.7);
    }
}