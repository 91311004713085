
/* --------------------------------

Primary style

-------------------------------- */

*, *::after, *::before {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    color: $color-1;
    background-color: $light;
    @include font-smoothing;
}

a {
    text-decoration: none;
}

/* --------------------------------

1. Auto-Hiding Navigation - Simple

-------------------------------- */

.cd-auto-hide-header {
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height-small;
    background-color: darken($primary, 6%);
    @include clearfix;
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform .5s,
                background 0.3s ease;

    &.is-hidden {
        transform: translateY(-100%);
    }

    @include MQ(M) {
        height: $header-height;
    }
}

.cd-auto-hide-header .logo,
.cd-auto-hide-header .nav-trigger {
    position: absolute;
    @include center(y);
}

.cd-auto-hide-header .logo {
    left: 5%;
    a, img {
        display: flex;
        height: 28px;
        @include media-breakpoint-down(sm) {
            height: 21px;
        }
        @include media-breakpoint-down(ms) {
            height: 17px;
        }
    }
}

.cd-auto-hide-header .nav-trigger {
    /* vertically align its content */
    display: table;
    height: 100%;
    padding: 0 1em;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $color-1;
    font-weight: bold;
    right: 0;
    border-left: 1px solid shade($color-3, 5%);

    span {
        /* vertically align inside parent element */
        display: table-cell;
        vertical-align: middle;
    }

    em, em::after, em::before {
        /* this is the menu icon */
        display: block;
        position: relative;
        height: 2px;
        width: 22px;
        background-color: $color-1;
        backface-visibility: hidden;
    }

    em {
        /* this is the menu central line */
        margin: 6px auto 14px;
        transition: background-color .2s;
    }

    em::before, em::after {
        position: absolute;
        content: '';
        left: 0;
        transition: transform .2s;
    }

    em::before {
        /* this is the menu icon top line */
        transform: translateY(-6px);
    }

    em::after {
        /* this is the menu icon bottom line */
        transform: translateY(6px);
    }

    @include MQ(L) {
        display: none;
    }
}

.cd-auto-hide-header.nav-open .nav-trigger {
    em {
        /* transform menu icon into a 'X' icon */
        background-color: rgba($color-3, 0);
    }

    em::before {
        /* rotate top line */
        transform: rotate(-45deg);
    }

    em::after {
        /* rotate bottom line */
        transform: rotate(45deg);
    }
}

.cd-primary-nav {
    display: inline-block;
    float: right;
    height: 100%;
    padding-right: 5%;

    > ul {
        position: absolute;
        z-index: 2;
        top: $header-height-small;
        left: 0;
        width: 100%;
        background-color: $color-3;
        display: none;
        box-shadow: 0 14px 20px rgba(#000, .2);

        a {
            /* target primary-nav links */
            display: block;
            height: 50px;
            line-height: 50px;
            padding-left: 5%;
            color: $color-1;
            font-size: 1.8rem;
            border-top: 1px solid shade($color-3, 5%);

            &:hover, &.active {
                color: $color-2;
            }
        }
    }

    @include MQ(L) {
        /* vertically align its content */
        display: table;

        > ul {
            /* vertically align inside parent element */
            display: table-cell;
            vertical-align: middle;
            /* reset mobile style */
            position: relative;
            width: auto;
            top: 0;
            padding: 0;
            @include clearfix;
            background-color: transparent;
            box-shadow: none;

            li {
                display: inline-block;
                float: left;
                margin-right: 1.5em;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            a {
                /* reset mobile style */
                height: auto;
                line-height: normal;
                padding: 0;
                border: none;
            }
        }
    }
}

.nav-open .cd-primary-nav ul,
.cd-primary-nav ul:target {
    /*
        show primary nav - mobile only
        :target is used to show navigation on no-js devices
    */
    display: block;

    @include MQ(L) {
        display: table-cell;
    }
}

/* --------------------------------

2. Auto-Hiding Navigation - with Sub Nav

-------------------------------- */
.search {
    background-color: $white;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.175);
}
// .sold {
//     background-color: $orange;
//     box-shadow: 0 0.5rem 1rem rgba(0,0,0,.175);
//     @include small-caps;
//     font-size: 1.5vw;
//     line-height: 1;
//     color: $white;
//     text-align: center;
//     display:block;
//     position: relative;
//     z-index: 1;
//     p {
//         margin-bottom: 0;
//     }
//     @include media-breakpoint-up(xxl) {
//         font-size: 1vw;
//     }
//     @include media-breakpoint-down(ms) {
//         font-size: 3.5vw;
//     }
//     .item {
//         height: 90px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
// }
.margin-offset {
    top: $secondary-margin-offset;
}
.header-transparent {
    // background: transparent;
    // transition: background 0.3s ease;
}
.header-gold {
    background: $primary;
    transition: background 0.3s ease;
}
.cd-secondary-nav-none-sticky,
.cd-secondary-nav {
    top: -$secondary-nav-height / 2;
    position: relative;
    z-index: 11;
    clear: both;
    width: 1410px;
    left: 50%;
    margin-left: -705px;
    height: $secondary-nav-height;
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform .5s;
    @include media-breakpoint-down(xl) {
        width: 1140px;
        margin-left: -570px;
    }
    @include media-breakpoint-down(lg) {
        width: 960px;
        margin-left: -480px;
    }
    @include media-breakpoint-down(md) {
        width: 720px;
        margin-left: -360px;
    }
    @include media-breakpoint-down(sm) {
        width: 80%;
        left: 10%;
        margin-left: 0;
    }
    &.nav-end::after {
        opacity: 0;
    }

    ul, li, a {
        height: 100%;
    }

    ul {
        /* enables a flex context for all its direct children */
        display: flex;
        padding: 0 5%;
        @include clearfix;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    li {
        display: inline-block;
        float: left;
        /* do not shrink - elements float on the right of the element */
        flex-shrink: 0;

        &:last-of-type {
            padding-right: 20px;
        }
    }

    a {
        display: block;
        color: $color-3;
        opacity: .6;
        line-height: $secondary-nav-height-small;
        padding: 0 1em;
        transition: color 0.3s ease,
                    opacity 0.3s ease;
        &:hover, &.active {
            opacity: 1;
            text-decoration: none;
        }
    }
    @include media-breakpoint-down(lg) {
        height: $secondary-nav-height;
        overflow: visible;

        ul {
            /* reset mobile style */
            display: block;
            text-align: center;
        }

        li {
            /* reset mobile style */
            float: none;
            flex-shrink: 1;
        }

        a {
            line-height: $secondary-nav-height;

            &.active {
                box-shadow: inset 0 -3px $color-2;
            }
        }
    }
    @include media-breakpoint-down(md) {
        height: $secondary-nav-height-small;
        top: -$secondary-nav-height-small / 2;
    }
    @include media-breakpoint-down(ms) {
        height: 92px;
        top: -$secondary-nav-height-small / 2;
    }
}
.cd-secondary-nav.sold {
    width: 220px;
    margin-left: -110px;
    height: 88px;
    &:before {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        top: 0px;
        left: -3vw;
        border-top: 3vw solid #BE4407;
        border-bottom: 3vw solid #BE4407;
        border-right: 3vw solid #BE4407;
        border-left: 3vw solid #0000;
    }
    &:after {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        top: 0px;
        right: -3vw;
        border-top: 3vw solid #BE4407;
        border-bottom: 3vw solid #BE4407;
        border-right: 3vw solid #0000;
        border-left: 3vw solid #BE4407;
    }
}
.menu .menu-search {
    opacity: 0;
    transition: opacity 0.3s ease;
}
.menu.menu--open .menu-search {
    opacity: 1;
}
.menu-search {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
    @include media-breakpoint-down(ms) {
        bottom: 13%;
        top: inherit;
    }
}
.cd-secondary-nav-none-sticky {
    // position: static;
    // width: 100%;
    // margin-left: 24px;
    // margin-bottom: -88px;
    top: 0;
    z-index: 1;
    margin-bottom: 0;
    position: static;
    left: 0;
    height: inherit;
    width: auto;
}
.serach-results-property-search {
    margin-bottom: 6rem;
    margin-top: 6rem;
    .cd-secondary-nav-none-sticky {
        margin-left: 0;
    }
}
/* --------------------------------

3. Auto-Hiding Navigation - with Sub Nav + Hero Image

-------------------------------- */

.cd-secondary-nav {
    &.fixed {
        position: fixed;
        top: $header-height-small;
        @include media-breakpoint-down(ms) {
            /* Make Mobile search nav none sticky */
            position: relative;
            top: -$secondary-nav-height / 2;
        }
    }

    &.slide-up {
        transform: translateY(-$header-height-small)
    }

    @include MQ(L) {

        &.fixed {
            top: $header-height;
            /* fixes a bug where nav and subnab move with a slight delay */
        }

        &.slide-up {
            transform: translateY(-$header-height);
        }
    }
    @include MQ(S) {

        &.fixed {
            position: stitic;
        }
    }
}


/* --------------------------------

Main content

-------------------------------- */

.cd-main-content {
    // overflow: hidden;

    &.sub-nav {
        /* to be used if there is sub nav */
        margin-top: $header-height-small + $secondary-nav-height-small;
    }

    &.sub-nav-hero {
        /* to be used if there is hero image + subnav */
        margin-top: 0;

        &.secondary-nav-fixed {
            margin-top: $secondary-nav-height-small;
            @include media-breakpoint-down(ms) {
                margin-top: 0;
            }
        }
    }
    &.no-sticky-nav {
        margin-top: 0 !important;
    }

    @include MQ(L) {
        margin-top: $header-height;
        &.sub-nav {
            margin-top: $header-height + $secondary-nav-height;
        }
        &.sub-nav-hero.secondary-nav-fixed {
            margin-top: $secondary-nav-height;
            background-color: $light;
        }
        &.no-sticky-nav {
            margin-top: 0 !important;
        }
    }
}


/*
    adjust the positioning of in-page links
    http://nicolasgallagher.com/jump-links-and-viewport-positioning/
*/

.cd-main-content.sub-nav :target::before,
.cd-main-content.sub-nav-hero :target::before {
        display: block;
        content: "";
        margin-top: -$secondary-nav-height-small;
        height: $secondary-nav-height-small;
        visibility: hidden;
        @include MQ(L) {
            margin-top: -$secondary-nav-height;
            height: $secondary-nav-height;
        }
}