html {
  scroll-behavior: smooth;
}
body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.bold {
    font-weight: 700;
}
.grecaptcha-badge {
    display: none;
}
.relative {
    position: relative;
}
.inline {
    display: inline;
}
.h-100vw {
    height: 100vw;
}
.absolute-right {
    position: absolute;
    right: 0;
}
hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.bl-primary {
    border-left: 17px solid $primary;
    @include media-breakpoint-down(sm) {
        border-left: 0;
    }
}
.indent-one {
    margin-left: 5rem;
    h1,h3,h3,h4,h5 {
        margin-top: 0;
    }
    @include media-breakpoint-down(md) {
        margin-left: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
}
.indent-two {
    margin-left: 10rem;
    @include media-breakpoint-down(md) {
        margin-left: 5rem;
    }
    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.breadcrumb {
    background: transparent;
    font-size: 1.25rem;
    padding-left: 0;
    padding-right: 0;
    .breadcrumb-item {
        &.active {
            color: $text-color;
        }
    }
}
.breadcrumb-light {
    .breadcrumb-item {
        a {
            color: rgba($white, 0.5);
        }
        &.active {
            color: $white;
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        color: rgba($white, 0.5);
    }
}
.breadcrumb-white {
    .breadcrumb-item {
        &:before {
            color: rgba($white, 0.5);
        }
        a {
            color: $white;
        }
        &.active {
            color: rgba($white, 0.5);
        }
    }
}
.share-list {
    ul.social-list {
        @include small-caps;
        position: static;
        width: inherit;
        @include media-breakpoint-down(sm) {
            top: 1.875rem;
            right: 6rem;
        }
        &:after {
            content: '';
            display: none;
            @include media-breakpoint-down(sm) {
                margin-left: 2rem;
            }
        }
        li {
            &:not(:last-child) {
                margin-right: 2rem;
                @include media-breakpoint-down(sm) {
                    margin-right: 1rem;
                }
            }
            a {
                color: $white;
                text-decoration: none;
                position: relative;
                left: 0;
                opacity: 1;
                transition: left 0.3s ease,
                            opacity 0.3s ease;
                &:hover {
                    left: 3px;
                    opacity: 0.5;
                }
            }
            &.text {
                &:after {
                    content: '';
                    border-right: 1px solid rgba($primary, $alpha);
                    margin-left: 2rem;
                    @include media-breakpoint-down(sm) {
                        margin-left: 1rem;
                    }
                }
            }
        }
        &.primary {
            li {
                a {
                    color: $primary;
                }
            }
        }
        &.bigger {
            li {
                a i {
                    font-size: 2.5rem;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
.h-100-vh {
    height: 100vh;
}
.cta-section {
    background: $primary;
    .text-image-box {
        .promotion-tab {
            z-index: 2;
        }
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                display: flex;
                margin-top: 0;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-top: 0;
                    height: 70vw;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: $primary;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                @include media-breakpoint-down(ms) {
                    padding: 3rem;
                }
                .dev-logo {
                    padding: 1vw 0;
                    @include media-breakpoint-down(md) {
                        padding: 0 0 2vw;
                    }
                    @include media-breakpoint-down(sm) {
                        height: inherit;
                        padding: 0 25vw 4vw;
                    }
                }
                h2 {
                    color: $white;
                    font-size: 3rem;
                    margin-bottom: 1.25rem;
                    @include media-breakpoint-down(md) {
                        font-size: 2.25rem;
                        margin-bottom: 2vw;
                        margin-left: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 2rem;
                        margin-bottom: 4vw;
                    }
                    @include media-breakpoint-down(ms) {
                        // font-size: 5vw;
                    }
                }
                .sub-title {
                    color: $primary;
                    opacity: 1;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            .description {
                border-top: 1px solid rgba($primary, 0.2);
                margin-top: 2rem;
                padding-top: 2rem;
                font-size: 1.5rem;
                @include media-breakpoint-down(xl) {
                    font-size: 1.25rem;
                }
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
    }
}
/***BUFFER WHEEL**/
.loader {
    border: 16px solid $white !important;
    border-top: 16px solid $primary !important;
}
/*****************/
.z2 {
    position: relative;
    z-index: 2;
}
.texture-grey {
    // background: url(/media/images/texture-grey.jpg) top left;
    background: #e0dedc;
}
.texture-grey-alt:after {
    content: url(/media/images/texture-grey.jpg);
    position: absolute;
    top: 0;
    left: -70%;
    bottom: 0;
}
.smallprint {
    font-size: 60%;
    line-height: 1.4;
    display: block;
}
.script-text {
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        height: inherit;
        top: 0;
        z-index: 1;
        pointer-events: none;
        @include media-breakpoint-down(ms) {
            bottom: 0;
            top: inherit;
        }
    }
}
.designer-living {
    &:after {
        content: url(/media/images/text-designer-living.svg);
        width: 13vw;
        right: 51vw;
        @include media-breakpoint-down(xl) {
            width: 15vw;
            right: 48vw;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
.quality {
    &:after {
        content: url(/media/images/text-quality.svg);
        width: 24vw;
        right: -6vw;
        @include media-breakpoint-down(md) {
            top: 70px;
            width: 32vw;
        }
        @include media-breakpoint-down(ms) {
            width: 36vw;
            right: -15vw;
        }
    }
}
.bolton {
    &:after {
        content: url(/media/images/text-bolton.svg);
        width: 19vw;
        right: -2vw;
        bottom: -5vw;
        top: inherit;
        @include media-breakpoint-down(ms) {
            width: 36vw;
            right: -6vw;
        }
    }
}
.lifestyle {
    &:after {
        content: url(/media/images/text-lifestyle.svg);
        width: 15vw;
        right: -5vw;
        @include media-breakpoint-down(xl) {
            width: 12vw;
        }
        @include media-breakpoint-down(lg) {
            width: 13vw;
            right: -5vw;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
.news-and-offers {
    &:after {
        content: url(/media/images/text-news-and-offers.svg);
        width: 12vw;
        right: -1vw;
        @include media-breakpoint-down(lg) {
            width:16vw;
            right: -1vw;
        }
        @include media-breakpoint-down(sm) {
            width: 22vw;
            right: -1vw;
        }
        @include media-breakpoint-down(ms) {
            width: 31vw;
            right: -1vw;
        }
    }
}
.news-and-offers-banner {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-news-and-offers-banner.svg);
        width: 10vw;
        right: 0vw;
        top: 3vw;
        @include media-breakpoint-down(md) {
            width: 15vw;
            right: 1vw;
            top: 6vw;
        }
        @include media-breakpoint-down(ms) {
            width: 17vw;
            right: 1vw;
            top: 15vw;
        }
    }
}
.text-developments {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-developments.svg);
        width: 13vw;
        right: -1vw;
        top: 3vw;
        @include media-breakpoint-down(md) {
            width: 20vw;
            right: -4vw;
            top: 7vw;
        }
        @include media-breakpoint-down(ms) {
            width: 23vw;
            right: -4vw;
            top: 15vw;
        }
    }
}
.text-inspiration-banner {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-inspiration-banner.svg);
        width: 16vw;
        right: -3vw;
        top: 4vw;
        @include media-breakpoint-down(md) {
            width: 25vw;
            right: -6vw;
        }
        @include media-breakpoint-down(ms) {
            width: 27vw;
            right: -6vw;
            top: 15vw;
        }
    }
}
.text-newsletter {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-newsletter.svg);
        width: 13vw;
        right: -1vw;
        top: 3vw;
        @include media-breakpoint-down(ms) {
            width: 23vw;
            right: -4vw;
            top: 15vw;
        }
    }
}
.designer-style {
    &:after {
        content: url(/media/images/text-designer-style.svg);
        width: 28vw;
        right: -8vw;
        @include media-breakpoint-down(lg) {
            width: 58vw;
            right: -23vw;
        }
        @include media-breakpoint-down(ms) {
            width: 41vw;
            right: -13vw;
            top: 0;
        }
    }
}
.designer-style-dark {
    &:after {
        content: url(/media/images/text-designer-style-dark.svg);
        width: 28vw;
        right: -8vw;
        opacity: 0.1;
        @include media-breakpoint-down(lg) {
            width: 58vw;
            right: -23vw;
        }
        @include media-breakpoint-down(ms) {
            width: 41vw;
            right: -13vw;
            top: 0;
        }
    }
}
.keep-up-to-date {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-keep-up-to-date.svg);
        width: 102vw;
        right: -1vw;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
    }
}
.keep-up-to-date-light {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-keep-up-to-date-light.svg);
        width: 102vw;
        right: -1vw;
        top: 92%;
        transform: translateY(-50%);
        z-index: 1;
        opacity: 0.2;
        @include media-breakpoint-down(ms) {
            top: 48%;
            opacity: 1;
        }
    }
}
.keep-up-to-date-light-side {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-keep-up-to-date-light-side.svg);
        width: 8vw;
        left: 0;
        top: 0;
        z-index: 1;
        @include media-breakpoint-down(xl) {
            width: 13vw;
        }
    }
}
.contemporary-living {
    &:after {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-contemporary-living.svg);
        width: 102vw;
        right: -1vw;
        z-index: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.inspiration {
    &:after {
        content: url(/media/images/text-inspiration.svg);
        width: 102vw;
        right: -1vw;
        top: inherit;
        bottom: -10vw;
        z-index: 0;
    }
}
.inspiration-watermark {
    position: absolute;
    left: 0;
    right: 0;
    img {
        width: 100vw;
    }
}
.inspiration-middle {
    &:before {
        content: url(/media/images/text-inspiration.svg);
        width: 102vw;
        right: -1vw;
        top: 500px;
        bottom: 0;
        z-index: 0;
        position: absolute;
        @include media-breakpoint-down(sm) {
            top: 476px;
        }
    }
}
.received-middle {
    &:before {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-received.svg);
        width: 102vw;
        right: -1vw;
        top: 500px;
        bottom: 0;
        z-index: 0;
        position: absolute;
        opacity: 0.1;
    }
}
.revelations-middle {
    &:before {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-revelations.svg);
        width: 102vw;
        right: -1vw;
        top: 500px;
        bottom: 0;
        z-index: 0;
        position: absolute;
        opacity: 0.1;
    }
}
.the-experts-middle {
    &:before {
        content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/text-the-experts.svg);
        width: 102vw;
        right: -1vw;
        top: 540px;
        bottom: 0;
        z-index: 0;
        position: absolute;
        opacity: 0.1;
        @include media-breakpoint-down(ms) {
            top: 630px;
        }
    }
}
.inspiration-alt {
    &:after {
        content: url(/media/images/text-inspiration.svg);
        width: 102vw;
        right: -1vw;
        bottom: inherit;
        top: -25vw;
        z-index: 0;
    }
}
.take-a-look {
    &:after {
        content: url(/media/images/text-take-a-look.svg);
        width: 102vw;
        right: -1vw;
        top: inherit;
        bottom: -4vw;
        z-index: 0;
    }
}
.here-to-help {
    &:after {
        content: url(/media/images/text-here-to-help.svg);
        width: 64vw;
        right: 0;
        top: inherit;
        bottom: -5vw;
        z-index: 0;
        @include media-breakpoint-down(lg) {
            width: 104vw;
            bottom: -9vw;
        }
    }
}
.signature {
    max-height: 124px;
    display: block;
    width: fit-content;
    margin: 2rem 0;
    @include media-breakpoint-down(sm) {
        width: 110px;
    }
}
.pagination {
    border-radius: 0;
    border: 0;
    .page-link {
        background: transparent;
        border: 0;
        font-weight: 700;
        &:focus {
            box-shadow: none;
        }
    }
}
.page-item.disabled .page-link {
    background: transparent;
    color: rgba($primary, 0.3);
}
// .cta-strip {
//     font-size: 2.25rem;
//     @include media-breakpoint-down(sm) {
//         font-size: 1.75rem;
//     }
//     h2 {
//         font-size: 3.5rem;
//         @include media-breakpoint-down(sm) {
//             font-size: 2.5rem;
//         }
//     }
//     .checkbox {
//         margin-top: 2rem;
//     }
//     .btn {
//         margin-top: 2rem;
//     }
// }
.cta-strip {
    background: $secondary;
    color: $white;
    .text-image-box {
        .promotion-tab {
            z-index: 2;
        }
        .content-area {
            .content {
                margin-right: 0;
                form {
                    margin-top: 3rem;
                    @include media-breakpoint-down(sm) {
                        margin-top: 1rem;
                    }
                }
            }
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                display: flex;
                margin-top: 0;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-top: 0;
                    height: 70vw;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: $primary;
                padding: 6vw;
                font-size: 2.25rem;
                line-height: 1.4;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(md) {
                    font-size: 1.75rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 1.5rem;
                }
                h2 {
                    color: $white;
                    font-size: 2vw;
                    @include media-breakpoint-down(md) {
                        font-size: 2vw;
                        margin-bottom: 2vw;
                        margin-left: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 5vw;
                        margin-bottom: 2rem;
                    }
                }
                .date {
                    @include small-caps;
                    margin-bottom: 1rem;
                }
                .author {
                    @include small-caps;
                    font-size: 1.25rem;
                    color: $primary;
                }
                .btn-primary {
                    margin-top: 4rem;
                    @include media-breakpoint-down(sm) {
                        margin-top: 2rem;
                    }
                }
                .sub-title {
                    color: $text-color;
                    opacity: 1;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            .description {
                padding-top: 2rem;
                @include media-breakpoint-down(xl) {
                    font-size: 1.25rem;
                }
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
    }
}
.highlight-strip {
    background: $secondary;
    color: $white;
    margin-bottom: 1.5rem;
    .text-image-box {
        .promotion-tab {
            z-index: 2;
        }
        .content-area {
            .content {
                margin-right: 0;
                form {
                    margin-top: 3rem;
                    @include media-breakpoint-down(sm) {
                        margin-top: 1rem;
                    }
                }
                label.checkbox {
                    font-size: 2.25rem;
                    @include media-breakpoint-down(lg) {
                        height: inherit;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 1.75rem;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 1.5rem;
                    }
                }
                input[type=checkbox]
                {
                  -ms-transform: scale(1.5); /* IE */
                  -moz-transform: scale(1.5); /* FF */
                  -webkit-transform: scale(1.5); /* Safari and Chrome */
                  -o-transform: scale(1.5); /* Opera */
                  transform: scale(1.5);
                  padding: 10px;
                  @include media-breakpoint-down(sm) {
                      -ms-transform: scale(1); /* IE */
                      -moz-transform: scale(1); /* FF */
                      -webkit-transform: scale(1); /* Safari and Chrome */
                      -o-transform: scale(1); /* Opera */
                      transform: scale(1);
                  }
                }
            }
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                display: flex;
                margin-top: 0;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 70vw;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: $primary;
                padding: 3vw !important;
                font-size: 2.25rem;
                line-height: 1.4;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(md) {
                    font-size: 1.75rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 1.5rem;
                    padding: 2rem !important;
                }
                h2 {
                    color: $white;
                    font-size: 3.5rem;
                    margin-top: 0;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 2vw;
                        margin-left: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 2rem;
                        margin-bottom: 0.5rem;
                    }
                }
                .date {
                    @include small-caps;
                    margin-bottom: 1rem;
                }
                .author {
                    @include small-caps;
                    font-size: 1.25rem;
                    color: $primary;
                }
                .btn-primary {
                    margin-top: 4rem;
                    @include media-breakpoint-down(sm) {
                        margin-top: 2rem;
                    }
                }
                .sub-title {
                    color: $text-color;
                    opacity: 1;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            .description {
                padding-top: 2rem;
                @include media-breakpoint-down(xl) {
                    font-size: 1.25rem;
                }
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
    }
}
blockquote {
    color: $primary;
    border-bottom: 1px solid $primary;
    border-top: 1px solid $primary;
    padding: 2rem 0;
    margin: 5rem 0;
    position: relative;
    text-align: center;
    @include media-breakpoint-down(sm) {
        margin: 3rem 0;
        padding: 3rem 0;
    }
    &:before,&:after {
        content: '';
        background-repeat: no-repeat;
        position: absolute;
        width: 40px;
        height: 40px;
        @include media-breakpoint-down(sm) {
            width: 20px;
            height: 20px;
        }
    }
    &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 30.4 24.2' style='enable-background:new 0 0 30.4 24.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D%0A%3C/style%3E%3Cpath id='Path_1185' class='st0' d='M15.7,16.8c0,4.3,2.6,7.4,6.6,7.4c3.5,0,5.3-2.1,5.3-5.3c0.1-2.2-1.6-4-3.8-4.1c0,0-0.1,0-0.1,0 c-1-0.1-2,0.2-2.9,0.7c-0.4-1.1-0.5-2.2-0.5-3.3c0-4.6,4-8.5,10.1-10.9L30,0C21.7,2.8,15.7,8.8,15.7,16.8z M0,16.8 c0,4.3,2.6,7.4,6.6,7.4c3.5,0,5.3-2.1,5.3-5.3c0.1-2.2-1.6-4-3.8-4.1c0,0-0.1,0-0.1,0c-1-0.1-2,0.2-2.9,0.7 c-0.4-1.1-0.6-2.2-0.5-3.3c0-4.6,4-8.5,10.1-10.9L14.3,0C6,2.8,0,8.8,0,16.8z'/%3E%3C/svg%3E");
        top: -25px;
        left: 50%;
        margin-left: -20px;
        @include media-breakpoint-down(sm) {
            margin-left: -10px;
            top: -14px;
        }
    }
    &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 30.4 24.2' style='enable-background:new 0 0 30.4 24.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D%0A%3C/style%3E%3Cpath id='Path_1186' class='st0' d='M14.7,7.4c0-4.3-2.6-7.4-6.6-7.4C4.6,0,2.8,2.1,2.8,5.3c-0.1,2.2,1.6,4,3.8,4.1c0,0,0.1,0,0.1,0 c1,0.1,2-0.2,2.9-0.7c0.4,1.1,0.6,2.2,0.5,3.3c0,4.5-4,8.5-10.1,10.9l0.4,1.3C8.7,21.4,14.7,15.4,14.7,7.4z M30.4,7.4 c0-4.3-2.7-7.4-6.6-7.4c-3.5,0-5.3,2.1-5.3,5.3c-0.1,2.2,1.6,4,3.8,4.1c0,0,0.1,0,0.1,0c1,0.1,2-0.1,2.9-0.7 c0.4,1.1,0.5,2.2,0.5,3.3c0,4.6-4,8.5-10.1,10.9l0.4,1.3C24.4,21.4,30.4,15.4,30.4,7.4z'/%3E%3C/svg%3E");
        bottom: -33px;
        right: 50%;
        margin-right: -20px;
        @include media-breakpoint-down(sm) {
            margin-right: -10px;
            bottom: -17px;
        }
    }
}
.intro {
    .copy {
        padding-right: 8rem;
    }
}
.line-divide {
    content:'';
    display: inline-block;
    width: 100%;
    margin-top: -12px;
    border-bottom: 1px solid $text-color;
}
.plot-key-features > ul,
.news-page-content ul,
ul.square-list,
ul.tinymce {
    padding-left: 0;
    margin: 2.5rem 0;
    @include media-breakpoint-down(md) {
        font-size: 1.25rem;
    }
    li {
        background: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/square-bullet.png) no-repeat top left;
        background-position: 0 8px;
        list-style: none;
        padding: 5px 0 0 26px;
        @include media-breakpoint-down(md) {
            background: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/square-bullet-small.png) no-repeat top left;
            background-position: 0 12px;
        }
        @include media-breakpoint-down(ms) {
            background-position: 0 9px;
            padding: 5px 0 0 18px;
        }
    }
}
ol.tinymce,
ul.tinymce {
    font-size: inherit;
    @include media-breakpoint-down(md) {
        font-size: inherit;
    }
}
ol.tick-list,
ol.tinymce {
    padding-left: 0;
    margin: 2.5rem 0;
    @include media-breakpoint-down(md) {
        font-size: 1.25rem;
    }
    li {
        background: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/tick-bullet.png) no-repeat top left;
        background-position: 0 8px;
        list-style: none;
        padding: 5px 0 0 26px;
        @include media-breakpoint-down(md) {
            background: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/tick-bullet-small.png) no-repeat top left;
            background-position: 0 12px;
        }
        @include media-breakpoint-down(ms) {
            background-position: 0 9px;
            padding: 5px 0 0 18px;
        }
    }
}
.plot-key-features > ul{
    li {
        font-size: 1.5rem;
        @include media-breakpoint-down(md) {
        }
    }
}
/* Class name via Modernizr */
.rotate-text aside {
    border-left: 7vw solid #0000;
    padding-left: 10px;
    @include media-breakpoint-down(sm) {
        border-left: 0;
        padding-left: 0;
    }
}
.rotate-text aside .sub-title,
.rotate-text aside h1 {
  /* Abs positioning makes it not take up vert space */
  position: absolute;
  bottom: 3rem;
  left: 0;

  /* Border is the new background */
  background: none;

  /* Rotate from top left corner (not default) */
  transform-origin: bottom left;
  transform: rotate(-90deg);
  @include media-breakpoint-down(sm) {
      transform: none;
      position: static;
  }
}
.rotate-text aside.offset .sub-title,
.rotate-text aside.offset h1 {
    bottom: 10vw;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
    }
}
aside {
    display: block;
    position: relative;
    margin: 40px 0;
    z-index: 1;
    h1 {
        font-size: 90px;
        padding: 0;
        margin: 0;
        line-height: 24px;
        @include media-breakpoint-down(xxl) {
            font-size: 90px;
        }
        @include media-breakpoint-down(xl) {
            font-size: 70px;
        }
        @include media-breakpoint-down(md) {
            font-size: 50px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
            margin-bottom: 3rem;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }
    }
    .sub-title {
        color: $white;
        opacity: 1;
        padding: 70px 0;
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
            font-size: inherit;
            padding: 0;
        }
    }
}

.right-aligned {
    .rotate-text aside {
        border-right: 7vw solid #0000;
        padding-right: 10px;
        padding-left: 0;
        border-left: 0;
        @include media-breakpoint-down(sm) {
            border-right: 0;
            padding-right: 0;
        }
    }
    .rotate-text aside .sub-title,
    .rotate-text aside h1 {
      /* Abs positioning makes it not take up vert space */
      position: absolute;
      bottom: 0;
      right: 0;
      left: inherit;

      /* Border is the new background */
      background: none;

      /* Rotate from top left corner (not default) */
      transform-origin: bottom right;
      transform: rotate(90deg);
      @include media-breakpoint-down(sm) {
          display: none;
      }
    }
    .rotate-text aside.offset .sub-title,
    .rotate-text aside.offset h1 {
        bottom: 10vw;
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }
    }
    aside {
        display: block;
        position: relative;
        margin: 40px 0;
        z-index: 1;
        h1 {
            font-size: 90px;
            padding: 0;
            margin: 0;
            line-height: 24px;
            @include media-breakpoint-down(xxl) {
                font-size: 80px;
            }
            @include media-breakpoint-down(xl) {
                font-size: 70px;
            }
            @include media-breakpoint-down(md) {
                font-size: 50px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 2rem;
                margin-bottom: 3rem;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
            }
        }
        .sub-title {
            color: $white;
            opacity: 1;
            padding: 70px 0;
            @include media-breakpoint-down(sm) {
                font-size: inherit;
                padding: 0;
            }
        }
    }
}

.promotion-tab {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 150px solid $orange;
    border-bottom: 150px solid transparent;
    border-right: 150px solid transparent;
    z-index: 1;
    &.orange {
        border-top: 150px solid $orange;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
    }
    &.yellow {
        border-top: 150px solid $yellow;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
        span {
            color: $text-color;
        }
    }
    &.green {
        border-top: 150px solid $green;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
    }
    &.teal {
        border-top: 150px solid $teal;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
    }
    &.purple {
        border-top: 150px solid $purple;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
    }
    &.dark {
        border-top: 150px solid $secondary;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
        span {
            color: $primary;
        }
    }
    &.gold {
        border-top: 150px solid $primary;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
    }
    &.silver {
        border-top: 150px solid $silver;
        border-bottom: 150px solid transparent;
        border-right: 150px solid transparent;
        span {
            color: $text-color;
        }
    }
}

.promotion-tab span {
    @include small-caps;
    font-size: 1.25rem;
    line-height: 1;
    color: $white;
    position: absolute;
    top: -105px;
    width: 100px;
    left: 5px;
    text-align: center;
    transform: rotate(-45deg);
    display:block;
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
    }
}
.promotion-tab.rosette:after {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-rosette.svg);
    position: absolute;
    top: -135px;
    width: 20px;
    left: 18px;
    text-align: center;
    transform: rotate(-45deg);
    opacity: 0.5;
}
.promotion-tab.house:after {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-house-light.svg);
    position: absolute;
    top: -135px;
    width: 27px;
    left: 18px;
    text-align: center;
    transform: rotate(-45deg);
    opacity: 0.7;
}
.promotion-tab.keys:after {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-keys.svg);
    position: absolute;
    top: -135px;
    width: 32px;
    left: 18px;
    text-align: center;
    transform: rotate(-45deg);
    opacity: 1;
}
.promotion-tab.silver.rosette:after {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-rosette-dark.svg);
    position: absolute;
    top: -135px;
    width: 20px;
    left: 18px;
    text-align: center;
    transform: rotate(-45deg);
    opacity: 0.5;
}
.promotion-tab.silver.house:after {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-house-dark.svg);
    position: absolute;
    top: -135px;
    width: 27px;
    left: 18px;
    text-align: center;
    transform: rotate(-45deg);
    opacity: 0.7;
}
.promotion-tab.silver.keys:after {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-keys-dark.svg);
    position: absolute;
    top: -135px;
    width: 32px;
    left: 18px;
    text-align: center;
    transform: rotate(-45deg);
    opacity: 1;
}
