.map-size {
    height: 35vw;
    @include media-breakpoint-down(lg) {
        height: 50vw;
    }
    @include media-breakpoint-down(ms) {
        height: 100vw;
    }
}
.local-area-map-details {
    padding: 5rem;
    font-size: 1.5rem;
    .sub-title {
        color: $primary;
        opacity: 1;
    }
    h3 {
        color: $text-color;
    }
}
.map-container {
    position: relative;
    .map-responsive {
        padding-bottom: 35vw;
        @include media-breakpoint-down(lg) {
            padding-bottom: 50vw;
        }
        @include media-breakpoint-down(ms) {
            padding-bottom: 100vw;
        }
    }
}
.map-responsive {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    @include media-breakpoint-down(ms) {
        padding-bottom: 114vw;
    }
    iframe {
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }
}
.gm-style img {
    @include media-breakpoint-down(ms) {
        max-width: 100px;
    }
}
.gm-style .development-pin-image {
    display: block;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    max-height: 72px;
    width: auto;
}
.local-area-map {
    position: relative;
    .icon-area {
        width: 5rem;
        height: 5rem;
        background: $primary;
        position: absolute;
        text-align: center;
        color: $white;
        font-size: 2.5rem;
        top: 0;
        right: 0;
        padding: 0.5rem;
        z-index: 2;
        i {
            width: 50%;
            margin: auto;
            position: absolute;
            top: 50%; left: 50%;
            -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
        }
    }
}
ul.local-area-list {
    @include media-breakpoint-down(xl) {
        padding: 5vw;
    }
    @include media-breakpoint-down(md) {
        margin: 6rem 0;
        padding: 0 5rem;
    }
    @include media-breakpoint-down(ms) {
        margin: 3rem 0;
    }
    li {
        list-style: none;
        a {
            @include small-caps;
            color: $text-color;
            font-weight: 600;
            left: 0;
            transition: left 0.3s ease;
            position: relative;
            @include media-breakpoint-down(ms) {
                font-size: 1.25rem;
            }
            &:hover {
                text-decoration: none;
                left: 5px;
            }
            i {
                width: 3rem;
                text-align: center;
                color: $primary;
            }
            &.active {
                color: $primary;
            }
        }
    }
}