.p-config {
    padding: 10rem 0 0 0;
}
.inspiration-config {
    padding-bottom: 5rem;
    position: relative;
    &:after {
        content: url(/media/images/text-inspiration.svg);
        opacity: 0.05;
        position: absolute;
        right: 0;
        top: 0;
        width: 102vw;
        z-index: -1;
        @include media-breakpoint-down(md) { 
            bottom: 0;
            top: auto;
        }
    }
}
.config-area {
    background-color: $white;
    position: relative;
    .container {
        position: relative;
        z-index: 1;
        .config-content {
            padding: 8rem;
            @include media-breakpoint-down(md) { 
                padding: 4rem;
            }
            .kitchen-swiper {
                transition: ease all 0.25s;
                opacity: 0.75;
                &.active {
                    opacity: 1;
                }
                .owl-stage {
                    display: flex;
                    flex-flow: row wrap;
                }
            }
            .kitchen-swiper, .development-swiper-wrapper {
                .slick-slide {
                    margin: 0 15px;
                }
                .slick-list {
                    margin: 0 -15px;
                }
                .slick-next {
                    right: -45px;
                    @include media-breakpoint-down(md) { 
                        right: -30px;
                    }
                    &:before {
                        @include font-awesome;
                        content: '\f054' !important;
                    }
                }
                .slick-prev {
                    left: -45px;
                    @include media-breakpoint-down(md) { 
                        left: -30px;
                    }
                    &:before {
                        @include font-awesome;
                        content: '\f053' !important;
                    }
                }
                .card {
                    border-radius: 0;
                    border: 0;
                    &.active {
                        opacity: 1;
                    }
                    &.not-active {
                        opacity: 0.5;
                    }
                    .card-body {
                        padding: 3rem 1.5rem;
                        h2 {
                            @include font-serif;
                            color: $secondary;
                            font-size: 1.75rem;
                            margin-bottom: 0;
                        } 
                        p {
                            color: $secondary;
                            font-size: 1.5rem;
                        }
                    }
                    .card-footer {
                        background-color: lighten($primary, 40%);
                        color: $secondary;
                        overflow: hidden;
                        .housetype-kitchen-details {
                            background-color: $primary;
                            p {
                                color: $white;
                                margin: 2rem 0;
                                text-align: center;
                                &.small-caps {
                                    font-size: 1.25rem;
                                }
                            }
                        }
                    }
                }
            }
            .indent-invert {
                margin-left: -12rem;
                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                }
                h2 {
                    color: $white;
                }
            }
        }
        .share-block {
            background-color: $white;
            color: $primary;
            display: inline-block;
            float: right;
            padding: 1rem 3rem;
            @include media-breakpoint-down(md) {
                position: relative;
                width: 100%;
            }
            .sub-title {
                opacity: 1 !important;
            }
            i {
                font-size: 1.5rem;
                @include media-breakpoint-down(md) {
                    font-size: 1rem !important;
                }
            }
        }
    }
    &:after {
        content:'';
        background-color: $light;
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
        z-index: 0;
    }
}
.configurator-section {
    padding-top: 8rem;
    .configurator-container {
        .configurater-collection-container {
            .configurater-collection-item {
                background-color: $white;
                border-right: 2px solid $secondary;
                border-bottom: 2px solid #222222;
                color: $secondary;
                text-align: center;
                h3 {
                    font-size: 1.75rem;
                    margin-bottom: 0;
                    padding-top: 3rem;
                    @include media-breakpoint-down(md) {
                        font-size: 1.5rem;
                        margin-bottom: 10px;
                    }
                }
                p {
                    opacity: 1 !important;
                }
                &:last-of-type {
                    border-right: 0;
                }
            }
        }
        .configurater-element-item {
            background-color: $primary;
            padding: 1rem 0;
            font-family: $font-sans;
            font-weight: 800;
            text-transform: uppercase;
            font-size: 1.5rem;
            text-align: center;
            @include media-breakpoint-down(md) {
                font-size: 0.95rem;
                border-top: 1px solid $black;
                &.br-black {
                    border-right: 1px solid $black;
                }
                &.bl-black {
                    border-left: 1px solid $black;
                }
            }
            &:hover {
                cursor: pointer;
            }
            &.active {
                background-color: $white;
                color: $primary;
                border-top: 10px solid $white;
                margin-top: -10px;
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    border-top: 1px solid $black !important;
                }
            }
        }
        .configurater-element-container {
            .configurator-element-slider {
                background-color: $white;
                margin-top: 1rem;
                @include media-breakpoint-down(md) {
                    margin-top: 2px;
                }
                .slick-slide {
                    margin: 0 15px;
                }
                .slick-list {
                    margin: 0 -15px;
                }
                .configurator-element-slider-item {
                    text-align: center;
                    .active {
                        border: 3px solid #af8757 !important;
                        opacity: 1 !important;
                        @include media-breakpoint-down(md) {
                            border: 2px solid #af8757 !important;
                        }
                    }
                    .configurator-element-slider-image {
                        opacity: 0.8;
                        @include media-breakpoint-down(md) {
                            height: 30px !important;
                        }
                    }
                    h3 {
                        font-size: 1.75rem;
                        margin-bottom: 0;
                        padding-top: 3rem;
                        @include media-breakpoint-down(md) {
                            font-size: 1.5rem;
                            margin-bottom: 10px;
                        }
                    }
                    img {
                        @include media-breakpoint-down(md) {
                            height: 100px;
                        }
                    }
                    p {
                        opacity: 1 !important;
                        @include media-breakpoint-down(md) {
                            line-height: 1.25rem;
                        }
                        &.c-name {
                            font-family: $font-sans;
                            color: $primary
                        }
                        &.s-name {
                            font-family: $font-sans;
                            color: $secondary
                        }
                    }
                }
                .owl-dots {
                    display: none;
                }
            }
        }
    }
}

.btn-config {
    background-color: $primary;
    color: $white !important;
    border-radius: 0;
    padding: 2rem 3rem;
    @include small-caps;
}
.results-area {
    background-color: $secondary;
    position: relative;
    padding-bottom: 5rem;
    -webkit-print-color-adjust: exact;
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
        color-adjust: exact !important;                 /*Firefox*/
    }
    &:after {
        content:'';
        background-color: $light;
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
        z-index: 0;
         @include media-breakpoint-down(md) {
            top: 0px;
            height: 110px;
            bottom: auto;
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .finished-img {
        position: relative;
        .zoom-block {
            background-color: $secondary;
            color: $primary;
            display: inline-block;
            position: absolute;
            right: 0;
            top: auto;
            bottom: 0;
            padding: 1rem 2rem;
            z-index: 1;
            i {
                color: $white;
                font-size: 1.5rem;
            }
        }
    }
    .share-block {
        background-color: $white;
        color: $primary;
        display: inline-block;
        float: right;
        position: absolute;
        left: 0;
        top: 0;
        padding: 1rem 3rem;
        z-index: 1;
        .sub-title {
            opacity: 1 !important;
        }
        i {
            font-size: 1.5rem;
            @include media-breakpoint-down(md) {
                font-size: 1rem !important;
            }
        }
    }
    .zoom-block {
        background-color: $secondary;
        color: $primary;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 1rem 2rem;
        z-index: 1;
        i {
            color: $white;
            font-size: 1.5rem;
        }
    }
    .collection-detail {
        border: 1px solid $white;
        margin: 3rem 0;
        padding: 2rem 0;
        text-align: center;
         @include media-breakpoint-down(md) {
            margin: 1rem 0;
         }
        .sub-title {
            color: $white;
            opacity: 1;
        }
        .sub-title-alt {
            color: $primary;
            font-size: 1.5rem;
            font-family: $primary-font;
        }
    }
    .collection-detail-alt-container {
        &:nth-of-type(4n+1) {
            .collection-detail-alt {
                border-right: 0;
            }
        }
        &:last-of-type {
            .collection-detail-alt {
                border-bottom: 0;
            }
        }
        .collection-detail-alt {
            border-right: 1px solid $primary;
            margin: 3rem 0;
            text-align: center;
             @include media-breakpoint-down(md) {
                border-right: 0;
                border-bottom: 1px solid $primary;
                margin: 1rem 0;
                padding-bottom: 2rem;
             }
            .sub-title {
                color: $white;
                opacity: 1;
            }
            .sub-title-alt {
                color: $primary;
                font-size: 1.5rem;
                font-family: $primary-font;
            }
        }
    }
}
.appointment-cta {
    background-color: $primary;
    overflow: hidden;
    .appointment-cta-container {
        padding: 5rem;
        h2 {
            color: $white;
        }
        p {
            color: $white;
        }
    }
}
.cta-bar {
    background-color: $white;
    padding: 3rem 0;
}
.cta-forms {
    background-color: $white;
}
.transparent {
    background-color: transparent !important;
}
.date-selection {
    position: relative;
    &:after {
        @include font-awesome;
        content: '\f073';
        color: $primary;
        position: absolute;
        top: 10px;
        left: 0;
    }
}
.datepicker-dropdown {
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    .datepicker-days {
        .table-condensed {
            thead {
                tr {
                    border-radius: 0;
                    th {
                        border-radius: 0;
                        height: 35px !important;
                        width: 45px !important;
                        font-family: 'proxima-nova';
                        font-weight: 900;
                    }
                    &:first-of-type {
                        background-color: $light;
                    }
                    &:last-of-type {
                        background-color: $primary;
                        color: $white !important;
                        border-radius: 0 !important;
                    }
                }
            }
            tbody {
                background-color: $primary;
                color: $white !important;
                tr {
                    td {
                        border-radius: 0 !important;
                        font-family: 'proxima-nova';
                        font-weight: 900;
                    }
                    .old {
                        color: rgba(255, 255, 255, 0.7) !important;
                    }
                    .new {
                        color: rgba(255, 255, 255, 0.7) !important;
                    }
                }
            }
        }
    }
    .datepicker-months, .datepicker-years {
        .table-condensed {
            thead {
                background-color: $light;
                tr {
                    background-color: $light;
                    border-radius: 0;
                    height: 35px;
                }
            }
            tbody {
                background-color: $primary;
                color: $white;
                tr {
                    td {
                        border-radius: 0;
                        .new, .old {
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }
}
.config-results-cta {
    i {
        color: $white;
        font-size: 4rem;
    }
    h4 {
        color: $white;
        margin-bottom: 0.5rem;        
    }
    p {
        color: white;
        font-size: 1.5rem;
        padding-left: 7rem;
    }
    .checkbox {
        padding-left: 7rem;
    }
}
.development-selection {
    background: white;
    font-weight: 900;
    padding: 2rem 4rem;
    font-size: 1.5rem;
    color: black;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: ease all 0.25s;
    @include media-breakpoint-down(md) {
        padding: 1.5rem;
        font-size: 0.85rem;
    }
    .development-name {
        color: $primary;
    }
    .tick-to-edit {
        position: absolute;
        right: 15px;
        top: 0;
        height: 65px;
        width: 50px;
        align-items: center;
        display: flex;
        font-size: 2.5rem;
        @include media-breakpoint-down(md) { 
            font-size: 1.25rem;
            right: 0;
            top: 0;
            height: 42px;
            }
        &:after {
            @include font-awesome;
            content: '\f00c';
            color: $primary;
            position: absolute;
            transition: ease all 0.25s;
        }
    }
    &:hover {
        background-color: $primary;
        color: $white !important;
        .development-name {
            color: $white !important;
        }
        .tick-to-edit {
            &:after {
                @include font-awesome;
                color: $white;
                content: '\f303';
                transform: rotate(360deg);
                cursor: pointer;
            }
        }
    }
}
.kitchen-swiper-card, .development-swiper-card {
    transition: ease all 0.25s !important;
    &:hover {
        box-shadow: 0 11px 13px rgba(0, 0, 0, 0.95);
        text-decoration: none;
        transform: translateY(-5px);

    }
    &:active {
        outline: none;
    }
}
.slick-active {
   &:active {
        outline: none;
    }
}
.configurater-element-container {
    .owl-prev, .owl-next {
        display: none !important;
    }
}
.configurater-collection-item {
    img {
        border: 5px solid $white;
        &.active {
            border: 5px solid $primary;
            @include media-breakpoint-down(md) { 
                border: 2px solid $primary;
            }
        }
    }
}
.config-content {
    * {
        &:focus {
            outline: none;
        }
    }
}
.development-swiper {
    .owl-prev, .owl-next, .owl-dots {
        display: none;
    }
}
.configurator-elementor-slider {
    .owl-dots {
        display: none;
    }
}
.configurater-collection-slider {
    .slick-list {
        .slick-track {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
        }
    }
}
@media print
{
    * {-webkit-print-color-adjust:exact;}
}