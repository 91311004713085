.team-header {
    height: 70vh;
    @include media-breakpoint-up(xxl) {
        height: 92vh;
    }
    @include media-breakpoint-down(xl) {
        height: 92vh;
    }
    @include media-breakpoint-down(sm) {
        height: inherit;
        padding: 9rem 6rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 8rem 4rem 4rem;
    }
    aside {
        display: flex;
        @include media-breakpoint-down(sm) {
            display: block
        }
        .sub-title {
            color: $text-color;
        }
    }
    h2 {
        font-weight: 500;
        font-size: 3.5vw;
    }
    .sub-title-hoz {
        opacity: 1;
    }
    .copy {
        color: $text-color;
        width: 60vw;
        margin-left: 8vw;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0;
        }
    }
}
.team-card {
    .card-body {
        padding: 1.5vw;
        @include media-breakpoint-down(lg) {
            font-size: 6vw;
        }
        @include media-breakpoint-down(sm) {
            padding: 5vw;
        }
        @include media-breakpoint-down(ms) {
            padding: 9vw;
        }
        h2 {
            font-size: 2vw;
            @include media-breakpoint-up(xxl) {
                font-size: 2.5rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 2vw;
                margin-bottom: 2vw;
                margin-left: 0;
            }
            @include media-breakpoint-down(sm) {
                font-size: 3vw;
            }
            @include media-breakpoint-down(ms) {
                font-size: 6vw;
            }
        }
        .position {
            @include small-caps;
            margin-bottom: 1rem;
            @include media-breakpoint-down(sm) {
                font-size: 1.125rem;
                margin-top: 1rem;
            }
        }
        .description {
            font-size: 1.5rem;
            @include media-breakpoint-down(xl) {
                font-size: 1.25rem;
            }
        }
    }
}