.news-header {
    color: $white;
    height: 70vh;
    @include media-breakpoint-up(xxl) {
        height: 92vh;
    }
    @include media-breakpoint-down(xl) {
        height: 92vh;
    }
    @include media-breakpoint-down(sm) {
        height: inherit;
        padding: 9rem 6rem;
    }
    @include media-breakpoint-down(ms) {
        padding: 8rem 4rem 4rem;
    }
    aside {
        display: flex;
        @include media-breakpoint-down(sm) {
            display: block
        }
    }
    h2 {
        color: $white;
        font-weight: 500;
        font-size: 3.5vw;
    }
    .sub-title-hoz {
        color: $white;
        opacity: 0.5;
    }
    .copy {
        width: 60vw;
        margin-left: 8vw;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-left: 0;
        }
    }
}
.main-news {
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: $primary;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                h2 {
                    color: $white;
                    margin-left: -50%;
                    padding-right: 50%;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $white;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
.news-item {
    padding-top: 8vw;
    @include media-breakpoint-down(xl) {
        padding-top: 12vw;
    }
    @include media-breakpoint-down(ms) {
        padding-top: 19vw;
    }
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: auto;
                background: transparent;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    padding-right: 0;
                }
                .author {
                    font-weight: 700;
                    font-size: 1.5rem;
                    margin-bottom: 2rem;
                }
                h2 {
                    color: $white;
                    margin-left: -50%;
                    padding-right: 50%;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $primary;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
.parent:hover .child, .parent:focus .child {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.parent:hover .child:before, .parent:focus .child:before {
    display: block;
}
.offers-list-item {
    margin-bottom: 2vw;
    @include media-breakpoint-down(lg) {
        margin-bottom: 4vw;
    }
    a {
        color: $text-color;
        &:hover {
            text-decoration: none;
            .card .news-img {
                -ms-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -webkit-transform: scale(1.2);
                -o-transform: scale(1.2);
                transform: scale(1.2);
            }
        }
        .offers-img {
            position: relative;
            -webkit-transition: all 1.5s ease;
                -moz-transition: all 1.5s ease;
                -o-transition: all 1.5s ease;
                transition: all 1.5s ease;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: rgba($black, 0.4);
            }

        }
        .card {
            border-radius: 0;
            border: 0;
            background: transparent;
            display: flex;
            height: 40vw;
            margin-bottom: 4rem;
            overflow: hidden;
            position: relative;
            @include media-breakpoint-down(xxl) {
                height: 36vw;
            }
            @include media-breakpoint-down(xl) {
                height: 40vw;
            }
            @include media-breakpoint-down(lg) {
                height: 40vw;
            }
            @include media-breakpoint-down(md) {
                height: 44vw;
                margin-bottom: 2rem;
            }
            @include media-breakpoint-down(sm) {
                height: 80vw;
            }
            .text-area {
                position: absolute;
                color: $white;
                bottom: 0;
                right: 0;
                padding: 5rem;
                width: 80%;
                z-index: 1;
                text-align: right;
                @include media-breakpoint-down(md) {
                    padding: 3rem;
                    width: 100%;
                }
                h3 {
                    color: $white;
                    font-size: 3vw;
                    @include media-breakpoint-down(sm) {
                        font-size: 2rem;
                    }
                }
                .description {
                    color: $white;
                    display: block;
                }
            }
            .promotion-tab {
                top: 60px;
            }
            .img-box {
                position: relative;
                margin: auto;
                overflow: hidden;
                width: 100%;
            }
            .card-header {
                background: transparent;
                border-radius: 0;
                border: 0;
                height: 60px;
                padding-left: 0;
                padding-right: 0;
            }
            .card-img, .card-img-top {
                border-radius: 0;
                max-width: 100%;
                transition: all 1s ease;
                display: block;
                width: 100%;
                height: auto;
                transform: scale(1);
            }
            .card-body {
                padding-left: 0;
                padding-right: 0;
                display: flex;
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
        .featured {
            .dev-details.dark {
                padding-left: 2rem;
                padding-right: 2rem;
                .item {
                    .item-inner {
                        color: $white;
                        img {
                            fill: #ffffff;
                        }
                    }
                }
                background: $primary;
                color: $white;
            }
            .card-body {
                padding: 2rem;
                background: $primary;
                color: $white;
            }
            .btn-primary {
                background: $primary;
                color: $white;
                padding-right: 3rem;
                &:after {
                    content: url(/media/images/arrow-right-light.svg);
                    right: 12px;
                }
            }
        }
        .btn-area {
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
        }
        .icon-area {
            width: 5rem;
            height: 5rem;
            background: $primary;
            position: absolute;
            text-align: center;
            color: $white;
            font-size: 2.5rem;
            top: 0;
            right: 0;
            padding: 0.5rem;
            i {
                width: 50%;
                margin: auto;
                position: absolute;
                top: 50%; left: 50%;
                -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
            }
        }
        .btn-primary {
            padding: 14px 20px;
            height: 40px;
            left: 0;
            transition: left 0.3s ease;
            &:before {
                display: none;
            }
            &:hover {
                left: 5px;
            }
        }
    }
    &:nth-of-type(odd) a .card {
        margin-right: 2rem;
        @include media-breakpoint-down(md) {
            margin-right: 1rem;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
    }
    &:nth-of-type(even) a .card {
        margin-left: 2rem;
        @include media-breakpoint-down(md) {
            margin-left: 1rem;
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }
    .title {
        h3 {
            @include font-sans;
            color: $text-color;
            @include media-breakpoint-down(ms) {
                font-size: 2rem;;
            }
        }
    }
}
.news-filter {
    margin: 5rem 0;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(md) {
        margin: 3rem 0;
    }
    .search-field {
    }
    .custom-select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 17.6 11' style='enable-background:new 0 0 17.6 11;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23A6815A;%7D%0A%3C/style%3E%3Cg id='Group_908' transform='translate(0 11.015) rotate(-90)'%3E%3Crect id='Rectangle_906' x='3.9' y='-0.7' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 5.5076 13.2967)' class='st0' width='3.1' height='12.5'/%3E%3Crect id='Rectangle_907' x='-0.7' y='10.6' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 0.8253 24.5992)' class='st0' width='12.5' height='3.1'/%3E%3C/g%3E%3C/svg%3E");
        @include small-caps;
        padding: 0 2rem;
        color: $primary;
        border-bottom: 0;
        font-size: 1.25rem;
        padding-left: 0;
        @include media-breakpoint-only(xxl) {
        }
        @include media-breakpoint-only(xl) {
        }
        @include media-breakpoint-down(md) {
            padding-left: 0;
            // border-right: 0;
        }
        @include media-breakpoint-down(sm) {
            background-size: 9px;
            padding-left: 0;
            // border-right: 0;
            background-position: right 0 center;
        }
    }
    .form-control {
        border-bottom: 0;
    }
}
.news-view {
    hr {
        border-color: darken($border, 50%);
        margin: 6rem 0;
        @include media-breakpoint-down(sm) {
            margin: 3rem 0;
        }
    }
    ul.social-list {
       position: static;
       &:after {
            display: none;
       }
       li:after {
            display: none;
       }
    }
}
.news-page-content {
    .swiper-container.gallery-slider .swiper-slide.gallery-item h3 {
        color: $primary;
    }
    ul.social-list {
        margin: 0;
        li {
            background: transparent;
            padding: 0;
        }
    }
    hr {
        margin: 6rem 0;
        @include media-breakpoint-down(sm) {
            margin: 3rem 0;
        }
    }
    h1 {
        @include media-breakpoint-down(lg) {
            font-size: 2.5rem;
            line-height: 1.4;
        }
    }
    .description {
        font-size: 2.5rem;
        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
        }
    }
}
.news-page {
    aside {
        h1 {
            font-size: 80px;
            @include media-breakpoint-down(xl) {
                font-size: 70px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 3rem;
                margin-top: 3rem;
            }
        }
        .sub-title {
            margin-bottom: 0;
        }
    }
}
.news-pills {
    background: transparent;
    padding: 0;
    .tab-tag {
        position: absolute;
        left: 0px;
        width: 89px;
        height: auto;
        background: $secondary;
        color: $white;
        top: 0;
        bottom: 0;
        font-size: 2.5rem;
        text-align: center;
        line-height: 2;
    }
    li.nav-item {
        position: relative;
        .nav-link {
            @include small-caps;
            background: $secondary;
            color: $primary;
            padding: 2rem;
            border-radius: 0;
            font-size: 2rem;
            @include media-breakpoint-down(md) {
                padding: 2rem;
            }
            @include media-breakpoint-down(ms) {
                font-size: 1.25rem;
                font-weight: 700;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        .nav-link.active {
            background: darken($light, 10%);
            color: $primary;
            &:after {
                content:'\f0dd';
                @include font-awesome;
                color: darken($light, 10%);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -25px;
                font-size: 60px;
                line-height: 1;
                font-weight: 700;
            }
            .tab-tag {
                background: $secondary;
            }
        }
    }
}
.news-pill-content {
    .grey-bg {
        h2 {
            margin-top: 0;
        }
        padding: 6rem 0;
        @include media-breakpoint-down(md) {
            padding: 3rem 0;
        }
    }
}
.news-list-item {
    margin-bottom: 5rem;
    .text-image-box {
        .promotion-tab {
            z-index: 2;
        }
        .content-area {
            .content {
                margin-right: 0;
            }
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                display: flex;
                margin-top: 0;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 70vw;
                }
            }
            &.text {
                width: 50%;
                color: $text-color;
                height: auto;
                background: $white;
                padding: 6vw;
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                h2 {
                    color: $primary;
                    font-size: 2vw;
                    @include media-breakpoint-down(md) {
                        font-size: 2vw;
                        margin-bottom: 2vw;
                        margin-left: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 5vw;
                    }
                }
                .date {
                    @include small-caps;
                    margin-bottom: 1rem;
                }
                .author {
                    @include small-caps;
                    font-size: 1.25rem;
                    color: $primary;
                }
                .sub-title {
                    color: $text-color;
                    opacity: 1;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            .description {
                padding-top: 2rem;
                @include media-breakpoint-down(xl) {
                    font-size: 1.25rem;
                }
            }
            .dev-details {
                display: flex;
                span {
                    display: block;
                }
            }
        }
    }
}
.news-list-item:nth-of-type(even) {
    .text-image-box {
        flex-direction: row-reverse;
        @include media-breakpoint-down(sm) {
            flex-direction: row;
        }
        .promotion-tab {
            transform: rotate(90deg);
            transform-origin: 50% 25%;
            @include media-breakpoint-down(sm) {
                transform: rotate(0);
            }
        }
    }
}
.offers-list-item {
    a {
        .card {
            .promotion-tab {
                top: 0;
            }
        }
    }
}