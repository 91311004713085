.contact-page {
    @include media-breakpoint-down(ms) {
        padding-top: 8rem;
    }
}
.contact-map {
    position: relative;
    hr {
        border-color: rgba($primary, 0.2);
        margin-top: 2rem;
        margin-bottom: 2rem;
        @include media-breakpoint-down(ms) {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
    ul.dev-details-list {
        @include media-breakpoint-down(xl) {
            font-size: 0.875vw;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.25vw;
        }
        @include media-breakpoint-down(sm) {
            font-size: 2.5vw;
        }
    }
    ul.dev-details-list li.item {
        color: $white;
    }
    ul.dev-details-list li.item img {
        @include media-breakpoint-down(sm) {
            height: 2.5vw;
        }
    }
    .details {
        padding-left: 6rem;
        padding-right: 6rem;
        @include media-breakpoint-down(ms) {
            padding-left: 5rem;
            padding-right: 3rem;
        }
        @include media-breakpoint-down(xl) {
            h3 {
               font-size: 2rem;
            }
            font-size: 1.25rem;
        }
        @include media-breakpoint-down(md) {
            padding-bottom: 3rem;
            padding-top: 3rem;
        }
        .btn {
            margin-top: 4rem;
            @include media-breakpoint-down(xl) {
                margin-top: 2rem;
            }
        }
        a {
            position: relative;
            left: 0;
            transition: color 0.3s ease,
                        left 0.3s ease;
            &:hover {
                color: rgba($primary, 0.7);
                text-decoration: none;
                left: 10px;
            }
        }
    }
    .icon-area {
        width: 5rem;
        height: 5rem;
        background: $primary;
        position: absolute;
        text-align: center;
        color: $white;
        font-size: 2.5rem;
        top: 0;
        right: 0;
        padding: 0.5rem;
        z-index: 2;
        i {
            width: 50%;
            margin: auto;
            position: absolute;
            top: 50%; left: 50%;
            -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
        }
    }
    .address,
    .email,
    .tel {
        position: relative;
        &:before {
            content: '\f3c5';
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            left: -30px;
            font-size: 1.75rem;
            font-weight: 700;
            color: $primary;
            @include media-breakpoint-down(xl) {
                font-size: 1rem;
                left: -20px;
            }
        }
    }
    .email {
        position: relative;
        &:before {
            content: '\f0e0';
            font-family: "Font Awesome 5 Pro";
        }
    }
    .tel {
        position: relative;
        &:before {
            content: '\f879';
            font-family: "Font Awesome 5 Pro";
        }
    }
}
.gm-ui-hover-effect {
    opacity: 0.2;
}
.gm-style .gm-style-iw-c {
    padding: 0;
}
.gm-style .gm-style-iw-d {
    padding: 2rem;
    max-width: 210px !important;
}
.gm-style .gm-style-iw-t::after {
    top: -2px;
}
.gm-style .gm-style-iw {
    font-family: proxima-nova, sans-serif !important;
    font-weight: 700 !important;
    color: $text-color !important;
}
.social-item {
    margin: 3rem 0;
    @include media-breakpoint-down(md) {
        margin: 2rem 0;
    }
    @include media-breakpoint-down(ms) {
        padding: 0;
        margin-bottom: 1rem;
    }
    h3 {
        color: $white;
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
            font-size: 1.875rem;
            margin-bottom: 1rem;
        }
    }
    .description {
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }
    img {
        max-width: 170px;
        @include media-breakpoint-down(md) {
           max-width: 80px;
           margin-bottom: 2rem;
        }
    }
}

.absolute-title-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 230px;
    z-index: 2;
    h1 {
        @include media-breakpoint-down(ms) {
            font-size: 1.5rem;
        }
    }
}