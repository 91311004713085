.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: -1; /* set to -1 and prevent flicker on Safari */
  opacity: 0;
  transition: 0s 0.6s opacity;
}
// .carousel-item {
//   position: relative;
//   display: none;
//   float: left;
//   width: 100%;
//   margin-right: -100%;
//   -webkit-backface-visibility: hidden;
//   backface-visibility: hidden;
//   transition: -webkit-transform 0.6s ease-in-out;
//   transition: transform 0.6s ease-in-out;
//   transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
// }
.no-banner {
    height: 80px;
    background-color: darken($primary, 6%);
    @include media-breakpoint-down(sm) {
        height: 60px;
    }
}
.ribbon {
    position: absolute;
    left: 0;
    bottom: 20%;
    z-index: 2;
    width: 30vw;
    height: 8vw;
    -webkit-box-shadow: 0px 10px 23px -6px rgba(0,0,0,0.42);
    -moz-box-shadow: 0px 10px 23px -6px rgba(0,0,0,0.42);
    box-shadow: 0px 10px 23px -6px rgba(0,0,0,0.42);
    .white-logo {
        left: 0;
        z-index: 2;
        background: $white;
        height: 8vw;
        img {
            width: auto;
            height: 44px;
            padding: 4px 8px;
            
            @include media-breakpoint-up(md) {
                height: 60px;
            }
            
            @include media-breakpoint-up(lg) {
               height: 80px;
            }

            @include media-breakpoint-up(xl) {
                width: auto;
                height: 100%;
            }
        }
    }
    &:after {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        top: 0px;
        right: -4vw;
        border-top: 4vw solid $orange;
        border-bottom: 4vw solid $orange;
        border-right: 4vw solid #0000;
        border-left: 4vw solid $orange;
    }
    @include media-breakpoint-up(xxl) {
        bottom: 20%;
        width: 31vw;
        height: 6vw;
        &:after {
            right: -3vw;
            border-top: 3vw solid $orange;
            border-bottom: 3vw solid $orange;
            border-right: 3vw solid #0000;
            border-left: 3vw solid $orange;
        }
    }
    @include media-breakpoint-down(sm) {
        bottom: 60%;
    }
    @include media-breakpoint-down(md) {
        width: 40vw;
    }
    @include media-breakpoint-down(ms) {
        bottom: 25%;
        width: 49vw;
        height: 12vw;
        &:after {
            right: -6vw;
            border-top: 6vw solid $orange;
            border-bottom: 6vw solid $orange;
            border-right: 6vw solid #0000;
            border-left: 6vw solid $orange;
        }
    }
    &.orange {
        background: $orange;
        &:after {
            border-top: 4vw solid $orange;
            border-bottom: 4vw solid $orange;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $orange;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $orange;
                border-bottom: 3vw solid $orange;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $orange;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $orange;
                border-bottom: 6vw solid $orange;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $orange;
            }
        }
    }
    &.yellow {
        background: $yellow;
        span {
            color: $text-color;
        }
        &:after {
            border-top: 4vw solid $yellow;
            border-bottom: 4vw solid $yellow;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $yellow;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $yellow;
                border-bottom: 3vw solid $yellow;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $yellow;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $yellow;
                border-bottom: 6vw solid $yellow;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $yellow;
            }
        }
    }
    &.green {
        background: $green;
        &:after {
            border-top: 4vw solid $green;
            border-bottom: 4vw solid $green;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $green;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $green;
                border-bottom: 3vw solid $green;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $green;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $green;
                border-bottom: 6vw solid $green;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $green;
            }
        }
    }
    &.teal {
        background:  $teal;
        &:after {
            border-top: 4vw solid $teal;
            border-bottom: 4vw solid $teal;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $teal;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $teal;
                border-bottom: 3vw solid $teal;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $teal;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $teal;
                border-bottom: 6vw solid $teal;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $teal;
            }
        }
    }
    &.purple {
        background:  $purple;
        &:after {
            border-top: 4vw solid $purple;
            border-bottom: 4vw solid $purple;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $purple;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $purple;
                border-bottom: 3vw solid $purple;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $purple;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $purple;
                border-bottom: 6vw solid $purple;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $purple;
            }
        }
    }
    &.dark {
        background:  $secondary;
        span {
            color: $primary;
        }
        &:after {
            border-top: 4vw solid $secondary;
            border-bottom: 4vw solid $secondary;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $secondary;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $secondary;
                border-bottom: 3vw solid $secondary;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $secondary;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $secondary;
                border-bottom: 6vw solid $secondary;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $secondary;
            }
        }
    }
    &.gold {
        background:  $primary;
        &:after {
            border-top: 4vw solid $primary;
            border-bottom: 4vw solid $primary;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $primary;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $primary;
                border-bottom: 3vw solid $primary;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $primary;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $primary;
                border-bottom: 6vw solid $primary;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $primary;
            }
        }
    }
    &.silver {
        background:  $silver;
        &:after {
            border-top: 4vw solid $silver;
            border-bottom: 4vw solid $silver;
            border-right: 4vw solid #0000;
            border-left: 4vw solid $silver;
            @include media-breakpoint-up(xxl) {
                right: -3vw;
                border-top: 3vw solid $silver;
                border-bottom: 3vw solid $silver;
                border-right: 3vw solid #0000;
                border-left: 3vw solid $silver;
            }
            @include media-breakpoint-down(ms) {
                right: -6vw;
                border-top: 6vw solid $silver;
                border-bottom: 6vw solid $silver;
                border-right: 6vw solid #0000;
                border-left: 6vw solid $silver;
            }
        }
        span {
            color: $text-color;
        }
    }
}

.ribbon span {
    @include small-caps;
    font-size: 1.5vw;
    text-align: left;
    flex: 1 1 auto;
    line-height: 1;
    color: $white;
    display:block;
    position: relative;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
    p {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 1vw;
    }
    @include media-breakpoint-down(md) {
        //padding-left: 17vw;
        padding-left: 8px;
        padding-right: 8px;
    }
    @include media-breakpoint-down(ms) {
        font-size: 2.5vw;
        //padding-left: 21vw;
    }
}
.ribbon.rosette span:before {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-rosette.svg);
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.ribbon.silver.rosette span:before {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-rosette-dark.svg);
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.ribbon.house span:before  {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-house-light.svg);
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.ribbon.silver.house span:before  {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-house-dark.svg);
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.ribbon.keys span:before  {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-keys.svg);
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.ribbon.silver.keys span:before  {
    content: url(https://phq.s3.eu-west-2.amazonaws.com/create_homes/media/images/icon-keys-dark.svg);
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.ribbon.plus span:before  {
    content:'\f055';
    font-family: "Font Awesome 5 Pro";
    font-weight: normal;
    font-size: 2vw;
    position: absolute;
    width: 3vw;
    left: -3.5vw;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0.5;
    @include media-breakpoint-up(xxl) {
        width: 2.5vw;
    }
    @include media-breakpoint-down(ms) {
        width: 5vw;
        left: -6.5vw;
    }
}
.cd-hero {
    /* vertically align its content */
    display: block;
    width: 100%;
    background-size: cover;
    background: $primary;
}
.banner {
    position: relative;
    overflow: hidden;
    .carousel-inner {
        .carousel-item {
            @include media-breakpoint-down(ms) {
                height: 400px;
                width: auto;
                position: relative;
                left: -50%;
            }
            img {
                width: 100%;
            }
            &:before {
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgb(0,0,0);
                background: -moz-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
                background: linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            }
            &.blue {
                &:before {
                    background: rgb(28,50,221);
                    background: -moz-linear-gradient(180deg, rgba(28,50,221,0.6573004201680672) 0%, rgba(28,50,221,0) 30%);
                    background: -webkit-linear-gradient(180deg, rgba(28,50,221,0.6573004201680672) 0%, rgba(28,50,221,0) 30%);
                    background: linear-gradient(180deg, rgba(28,50,221,0.6573004201680672) 0%, rgba(28,50,221,0) 30%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c32dd",endColorstr="#1c32dd",GradientType=1);
                }
            }
            &.gold {
                &:before {
                    background: rgb(175,134,87);
                    background: -moz-linear-gradient(180deg, rgba(175,134,87,0.8827906162464986) 0%, rgba(175,134,87,0) 50%);
                    background: -webkit-linear-gradient(180deg, rgba(175,134,87,0.8827906162464986) 0%, rgba(175,134,87,0) 50%);
                    background: linear-gradient(180deg, rgba(175,134,87,0.8827906162464986) 0%, rgba(175,134,87,0) 50%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#af8657",endColorstr="#af8657",GradientType=1);
                }
            }
            &.dark {
                &:before {
                    background: rgb(0,0,0);
                    background: -moz-linear-gradient(180deg, rgba(0,0,0,0.34637605042016806) 0%, rgba(0,0,0,0) 100%);
                    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.34637605042016806) 0%, rgba(0,0,0,0) 100%);
                    background: linear-gradient(180deg, rgba(0,0,0,0.34637605042016806) 0%, rgba(0,0,0,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                }
            }
            // &:after {
            //     content:'';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     background: rgb(0,0,0);
            //     background: -moz-linear-gradient(0deg, rgba(0,0,0,0.9009978991596639) 0%, rgba(0,0,0,0) 50%);
            //     background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.9009978991596639) 0%, rgba(0,0,0,0) 50%);
            //     background: linear-gradient(0deg, rgba(0,0,0,0.9009978991596639) 0%, rgba(0,0,0,0) 50%);
            //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            //     opacity: 0.9;
            //     @include media-breakpoint-down(sm) {
            //         background: rgb(0,0,0);
            //         background: -moz-linear-gradient(0deg, rgba(0,0,0,0.8057598039215687) 0%, rgba(0,0,0,0) 86%);
            //         background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.8057598039215687) 0%, rgba(0,0,0,0) 86%);
            //         background: linear-gradient(0deg, rgba(0,0,0,0.8057598039215687) 0%, rgba(0,0,0,0) 86%);
            //         filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            //     }
            // }
            .carousel-caption {
                background: rgba($white, 0.6);
                font-family: $font-sans;
                color: $text-color;
                text-align: left;
                font-size: 1.125vw;
                top: 66%;
                z-index: 1;
                left: 5%;
                transform: translateY(-50%);
                bottom: inherit;
                padding: 4rem;
                width: calc(38% - 0px);
                word-break: break-word;
                border-left: 10px solid $primary;
                border-radius: 0 0 50px 0;
                font-weight: 500;
                @include media-breakpoint-down(sm) {
                    border-width: 6px;
                    padding: 1.5rem;
                }
                @include media-breakpoint-down(ms) {
                    top: 66%;
                    left: 30%;
                    font-size: 1.25rem;
                    border-radius: 0 0 30px 0;
                }
                .title {
                    font-family: $font-serif;
                    font-weight: 700;
                    color: $text-color;
                    // font-weight: 400;
                    // letter-spacing: 0.03rem;
                    font-size: 2vw;
                    line-height: 1.3;
                    margin-bottom: 1vw;
                    display: block;
                    @include media-breakpoint-down(ms) {
                        font-size: 2rem;
                        line-height: 1.3;
                        color: $text-color;
                    }
                }
                .subtitle {
                    font-family: $font-serif;
                    font-weight: 700;
                    color: $text-color;
                    // font-weight: 400;
                    // letter-spacing: 0.03rem;
                    font-size: 2vw;
                    line-height: 1.3;
                    margin-bottom: 1vw;
                    display: block;
                    @include media-breakpoint-down(ms) {
                        font-size: 2rem;
                        line-height: 1.3;
                        color: $text-color;
                    }
                }
                .btn-primary {
                    margin-top: 3rem;
                    @include media-breakpoint-down(ms) {
                        margin-top: 1rem;
                    }
                }
                .tinymce {
                    @include media-breakpoint-down(ms) {
                        display: none;
                    }
                }
            }
            // .carousel-caption {
            //     font-family: $font-sans;
            //     color: $white;
            //     text-align: left;
            //     font-size: 1.125vw;
            //     top: 75%;
            //     z-index: 1;
            //     left: 5%;
            //     transform: translateY(-50%);
            //     bottom: inherit;
            //     padding-left: 4rem;
            //     width: calc(38% - 0px);
            //     word-break: break-word;
            //     border-left: 10px solid $primary;
            //     border-radius: 0 0 50px 0;
            //     font-weight: 500;
            //     @include media-breakpoint-down(lg) {
            //         top: 66%;
            //     }
            //     @include media-breakpoint-down(sm) {
            //         border-width: 6px;
            //         padding: 1.5rem;
            //     }
            //     @include media-breakpoint-down(ms) {
            //         top: 66%;
            //         left: 30%;
            //         font-size: 1.25rem;
            //         border-radius: 0 0 30px 0;
            //     }
            //     .title {
            //         font-family: $font-serif;
            //         font-weight: 700;
            //         color: $white;
            //         // font-weight: 400;
            //         // letter-spacing: 0.03rem;
            //         font-size: 2vw;
            //         line-height: 1.3;
            //         margin-bottom: 1vw;
            //         display: block;
            //         @include media-breakpoint-down(ms) {
            //             font-size: 2rem;
            //             line-height: 1.3;
            //             color: $white;
            //         }
            //     }
            //     .btn-primary {
            //         margin-top: 3rem;
            //         @include media-breakpoint-down(ms) {
            //             margin-top: 1rem;
            //         }
            //     }
            //     .tinymce {
            //         @include media-breakpoint-down(ms) {
            //             display: none;
            //         }
            //     }
            // }
        }
    }
    &.no-caption {
        .carousel-inner {
            .carousel-item {
                &:after {
                    display: none;
                }
            }
        }
    }
    .carousel-indicators {
        z-index: 2;
        bottom: 8%;
        @include media-breakpoint-down(ms) {
            bottom: 13%;
        }
        li {
            border-radius: 50%;
            border: 1px solid #fff;
            height: 10px;
            width: 10px;
            opacity: 1;
            margin-right: 5px;
            margin-left: 5px;
            background: #0000;
            &.active {
                background: #fff;
            }
            @include media-breakpoint-down(ms) {
                height: 7px;
                width: 7px;
            }
        }
    }
}
.video-banner {
    height: 100%;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        height: 50vw;
    }
    @include media-breakpoint-down(ms) {
        margin-top: 0;
        height: 100%;
    }
    .arrow-area {
        position: absolute;
        height: 100%;
    }
    .script-text {
        @include media-breakpoint-down(ms) {
            display: none;
        }
    }
    .banner-img {
        video {
           width:100%;
           height:auto;
        }
        &:before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
        &.blue {
            &:before {
                background: rgb(28,50,221);
                background: -moz-linear-gradient(180deg, rgba(28,50,221,0.6573004201680672) 0%, rgba(28,50,221,0) 30%);
                background: -webkit-linear-gradient(180deg, rgba(28,50,221,0.6573004201680672) 0%, rgba(28,50,221,0) 30%);
                background: linear-gradient(180deg, rgba(28,50,221,0.6573004201680672) 0%, rgba(28,50,221,0) 30%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c32dd",endColorstr="#1c32dd",GradientType=1);
            }
        }
        &.gold {
            &:before {
                background: rgb(175,134,87);
                background: -moz-linear-gradient(180deg, rgba(175,134,87,0.8827906162464986) 0%, rgba(175,134,87,0) 50%);
                background: -webkit-linear-gradient(180deg, rgba(175,134,87,0.8827906162464986) 0%, rgba(175,134,87,0) 50%);
                background: linear-gradient(180deg, rgba(175,134,87,0.8827906162464986) 0%, rgba(175,134,87,0) 50%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#af8657",endColorstr="#af8657",GradientType=1);
            }
        }
        &.dark {
            &:before {
                background: rgb(0,0,0);
                background: -moz-linear-gradient(180deg, rgba(0,0,0,0.34637605042016806) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.34637605042016806) 0%, rgba(0,0,0,0) 100%);
                background: linear-gradient(180deg, rgba(0,0,0,0.34637605042016806) 0%, rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            }
        }
        // &:after {
        //     content:'';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     background: rgb(93,64,33);
        //     background: -moz-linear-gradient(0deg, rgba(93,64,33,0.7693452380952381) 0%, rgba(139,103,64,0) 100%);
        //     background: -webkit-linear-gradient(0deg, rgba(93,64,33,0.7693452380952381) 0%, rgba(139,103,64,0) 100%);
        //     background: linear-gradient(0deg, rgba(93,64,33,0.7693452380952381) 0%, rgba(139,103,64,0) 100%);
        //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d4021",endColorstr="#8b6740",GradientType=1);
        // }
        .caption {
            position: absolute;
            background: rgba($light, 0.9);
            font-family: $font-sans;
            text-align: left;
            font-size: 1.1255vw;
            top: 54%;
            z-index: 1;
            left: 12%;
            transform: translateY(-50%);
            bottom: inherit;
            padding: 4rem;
            width: calc(38% - 0px);
            word-break: break-word;
            border-left: 10px solid $primary;
            border-radius: 0 0 50px 0;
            @include media-breakpoint-down(ms) {
                top: 66%;
                left: 30%;
                font-size: 1.25rem;
                border-radius: 0 0 30px 0;
            }
            @include media-breakpoint-down(sm) {
                border-width: 6px;
                padding: 1.5rem;
            }
            .title {
                font-family: $font-serif;
                font-weight: 700;
                color: $primary;
                // font-weight: 400;
                // letter-spacing: 0.03rem;
                font-size: 2.25vw;
                line-height: 1.3;
                margin-bottom: 1vw;
                display: block;
                @include media-breakpoint-down(ms) {
                    font-size: 2rem;
                    line-height: 1.3;
                    color: $text-color;
                }
            }
            .btn-primary {
                @include media-breakpoint-down(ms) {
                    margin-top: 1rem;
                }
            }
            .tinymce {
                @include media-breakpoint-down(ms) {
                    display: none;
                }
            }
        }
        // .caption {
        //     position: absolute;
        //     width: 40%;
        //     bottom: 0;
        //     @include media-breakpoint-down(xl) {
        //         bottom: 0%;
        //     }
        //     @include media-breakpoint-down(lg) {
        //         bottom: -16%;
        //     }
        //     @include media-breakpoint-down(md) {
        //         bottom: -30%;
        //     }
        //     .title {
        //         @include media-breakpoint-down(lg) {
        //             font-size: 2rem;
        //         }
        //         @include media-breakpoint-down(md) {
        //             font-size: 1.5rem;
        //         }
        //     }
        // }
    }
    &.banner-cta .banner-img {
        video {
            position: absolute;
        }
        .caption {
            top: 18vw;
            @include media-breakpoint-down(lg) {
                top: 12vw;
            }
            .title {
                @include media-breakpoint-down(lg) {
                    font-size: 1.75rem;
                    margin-bottom: 1rem;
                }
            }
            .subtitle {
                font-family: $font-serif;
                font-weight: 700;
                color: $text-color;
                // font-weight: 400;
                // letter-spacing: 0.03rem;
                font-size: 2vw;
                line-height: 1.3;
                margin-bottom: 1vw;
                display: block;
                @include media-breakpoint-down(ms) {
                    font-size: 2rem;
                    line-height: 1.3;
                    color: $text-color;
                }
            }
        }
        .cta-alert {
            top: 18vw;
            position: relative;
        }
    }
}