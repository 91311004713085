.agent-pills {
    background: transparent;
    padding: 0;
    .tab-tag {
        position: absolute;
        left: 0px;
        width: 89px;
        height: auto;
        background: $secondary;
        color: $white;
        top: 0;
        bottom: 0;
        font-size: 2.5rem;
        text-align: center;
        line-height: 2;
    }
    li.nav-item {
        position: relative;
        .nav-link {
            @include small-caps;
            background: $secondary;
            color: $primary;
            padding: 2rem;
            border-radius: 0;
            font-size: 2rem;
            @include media-breakpoint-down(md) {
                padding: 2rem;
            }
            @include media-breakpoint-down(ms) {
                font-size: 1.25rem;
                font-weight: 700;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        .nav-link.active {
            background: darken($light, 10%);
            color: $primary;
            &:after {
                content:'\f0dd';
                @include font-awesome;
                color: darken($light, 10%);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -25px;
                font-size: 60px;
                line-height: 1;
                font-weight: 700;
            }
            .tab-tag {
                background: $secondary;
            }
        }
    }
}
.agent-pill-content {
    .grey-bg {
        h2 {
            margin-top: 0;
        }
        padding: 6rem 0;
        @include media-breakpoint-down(md) {
            padding: 3rem 0;
        }
    }
}
.booking-list {
    text-align: center;
    font-size: 1.5rem;
    @include media-breakpoint-down(lg) {
        border-top: 1px solid $grey;
    }
    @include media-breakpoint-down(lg) {
        text-align: left;
    }
    .title-row {
        background: $primary !important;
        color: $white;
        padding: 1.25rem 0;
        &.alt {
            background: $secondary !important;
        }
        .title {
            font-weight: 700;
        }
    }
    .booking-row {
        background: darken($light, 5%);
        padding: 1rem 0;
        @include media-breakpoint-down(lg) {
            font-size: 1.5rem;
            border-bottom: 1px solid $grey;
        }
        div {
            @include media-breakpoint-down(lg) {
                padding: 0.5rem;
            }
        }
        .btn {
            @include media-breakpoint-down(lg) {
                float: right;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-top: 1rem;
            }
        }
        .btn-square {
            padding: 1rem 2rem;
            font-size: 1.5rem;
            @include media-breakpoint-down(sm) {
                text-align: center;
                font-size: 1rem;
            }
        }
    }
    .mobile-title {
        font-weight: 700;
    }
}
.booking-list .booking-row:nth-of-type(even) {
    background: $white;
}
// Animations
.bounce-in.ng-animate {
    animation: none 0s;
}
.agent-filter {
    border-bottom: 1px solid $primary;
    .form-control {
        border-bottom: 0;
    }
}
.bounce-in {
    animation: bounce-in .9s 0s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
}

@keyframes bounce-in {
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(7px);
    }
    100% {
        transform: translateX(2px);
    }
}

@keyframes bounce-button-in {
    0% {
        transform: translateZ(0) scale(0);
    }
    100% {
        transform: translateZ(0) scale(0.7);
    }
}