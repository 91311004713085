body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    @include media-breakpoint-down(md) {
        font-size: 1.5rem;
    }
    @include media-breakpoint-down(ms) {
        font-size: 1.25rem;
    }
}

// Typography
$font-size-base: 1rem;
$line-height-base: 1.6;
$font-family-base: proxima-nova, sans-serif;
$font-size-base: 1.75rem;
$font-weight-base:  500;
$body-color: $text-color;
$font-serif: caecilia, sans-serif;
$font-script: ivypresto-text, serif;
$font-sans: proxima-nova, sans-serif;
$font-sans-bold: proxima-nova, sans-serif;

::selection {
  background: rgba(34,34,34, 0.99);
  color: $primary;
}
::-moz-selection {
  background: rgba(34,34,34, 0.99);
  color: $primary;
}
::-webkit-selection {
    background: rgba(34,34,34, 0.99);
    color: $primary;
}


@mixin font-reg {
    font-family: $font-sans;
    font-weight: 400;
}

@mixin font-awesome {
    font-family: "Font Awesome 5 Pro";
}

@mixin font-body {
    font-family: $font-sans;
    font-weight: 500;
}

@mixin font-serif {
    font-family: $font-serif;
    font-weight: 600;
}

@mixin font-sans {
    font-family: $font-sans;
    font-weight: 600;
}

@mixin font-script {
    font-family: $font-script;
    font-weight: 300;
}

@mixin font-sans-demi {
    font-family: $font-sans;
    font-weight: 600;
}
@mixin small-caps {
    font-family: $font-sans;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    font-size: 1.5rem;
}
$h1-font-size:  1.429em;
$h2-font-size:  1.357em;
$h3-font-size:  1.286em;
$h4-font-size:  1.214em;
$h5-font-size:  1.143em;
$h6-font-size:  0.75em;

.italic {
    font-style: italic;
}
.regular {
    font-weight: 400;
}
.sub-title {
    @include small-caps;
    opacity: 0.6;
    margin-bottom: 2rem;
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}
.sub-title-hoz {
    @include small-caps;
    opacity: 0.6;
    margin-bottom: 2rem;
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}
.small-caps {
    @include small-caps;
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    font-family: $font-serif;
    font-weight: 700;
    color: $primary;
    margin: 3rem 0;
    @include media-breakpoint-down(sm) {
        margin: 1rem 0;
    }
}
h1,.h1 {
    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }
}
h2,.h2 {
    font-size: 3rem;
    @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.75rem;
    }
}
h3,.h3 {
    font-size: 2.875rem;
    @include media-breakpoint-down(sm) {
        font-size: 1.75rem;
    }
}
h4,.h4 {
    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }
}
h5,.h5 {
    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }
}
h6,.h6 {
    @include font-body;
    font-style: italic;
    font-weight: 500;
    font-size: 1.25rem;
    color: $text-color;
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
    }
}
.title {
    h1,.h1,
    h2,.h2,
    h3,.h3,
    h4,.h4,
    h5,.h5,
    h6,.h6 {
        margin-top: 0;
    }
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    &.title {
        margin-top: 0;
        margin-bottom: 1rem;
    }
}