.search-results {
    .dev-details,
    .plot-details {
        .item {
            color: $white;
            display: inline;
            @include small-caps;
            font-weight: 600;
            font-size: 1vw;
            text-align: center;
            padding: 1rem 0;
            border-right: 1px solid $border;
            margin-top: 2rem;
            @include media-breakpoint-down(xxl) {
                font-size: 0.75vw;
                padding: 0.5rem 0;
                margin-top: 1rem;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
                font-size: 0.875vw;
                padding: 0.5rem 0;
                margin-top: 1rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 1.2vw;
                padding: 0.5rem 0;
            }
            @include media-breakpoint-down(md) {
                font-size: 1.6vw;
            }
            @include media-breakpoint-down(ms) {
                font-size: 2vw;
            }
            &:nth-child(4) {
                border-right: 0;
            }
            &.link {
                background: $secondary;
                a {
                    color: $primary;
                    font-size: 1vw;
                    &:hover {
                        color: $white;
                    }
                    &:after {
                        height: 0.4vw;
                        width: 0.4vw;
                        right: 7px;
                        @include media-breakpoint-down(lg) {
                            height: 0.6vw;
                            width: 0.6vw;
                        }
                        @include media-breakpoint-down(ms) {
                            height: 1vw;
                            width: 1vw;
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 1vw;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 1.5vw;
                    }
                    @include media-breakpoint-down(ms) {
                        font-size: 2vw;
                    }
                }
            }
            img {
                display: inline;
                height: 1vw;
                @include media-breakpoint-down(md) {
                    height: 1.5vw;
                }
                @include media-breakpoint-down(ms) {
                    height: 2.5vw;
                }
            }
            .item-inner {
                border: 0;
                &:after {
                    display: none;
                }
            }
            &:nth-child(3) .item-inner {
                border: 0;
            }
            &:nth-child(4) .item-inner {
                border: 0;
            }
        }
        &.dark {
            .item {
                color: $text-color;
            }
        }
    }
    .house-type {
        margin-bottom: 2vw;
        @include media-breakpoint-down(lg) {
            margin-bottom: 4vw;
        }
        a {
            // display: block;
            color: $text-color;
            &:hover {
                text-decoration: none;
                .img-box img {
                    transform: scale(1.2);
                }
            }
            .card {
                border-radius: 0;
                border: 0;
                background: transparent;
                display: flex;
                .promotion-tab {
                    top: 0;
                }
                .img-box {
                    position: relative;
                    margin: auto;
                    overflow: hidden;
                    width: 100%;
                }
                .card-header {
                    background: transparent;
                    border-radius: 0;
                    border: 0;
                    height: auto;
                    padding-left: 0;
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
                .card-img, .card-img-top {
                    border-radius: 0;
                    max-width: 100%;
                    transition: all 1s ease;
                    display: block;
                    width: 100%;
                    height: auto;
                    transform: scale(1);
                }
                .card-body {
                    padding-left: 0;
                    padding-right: 0;
                    display: flex;
                }
                .dev-details {
                    display: flex;
                    span {
                        display: inline-block;
                    }
                }
            }
            .featured {
                .dev-details.dark {
                    padding-left: 2rem;
                    padding-right: 2rem;
                    .item {
                        .item-inner {
                            color: $white;
                            img {
                                fill: #ffffff;
                            }
                        }
                    }
                    background: $primary;
                    color: $white;
                }
                .card-body {
                    padding: 2rem;
                    background: $primary;
                    color: $white;
                }
                .btn-primary {
                    background: $primary;
                    color: $white;
                    padding-right: 3rem;
                    &:after {
                        content: url(/media/images/arrow-right-light.svg);
                        right: 12px;
                    }
                }
            }
            .btn-area {
                position: absolute;
                display: block;
                bottom: 0;
                right: 0;
            }
            .icon-area {
                width: 5rem;
                height: 5rem;
                background: $primary;
                position: absolute;
                text-align: center;
                color: $white;
                font-size: 2.5rem;
                top: 0;
                right: 0;
                padding: 0.5rem;
                i {
                    width: 50%;
                    margin: auto;
                    position: absolute;
                    top: 50%; left: 50%;
                    -webkit-transform: translate(-50%,-50%);
                        -ms-transform: translate(-50%,-50%);
                            transform: translate(-50%,-50%);
                }
            }
            .btn-primary {
                background: $light;
                padding: 14px 20px;
                height: 40px;
                left: 0;
                transition: left 0.3s ease;
                &:before {
                    display: none;
                }
                &:hover {
                    left: 5px;
                }
            }
        }
        .title {
            h3 {
                @include font-sans;
                color: $text-color;
                font-size: 1.5rem;
                margin-bottom: 0;
                font-weight: 700;
                color: $primary;
                @include media-breakpoint-down(lg) {
                    font-size: 1.25rem;
                }
                @include media-breakpoint-down(ms) {
                    font-size: 1.25rem;
                    margin-bottom: 0;
                }
            }
        }
    }
    ul.social-list li.text:after {
        border: 0;
    }
    ul.social-list:after {
        border: 0;
    }
}


