.calc-pills {
    background: transparent;
    padding: 0;
    .tab-tag {
        position: absolute;
        left: 0px;
        width: 89px;
        height: auto;
        background: $secondary;
        color: $white;
        top: 0;
        bottom: 0;
        font-size: 2.5rem;
        text-align: center;
        line-height: 2;
    }
    li.nav-item {
        .nav-link {
            @include small-caps;
            background: $primary;
            color: $white;
            padding: 2rem;
            border-radius: 0;
            border-right: 1px solid $white;
            @include media-breakpoint-down(md) {
                padding: 2rem;
            }
            @include media-breakpoint-down(sm) {
                border-top: 1px solid $white;
            }
            @include media-breakpoint-down(ms) {
                font-size: 0.75rem;
                font-weight: 700;
                padding-left: 1rem;
                padding-right: 1rem;
            }
            .nav-link-icon {
                position: relative;
                padding-left: 24px;
            }
            .nav-link-icon:before {
                content: url(/media/images/icon-help-to-buy-calc-round-light.svg);
                position: absolute;
                left: -15px;
                width: 26px;
                height: 26px;
                margin-right: 1.5rem;
                @include media-breakpoint-down(ms) {
                    top: -9px;
                    left: -7px;
                }
            }
            .help.nav-link-icon:before {
                content: url(/media/images/icon-help-to-buy-calc-round-light.svg);
            }
            .mortgage.nav-link-icon:before {
                content: url(/media/images/icon-mortgage-calc-round-light.svg);
            }
            .stamp.nav-link-icon:before {
                content: url(/media/images/icon-stamp-calc-round-light.svg);
            }
        }
        .nav-link.active {
            background: $light;
            color: $primary;
            .help.nav-link-icon:before {
                content: url(/media/images/icon-help-to-buy-calc-round-primary.svg);
            }
            .mortgage.nav-link-icon:before {
                content: url(/media/images/icon-mortgage-calc-round-primary.svg);
            }
            .stamp.nav-link-icon:before {
                content: url(/media/images/icon-stamp-calc-round-primary.svg);
            }
            .tab-tag {
                background: $secondary;
            }
        }
    }
}
.calc-content {
    .grey-bg {
        h2 {
            margin-top: 0;
        }
        padding: 6rem 0;
        @include media-breakpoint-down(md) {
            padding: 3rem 0;
        }
    }
}
.calc-icon {
    img {
        max-width: 100px;
    }
}
.calc-description {
    font-size: 1.5rem;
    @include media-breakpoint-down(ms) {
        font-size: 1rem;;
    }
}

.calc-form {
    .form-group {
        @include media-breakpoint-only(xl) {
            margin-bottom: 0.5rem;
        }
        @include media-breakpoint-only(ms) {
            margin-bottom: 0.5rem;
        }
        .times {
            background: -moz-linear-gradient(top,  rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0.3) 0%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to bottom,  rgba(255,255,255,0.3) 0%,rgba(255,255,255,0) 100%);
            padding: 2rem;
            color: $white;
            .form-check {
                text-align: left;
                margin-bottom: 1rem;
                .form-check-label {
                    .form-check-input {
                        position: absolute;
                        margin-top: 0;
                    }
                }
            }
        }
        &.form-check {
            .form-check-input {
                position: static;
                @include media-breakpoint-down(sm) {
                    position: absolute;
                }
            }
        }
        .form-text.text-muted {
            color: $white !important;
            a {
                color: $white;
                text-decoration: underline;
            }
        }
        label {
            @include small-caps;
            color: $text-color;
            margin-bottom: 1.5rem;
            @include media-breakpoint-only(xl) {
                margin-bottom: 1rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
            @include media-breakpoint-only(ms) {
                margin-bottom: 0.5rem;
            }
        }
        select {
            background: url(https://phq.s3.eu-west-2.amazonaws.com/nicholas_king_homes/media/select.svg);
            -moz-appearance: none;
            -webkit-appearance: none;
            padding-right: 1.25EM;
            appearance: none;
            position: relative;
            background-color: $white;
            background-repeat: no-repeat;
            background-size: 1.5em 1em;
            background-position: 95% 50%;
            background-clip: border-box;
            width: 100%;
        }
        .form-control {
            border: 0;
            font-size: 2rem;
            font-weight: 500;
            height: 64px;
            color: $text-color;
            border-radius: 0;
            padding-left: 2rem;
            background: $white;
            @include media-breakpoint-down(xl) {
                height: 64px;
                font-size: 1rem;
                padding-left: 1rem;
            }
            @include media-breakpoint-down(md) {
                height: 48px;
            }
            &.alt {
                border-radius: 0 50px 50px 0;
                background: $primary;
                color: #f1e1ce;
                &::-webkit-input-placeholder {
                  color: $white !important;
                }
                &::-moz-placeholder {
                  color: $white !important;
                }
                &:-ms-input-placeholder {
                  color: $white !important;
                }
                &:-moz-placeholder {
                  color: $white !important;
                }
            }
        }
        .alert.calculator {
            border: 0;
            font-size: 1.25rem;
            height: calc(1.6em + 2rem + 2px);
            color: $text-color;
            border-radius: 0;
            padding-left: 2rem;
            border-radius: 50px;
            background: $primary;
            color: $white;
            margin-top: 1.25rem;
            &::-webkit-input-placeholder {
              color: $white !important;
            }
            &::-moz-placeholder {
              color: $white !important;
            }
            &:-ms-input-placeholder {
              color: $white !important;
            }
            &:-moz-placeholder {
              color: $white !important;
            }
            @include media-breakpoint-down(xl) {
                height: calc(0.75em + 2rem + 2px);
                font-size: 1rem;
                padding-left: 1rem;
            }
            @include media-breakpoint-down(md) {
                margin: 0.5rem 0;
            }
        }
        .input-group {
            .input-group-prepend {
                .input-group-text {
                    border-radius: 0;
                    background: lighten($primary, 15%);
                    border: 0;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    font-size: 2rem;
                    border-radius: 5px 0 0 5px;
                    color: $white;
                    @include media-breakpoint-down(md) {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        font-size: 1rem;
                    }
                }
                .input-group-text.alt {
                    background: darken($primary, 10%);
                    border-radius: 50px 0 0 50px;
                    color: $white;
                }
            }
        }
        .form-check-label {
            margin-bottom: 0;
            text-transform: none;
            letter-spacing: 0;
            @include media-breakpoint-down(sm) {
                font-size: 0.5rem;
            }
            @include media-breakpoint-down(ms) {
                font-size: 1rem;
                text-transform: none;
                letter-spacing: 0;
            }
            &.reverse {
                small a {
                    color: $text-color;
                }
            }
            small {
                a {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
    .submit,
    .form-check {
        text-align: right;
        margin-bottom: 0.5rem;
        line-height: 1;
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
        &.alt {
            text-align: left !important;
            .form-check-input {
                position: absolute;
                .form-check-label {
                    @include media-breakpoint-down(ms) {
                        text-transform: none;
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}