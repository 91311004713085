footer {
    .footer-header,
    .footer-footer {
        margin: 7rem 0;
        @include media-breakpoint-down(sm) {
           margin: 3rem 0;
        }
    }
    .footer-footer {
        ul.social-list {
            position: static;
            font-size: 2.5rem;
            text-align: center;
            margin: auto;
            @include media-breakpoint-down(sm) {
               margin-top: -5px;
               font-size: 2rem;
            }
            li {
                a {
                    color: $primary;
                }
            }
        }
    }
    .title {
        @include small-caps;
        font-size: 1.5rem;
        color: $text-color;
        margin-bottom: 2rem;
        @include media-breakpoint-down(lg) {
           text-align: center;
        }
    }
    ul.social-list {
        width: inherit;
        &:after {
            display: none;
        }
    }
    ul.footer-list {
        margin: 0;
        padding: 0;
        @include media-breakpoint-down(lg) {
           margin-bottom: 5rem;
        }
        li {
            list-style: none;
            margin: 0.5rem 0;
            line-height: 1.4;
            @include media-breakpoint-down(lg) {
               text-align: center;
            }
             a {
                color: $text-color;
                font-size: 1.5rem;
             }
        }
    }
}
.phq-logo {
    max-width: 160px;
}