.dimensions {
    margin-top: 3rem;
    .title {
        @include small-caps;
        color: $primary;
        margin-bottom: 2rem;
    }
}
.floorplan {
    .floorplan-img {
        @include media-breakpoint-up(xxl) {
            padding: 1vw 3vw 3vw;
        }
    }
    h2.floor-level {
        @include font-sans;
        color: $text-color;
        font-size: 2.75rem;
        @include media-breakpoint-up(xxl) {
            padding-left: 3vw;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }
    }
    .floorplan-sml-img {
        margin-left: -14rem;
        @include media-breakpoint-down(lg) {
            margin-left: 0;
        }
    }
    aside {
        h1 {
            bottom: 0;
        }
        .sub-title {
            bottom: 0;
            margin-bottom: 0;
            color: $text-color;
            opacity: 0.5;
        }
    }
    .btn-area {
        margin-top: 2rem;
        text-align: right;
        margin-right: 3rem;
    }
    .nav {
        position: absolute;
        bottom: 5%;
        right: 47%;
        z-index: 1;
        @include media-breakpoint-down(lg) {
            position: static;
            padding: 3rem 3rem 0;
            display: flex;
            justify-content: center;
        }
        .nav-link {
            @include small-caps;
            border-radius: 0;
            @include media-breakpoint-down(ms) {
                font-size: 1rem;
            }
        }
    }
}