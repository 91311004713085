.hero-img {
    height: 57vw;
    @include media-breakpoint-down(md) {
        height: 87vw;
    }
    @include media-breakpoint-down(ms) {
        height: 106vw;
    }
}
.hero-swiper {
    .swiper-slide {
        &:before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }

    }
    &.swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 64px;
        @include media-breakpoint-down(ms) {
            bottom: 49px;
        }
        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            @include media-breakpoint-down(ms) {
                width: 10px;
                height: 10px
            }
        }
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: $white;
            border: 2px solid $white;
            @include media-breakpoint-down(ms) {
                border-width: 1px;
            }
        }
        .swiper-pagination-bullet {
            background: transparent;
            border: 2px solid $white;
            opacity: 1;
            @include media-breakpoint-down(ms) {
                border-width: 1px;
            }
        }
    }
    .carousel-caption {
        background: rgba($white, 0.7);
        font-family: $font-sans;
        color: $text-color;
        text-align: left;
        font-size: 1.125vw;
        top: 54%;
        z-index: 1;
        left: 5%;
        transform: translateY(-50%);
        bottom: inherit;
        padding: 4rem;
        width: calc(38% - 0px);
        word-break: break-word;
        border-left: 10px solid $primary;
        border-radius: 0 0 50px 0;
        font-weight: 500;
        @include media-breakpoint-down(xl) {
            top: 46%;
        }
        @include media-breakpoint-down(lg) {
            padding: 2rem;
            top: 66%;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
            width: calc(47% - 0px);
        }
        @include media-breakpoint-down(sm) {
            border-width: 6px;
            padding: 1.5rem;
        }
        @include media-breakpoint-down(ms) {
            top: 66%;
            left: 10%;
            font-size: 1.25rem;
            border-radius: 0 0 30px 0;
            right: 10%;
            width: auto;
        }
        .title {
            font-family: $font-serif;
            font-weight: 700;
            color: $text-color;
            // font-weight: 400;
            // letter-spacing: 0.03rem;
            font-size: 2vw;
            line-height: 1.3;
            margin-bottom: 1vw;
            display: block;
            @include media-breakpoint-down(ms) {
                font-size: 2rem;
                line-height: 1.3;
                color: $text-color;
            }
        }
        .btn-primary {
            font-size: 1.5rem;
            @include media-breakpoint-down(lg) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-down(sm) {
                font-size: 1rem;
            }
            @include media-breakpoint-down(ms) {
                margin-top: 1rem;
            }
        }
        .tinymce {
            @include media-breakpoint-down(ms) {
                display: none;
            }
        }
    }
    .video-banner {
        height: 57vw;
        position: relative;
        overflow: hidden;
        @include media-breakpoint-down(md) {
            height: 87vw;
        }
        .arrow-area {
            position: absolute;
            height: 100%;
        }
        .script-text {
            @include media-breakpoint-down(ms) {
                display: none;
            }
        }
        video {
           width: auto;
           height: inherit;
        }
        &:before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
        .caption {
            position: absolute;
            background: rgba($light, 0.9);
            font-family: $font-sans;
            text-align: left;
            font-size: 1.1255vw;
            top: 54%;
            z-index: 1;
            left: 12%;
            transform: translateY(-50%);
            bottom: inherit;
            padding: 4rem;
            width: calc(38% - 0px);
            word-break: break-word;
            border-left: 10px solid $primary;
            border-radius: 0 0 50px 0;
            @include media-breakpoint-down(xl) {
                top: 46%;
            }
            @include media-breakpoint-down(lg) {
                padding: 2rem;
                top: 41%;
            }
            @include media-breakpoint-down(sm) {
                border-width: 6px;
                padding: 1.5rem;
            }
            @include media-breakpoint-down(ms) {
                top: 66%;
                left: 10%;
                font-size: 1.25rem;
                border-radius: 0 0 30px 0;
                right: 10%;
                width: auto;
            }
            .title {
                font-family: $font-serif;
                font-weight: 700;
                color: $primary;
                // font-weight: 400;
                // letter-spacing: 0.03rem;
                font-size: 2.25vw;
                line-height: 1.3;
                margin-bottom: 1vw;
                display: block;
                @include media-breakpoint-down(ms) {
                    font-size: 2rem;
                    line-height: 1.3;
                    color: $text-color;
                }
            }
            .btn-primary {
                @include media-breakpoint-down(ms) {
                    margin-top: 1rem;
                }
            }
            .tinymce {
                @include media-breakpoint-down(ms) {
                    display: none;
                }
            }
        }
        &.banner-cta .banner-img {
            video {
                position: absolute;
            }
            .caption {
                top: 18vw;
                @include media-breakpoint-down(lg) {
                    top: 12vw;
                }
                .title {
                    @include media-breakpoint-down(lg) {
                        font-size: 1.75rem;
                        margin-bottom: 1rem;
                    }
                }
            }
            .cta-alert {
                top: 18vw;
                position: relative;
            }
        }
    }
}
.development-item {
    -webkit-box-shadow: 0px 13px 24px -12px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 13px 24px -12px rgba(0,0,0,0.54);
    box-shadow: 0px 13px 24px -12px rgba(0,0,0,0.54);
    background: $white;
    .item-body {
        padding: 4rem;
        .item-text {
            margin-top: 1rem;
            font-size: 1.5rem;
            color: $text-color;
        }
        .distance {
            @include small-caps;
            color: $primary;
            font-size: 1.5rem;
        }
    }
    .item-footer {
        background: $primary;
    }
    .logo {
        img {
            max-height: 100px;
        }
    }
}
.swiper-container.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 4px;
}
/****** GENERAL PAGINATION *****/

.swiper-pagination-nk {
    position: static;
    text-align: center;
    -webkit-transition: .3s opacity;
    -o-transition: .3s opacity;
    transition: .3s opacity;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    z-index: 10;
    margin-top: 3rem;
    @include media-breakpoint-down(sm) {
        bottom: -60px;
    }
    @include media-breakpoint-down(ms) {
        margin-top: 20px;
    }
    .swiper-pagination-bullet {
        vertical-align: middle;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        &:focus {
            outline: none;
        }
        @include media-breakpoint-down(sm) {
            width: 15px;
            height: 15px;
        }
    }
    .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
    }
}
.swiper-container  {
    &.gallery-slider,
    &.inspiration-gallery-slider,
    &.inspiration-slider,
    &.offset-slider,
    &.nearby-slider {
        .swiper-wrapper {
            margin: 3vw 0 2vw;
            @include media-breakpoint-down(sm) {
                margin-top: 9vw;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            height: 22px;
            background-size: 27px 22px;
        }
        .swiper-button-next-inspiration,
        .swiper-button-next {
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 46.9 46.9' style='enable-background:new 0 0 46.9 46.9;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23205696;%7D%0A%3C/style%3E%3Cpath class='st0' d='M23.4,46.9c12.9,0,23.4-10.5,23.4-23.4C46.9,10.5,36.4,0,23.4,0C10.5,0,0,10.5,0,23.4 C0,36.4,10.5,46.9,23.4,46.9z M17.9,13.2l1.8-1.8c0.7-0.7,1.9-0.7,2.7,0L33,22.1c0.7,0.7,0.7,1.9,0,2.7L22.3,35.5 c-0.7,0.7-1.9,0.7-2.7,0l-1.8-1.8c-0.7-0.7-0.7-1.9,0-2.7l7.6-7.6l-7.6-7.6C17.1,15.1,17.1,13.9,17.9,13.2z'/%3E%3C/svg%3E") !important;
            right: -60px;
        }
        .swiper-button-prev-inspiration,
        .swiper-button-prev {
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 46.9 46.9' style='enable-background:new 0 0 46.9 46.9;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23205696;%7D%0A%3C/style%3E%3Cpath class='st0' d='M23.4,0C10.5,0,0,10.5,0,23.4c0,12.9,10.5,23.4,23.4,23.4c12.9,0,23.4-10.5,23.4-23.4C46.9,10.5,36.4,0,23.4,0z M29,33.7l-1.8,1.8c-0.7,0.7-1.9,0.7-2.7,0L13.9,24.8c-0.7-0.7-0.7-1.9,0-2.7l10.7-10.7c0.7-0.7,1.9-0.7,2.7,0l1.8,1.8 c0.7,0.7,0.7,1.9,0,2.7l-7.6,7.6L29,31C29.8,31.8,29.8,33,29,33.7z'/%3E%3C/svg%3E") !important;
            left: -60px;
        }
    }
    .swiper-wrapper {
        height: auto;
    }
}
.nearby-slider,
.offset-slider {
    .development-item {
        .item-footer .dev-details .item {
            &:nth-child(3) .item-inner {
                &:after {
                    position: absolute;
                    right: 0;
                    content: '';
                    font-family: proxima-nova, sans-serif;
                }
            }
        }

    }
}
.swiper-container  {
    &.inspiration-slider {
        .swiper-slide {
            height: 40vw;
            @include media-breakpoint-down(xxl) {
                height: 26vw;
            }
            @include media-breakpoint-down(xl) {
                height: 40vw;
            }
            @include media-breakpoint-down(lg) {
                height: 40vw;
            }
            @include media-breakpoint-down(md) {
                height: 80vw;
            }
            @include media-breakpoint-down(sm) {
                height: 80vw;
            }
            .inspiration-img {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background: rgba($black, 0.4);
                }
                .text-area {
                    position: absolute;
                    color: $white;
                    bottom: 0;
                    right: 0;
                    padding: 5rem;
                    width: 80%;
                    z-index: 1;
                    text-align: right;
                    @include media-breakpoint-only(xxl) {
                        width: 100%;
                    }
                    @include media-breakpoint-down(sm) {
                        padding: 3rem;
                    }
                    h3 {
                        color: $white;
                        @include media-breakpoint-down(sm) {
                            font-size: 2rem;
                        }
                    }
                    .description {
                        color: $white;
                        @include media-breakpoint-down(ms) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.gallery {
    &.rotate-text aside h1,
    &.rotate-text aside .sub-title {
        bottom: 0;
        // margin-bottom: 0;
    }
}
.swiper-container  {
    &.gallery-slider {
        padding-bottom: 3rem;
        @include media-breakpoint-down(md) {
            padding-bottom: 5rem;
        }
        .swiper-slide {
            height: 40vw;
            @include media-breakpoint-down(xxl) {
                height: 26vw;
            }
            @include media-breakpoint-down(xl) {
                height: 40vw;
            }
            @include media-breakpoint-down(lg) {
                height: 40vw;
            }
            @include media-breakpoint-down(md) {
                height: 80vw;
            }
            @include media-breakpoint-down(sm) {
                height: 80vw;
            }
            &.gallery-item {
                a {
                    display: block;
                }
                h3 {
                    @include font-reg;
                    color: $white;
                    font-size: 2rem;
                    font-weight: 500;
                    margin-top: 2rem;
                    @include media-breakpoint-down(md) {
                        font-size: 2rem;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 1.5rem;
                    }
                }

            }
            .inspiration-img {
                position: relative;
                .text-area {
                    position: absolute;
                    color: $white;
                    bottom: 0;
                    right: 0;
                    padding: 5rem;
                    width: 80%;
                    z-index: 1;
                    text-align: right;
                    @include media-breakpoint-down(sm) {
                        padding: 3rem;
                    }
                    h3 {
                        color: $white;
                        @include media-breakpoint-down(sm) {
                            font-size: 2rem;
                        }
                    }
                    .description {
                        color: $white;
                    }
                }
            }
        }
    }
}
.inspiration-gallery {
    margin: 10rem 0 6rem;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin: 5rem 0 2rem;
    }
    .swiper-container-horizontal>.swiper-scrollbar {
        width: 100%;
    }
    .swiper-button-next-inspiration-gallery, .swiper-button-prev-inspiration-gallery {
        margin-left: 0;
        @include media-breakpoint-down(sm) {
            top: -15px !important;
            margin-top: 0;
        }
    }
    .swiper-button-prev-inspiration-gallery {
        right: 0px !important;
        left: inherit !important;
    }
    .swiper-button-next-inspiration-gallery {
        right: 70px !important;
        left: inherit !important;
        @include media-breakpoint-down(sm) {
            right: 50px !important;
        }
    }
    .all-btn {
        right: 0;
    }
    .title {
        position: absolute;
        left: 0;
        top: -62px;
        @include media-breakpoint-down(sm) {
            position: static;
        }
    }
}
.swiper-container  {
    &.inspiration-gallery-slider {
        padding-bottom: 3rem;
        @include media-breakpoint-down(xl) {
            padding-bottom: 5rem;
        }
        .swiper-wrapper {
            @include media-breakpoint-down(sm) {
                margin-top: 6vw;
            }
        }
        &.swiper-container-horizontal .swiper-scrollbar.bar {
            width: 100%;
        }
        .swiper-slide {
            height: 30vw;
            @include media-breakpoint-down(xxl) {
                height: 29vw;
            }
            @include media-breakpoint-down(xl) {
                height: 40vw;
            }
            @include media-breakpoint-down(lg) {
                height: 40vw;
            }
            @include media-breakpoint-down(md) {
                height: 42vw;
            }
            @include media-breakpoint-down(sm) {
                height: 80vw;
            }
            &.gallery-item {
                a {
                    display: block;
                }
                h3 {
                    @include font-reg;
                    color: $white;
                    font-size: 1.5rem;
                    font-weight: 500;
                    margin-top: 2rem;
                    @include media-breakpoint-down(sm) {
                        font-size: 1rem;
                    }
                }

            }
            .inspiration-img {
                position: relative;
                .text-area {
                    position: absolute;
                    color: $white;
                    bottom: 0;
                    right: 0;
                    padding: 5rem;
                    width: 80%;
                    z-index: 1;
                    text-align: right;
                    @include media-breakpoint-down(sm) {
                        padding: 3rem;
                    }
                    h3 {
                        color: $white;
                        @include media-breakpoint-down(sm) {
                            font-size: 2rem;
                        }
                    }
                    .description {
                        color: $white;
                    }
                }
            }
        }
    }
}
/****** NEWS SLIDER ******/
.news-slider {
    .view-all {
        padding-right: 60px;
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        @include media-breakpoint-down(ms) {
            padding: 25px 0;
        }
    }
    .swiper-container {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
        &.gallery-top {
            height: 80%;
            width: 100%;

            .swiper-slide {
                .content {
                    padding: 0 4vw;
                    @include media-breakpoint-down(sm) {
                        min-height: 40vh;
                    }
                    .sub-title {
                        margin-bottom: 2rem;
                        color: $primary;
                        @include media-breakpoint-down(md) {
                            margin-top: 2rem;
                        }
                    }
                    h2 {
                        margin-bottom: 2rem;
                        margin-top: 0;
                        color: $text-color;
                        @include media-breakpoint-down(md) {
                            font-size: 2rem;
                        }
                        @include media-breakpoint-down(ms) {
                            font-size: 1.75rem;
                        }
                    }
                    .description {
                        margin-bottom: 2rem;
                        @include media-breakpoint-only(md) {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }

        &.gallery-thumbs {
            height: 20%;
            box-sizing: border-box;
            padding: 10px 0;
            position: relative;
            z-index: 0;
            margin-top: 3rem;
            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
            @include media-breakpoint-down(md) {
                .promotion-tab span {
                    top: -74px;
                    width: 68px;
                    left: 1px;
                    font-size: 1rem;
                }
                .promotion-tab {
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 150px solid $orange;
                    border-bottom: 150px solid transparent;
                    border-right: 150px solid transparent;
                    z-index: 1;
                    &.orange {
                        border-top: 100px solid $orange;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                    }
                    &.yellow {
                        border-top: 100px solid $yellow;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                        span {
                            color: $text-color;
                        }
                    }
                    &.green {
                        border-top: 100px solid $green;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                    }
                    &.teal {
                        border-top: 100px solid $teal;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                    }
                    &.purple {
                        border-top: 100px solid $purple;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                    }
                    &.dark {
                        border-top: 100px solid $secondary;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                        span {
                            color: $primary;
                        }
                    }
                    &.gold {
                        border-top: 100px solid $primary;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                    }
                    &.silver {
                        border-top: 100px solid $silver;
                        border-bottom: 100px solid transparent;
                        border-right: 100px solid transparent;
                        span {
                            color: $text-color;
                        }
                    }
                }

            }
            .swiper-wrapper {
                height: 100%;
                margin-left: 18vw;
            }
            .swiper-buttons {
                position: absolute;
                // background: $light;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                width: 18vw;
                @include media-breakpoint-down(sm) {
                    position: static;
                    height: inherit;
                    margin: auto;
                    width: inherit;
                }
            }
            .swiper-button-next-gallery-thumbs, .swiper-container-rtl .swiper-button-prev {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233C3C3B;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='Component_30_77' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
            }
            .swiper-button-prev-gallery-thumbs, .swiper-container-rtl .swiper-button-next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Component_30_76' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233C3C3B;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/svg%3E") !important;
            }
            .swiper-button-next-gallery-thumbs, .swiper-button-prev-gallery-thumbs {
                position: static;
                display: inline-block;
                margin: 0 10px;
            }
        }

        &.gallery-thumbs .swiper-slide {
            width: 25%;
            opacity: 0.4;
            background-size: cover;
            background-position: center;
            .title {
                @include font-serif;
                font-size: 1.5rem;
                margin: 1rem 0;
            }
        }

        &.gallery-thumbs .swiper-slide-thumb-active {
            opacity: 1;
        }
    }
    .btn-square {
        padding: 2rem;
        bottom: 0;
        transition: bottom 0.3s ease,
                    background 0.3s ease,
                    color 0.3s ease;
        @include media-breakpoint-down(sm) {
            padding: 1rem 2rem;
        }
        &:hover {
            bottom: 5px;
            right: 0;
            color: $primary;
            background: $secondary;
        }
    }
}
.incentives-swiper {
    .swiper-slide {
        background: $light;
        .tool {
            height: 100%;
        }
    }
}
.testimonials.swiper-container {
    width: 100%;
    height: 100%;
    .swiper-pagination-bullet-active {
        background: $primary;
    }
    .swiper-slide {
        background: $white;
        min-height: 15vw;
    }
    .testimonial {
        width: 60vw;
        @include font-script;
        background: $white;
        font-style: italic;
        text-align: center;
        padding: 3rem 0;
        margin: auto;
        font-size: 2.5rem;
        position: relative;
        // min-height: 15vw;
        &:before,
        &:after {
            content: '';
            background-repeat: no-repeat;
            position: absolute;
            width: 30px;
            height: 30px;
            @include media-breakpoint-down(sm) {
                width: 18px;
                height: 18px;
            }
        }
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 30.4 24.2' style='enable-background:new 0 0 30.4 24.2;' xml:space='preserve'%3E%3Cpath id='Path_1185' d='M15.7,16.8c0,4.3,2.6,7.4,6.6,7.4c3.5,0,5.3-2.1,5.3-5.3c0.09-2.17-1.6-4.01-3.78-4.1c-0.04,0-0.08,0-0.12,0 c-1.02-0.07-2.03,0.18-2.9,0.7c-0.38-1.06-0.55-2.18-0.5-3.3c0-4.6,4-8.5,10.1-10.9L30,0C21.7,2.8,15.7,8.8,15.7,16.8z M0,16.8 c0,4.3,2.6,7.4,6.6,7.4c3.5,0,5.3-2.1,5.3-5.3c0.09-2.17-1.6-4.01-3.78-4.1c-0.04,0-0.08,0-0.12,0c-1.01-0.05-2.02,0.2-2.9,0.7 c-0.38-1.06-0.55-2.18-0.5-3.3c0-4.6,4-8.5,10.1-10.9L14.3,0C6,2.8,0,8.8,0,16.8z'/%3E%3C/svg%3E");
            top: 0;
            left: -10%;
            @include media-breakpoint-down(sm) {
                    top: 11px !important;
                    margin-top: 0 !important;
                }
        }
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 30.4 24.2' style='enable-background:new 0 0 30.4 24.2;' xml:space='preserve'%3E%3Cpath id='Path_1186' d='M14.7,7.4c0-4.3-2.6-7.4-6.6-7.4C4.6,0,2.8,2.1,2.8,5.3C2.71,7.47,4.4,9.31,6.58,9.4c0.04,0,0.08,0,0.12,0 c1.02,0.08,2.03-0.16,2.9-0.7c0.38,1.06,0.55,2.18,0.5,3.3c0,4.5-4,8.5-10.1,10.9l0.4,1.3C8.7,21.4,14.7,15.4,14.7,7.4z M30.4,7.4 c0-4.3-2.7-7.4-6.6-7.4c-3.5,0-5.3,2.1-5.3,5.3c-0.09,2.17,1.6,4.01,3.78,4.1c0.04,0,0.08,0,0.12,0c1.02,0.1,2.04-0.15,2.9-0.7 c0.38,1.06,0.55,2.18,0.5,3.3c0,4.6-4,8.5-10.1,10.9l0.4,1.3C24.4,21.4,30.4,15.4,30.4,7.4z'/%3E%3C/svg%3E");
            bottom: 20%;
            right: -10%;
        }
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(ms) {
            padding: 2rem 0 0;
        }
        .author {
            @include small-caps;
            display: block;
            margin: 2rem 0;
            font-style: normal;
            @include media-breakpoint-down(ms) {
                padding: 0 0 2rem;
            }
        }
    }
}
.hero-swiper.swiper-container {
    width: 100%;
    height: 100%;
    .swiper-slide {
    }
    .testimonial {
        width: 60vw;
        @include font-script;
        background: $white;
        font-style: italic;
        text-align: center;
        padding: 3rem 0;
        margin: auto;
        font-size: 2.5rem;
        position: relative;
        // min-height: 15vw;
        &:before,
        &:after {
            content: '';
            background-repeat: no-repeat;
            position: absolute;
            width: 30px;
            height: 30px;
            @include media-breakpoint-down(sm) {
                width: 18px;
                height: 18px;
            }
        }
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 30.4 24.2' style='enable-background:new 0 0 30.4 24.2;' xml:space='preserve'%3E%3Cpath id='Path_1185' d='M15.7,16.8c0,4.3,2.6,7.4,6.6,7.4c3.5,0,5.3-2.1,5.3-5.3c0.09-2.17-1.6-4.01-3.78-4.1c-0.04,0-0.08,0-0.12,0 c-1.02-0.07-2.03,0.18-2.9,0.7c-0.38-1.06-0.55-2.18-0.5-3.3c0-4.6,4-8.5,10.1-10.9L30,0C21.7,2.8,15.7,8.8,15.7,16.8z M0,16.8 c0,4.3,2.6,7.4,6.6,7.4c3.5,0,5.3-2.1,5.3-5.3c0.09-2.17-1.6-4.01-3.78-4.1c-0.04,0-0.08,0-0.12,0c-1.01-0.05-2.02,0.2-2.9,0.7 c-0.38-1.06-0.55-2.18-0.5-3.3c0-4.6,4-8.5,10.1-10.9L14.3,0C6,2.8,0,8.8,0,16.8z'/%3E%3C/svg%3E");
            top: 0;
            left: -10%;
            @include media-breakpoint-down(sm) {
                    top: 11px !important;
                    margin-top: 0 !important;
                }
        }
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 30.4 24.2' style='enable-background:new 0 0 30.4 24.2;' xml:space='preserve'%3E%3Cpath id='Path_1186' d='M14.7,7.4c0-4.3-2.6-7.4-6.6-7.4C4.6,0,2.8,2.1,2.8,5.3C2.71,7.47,4.4,9.31,6.58,9.4c0.04,0,0.08,0,0.12,0 c1.02,0.08,2.03-0.16,2.9-0.7c0.38,1.06,0.55,2.18,0.5,3.3c0,4.5-4,8.5-10.1,10.9l0.4,1.3C8.7,21.4,14.7,15.4,14.7,7.4z M30.4,7.4 c0-4.3-2.7-7.4-6.6-7.4c-3.5,0-5.3,2.1-5.3,5.3c-0.09,2.17,1.6,4.01,3.78,4.1c0.04,0,0.08,0,0.12,0c1.02,0.1,2.04-0.15,2.9-0.7 c0.38,1.06,0.55,2.18,0.5,3.3c0,4.6-4,8.5-10.1,10.9l0.4,1.3C24.4,21.4,30.4,15.4,30.4,7.4z'/%3E%3C/svg%3E");
            bottom: 20%;
            right: -10%;
        }
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(ms) {
            padding: 2rem 0 0;
        }
        .author {
            @include small-caps;
            display: block;
            margin: 2rem 0;
            font-style: normal;
            @include media-breakpoint-down(ms) {
                padding: 0 0 2rem;
            }
        }
    }
}
/****** CREATE SLIDER *******/
.swiper-button-next-inspiration-gallery, .swiper-button-prev-inspiration-gallery,
.swiper-button-next-single, .swiper-button-prev-single,
.swiper-button-next-gallery, .swiper-button-prev-gallery,
.swiper-button-next-gallery-thumbs, .swiper-button-prev-gallery-thumbs,
.swiper-button-next-nearby, .swiper-button-prev-nearby,
.swiper-button-next-offset, .swiper-button-prev-offset,
.swiper-button-next-inspiration, .swiper-button-prev-inspiration,
.swiper-button-next, .swiper-button-prev {
    top: -50px !important;
    margin-left: 6vw;
    position: absolute;
    z-index: 3;
    @include media-breakpoint-down(sm) {
        top: 11px !important;
        margin-top: 0 !important;
    }
}
.swiper-button-next-inspiration-gallery, .swiper-button-prev-inspiration-gallery,
.swiper-button-next-single, .swiper-button-prev-single,
.swiper-button-next-gallery, .swiper-button-prev-gallery,
.swiper-button-next-gallery-thumbs, .swiper-button-prev-gallery-thumbs,
.swiper-button-next-nearby, .swiper-button-prev-nearby,
.swiper-button-next-offset, .swiper-button-prev-offset,
.swiper-button-next-inspiration, .swiper-button-prev-inspiration {
    top: -80px !important;
    @include media-breakpoint-down(md) {
        top: -60px !important;
    }
    @include media-breakpoint-down(sm) {
        top: 11px !important;
    }
}
.swiper-button-next-inspiration-gallery, .swiper-button-prev-inspiration-gallery,
.swiper-button-next-single, .swiper-button-prev-single,
.swiper-button-next-gallery, .swiper-button-prev-gallery,
.swiper-button-next-gallery-thumbs, .swiper-button-prev-gallery-thumbs,
.swiper-button-next-nearby, .swiper-button-prev-nearby,
.swiper-button-next-offset, .swiper-button-prev-offset,
.swiper-button-next-inspiration, .swiper-button-prev-inspiration,
.swiper-button-next, .swiper-container-rtl .swiper-button-prev,
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    height: 50px !important;
    width: 50px !important;
    background-size: 50px 50px !important;
    @include media-breakpoint-down(md) {
        height: 40px !important;
        width: 40px !important;
        background-size: 40px 40px !important;
    }
    @include media-breakpoint-down(sm) {
        height: 30px !important;
        width: 30px !important;
        background-size: 30px 30px !important;
    }
}
.swiper-button-next-inspiration-gallery:focus, .swiper-button-prev-inspiration-gallery:focus,
.swiper-button-next-single:focus, .swiper-button-prev-single:focus,
.swiper-button-next-gallery:focus, .swiper-button-prev-gallery:focus,
.swiper-button-next-gallery-thumbs:focus, .swiper-button-prev-gallery-thumbs:focus,
.swiper-button-next-nearby:focus, .swiper-button-prev-nearby:focus,
.swiper-button-next-offset:focus, .swiper-button-prev-offset:focus,
.swiper-button-next-inspiration:focus, .swiper-button-prev-inspiration:focus,
.swiper-button-next:focus, .swiper-container-rtl:focus .swiper-button-prev:focus,
.swiper-button-prev:focus, .swiper-container-rtl:focus .swiper-button-next:focus {
    outline: none;
}
.swiper-button-next-inspiration-gallery,
.swiper-button-next-single,
.swiper-button-next-gallery,
.swiper-button-next-gallery-thumbs,
.swiper-button-next-offset,
.swiper-button-next-nearby,
.swiper-button-next-inspiration,
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Component_30_76' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233C3C3B;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/svg%3E") !important;
}
.swiper-button-prev-inspiration-gallery,
.swiper-button-prev-single,
.swiper-button-prev-gallery,
.swiper-button-prev-gallery-thumbs,
.swiper-button-prev-offset,
.swiper-button-prev-nearby,
.swiper-button-prev-inspiration,
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233C3C3B;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='Component_30_77' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

.bg-dark.text-image-swiper .swiper-button-next-single {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Component_30_88' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
    right: 120px !important;
    left: inherit !important;
    @include media-breakpoint-down(sm) {
        right: 64px !important;
    }
}
.bg-dark.text-image-swiper .swiper-button-prev-single {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='Component_30_89' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") !important;
    right: 60px !important;
    left: inherit !important;
    @include media-breakpoint-down(sm) {
        right: 24px !important;
    }
}
.swiper-button-next-inspiration-gallery,
.swiper-button-next-single,
.swiper-button-next-gallery,
.swiper-button-next-gallery-thumbs,
.swiper-button-next-offset,
.swiper-button-next-nearby,
.swiper-button-next-inspiration,
.swiper-button-next {
    left: 0 !important;
    @include media-breakpoint-down(sm) {
        right: 40px !important;
        left: auto !important;
    }
}
.swiper-button-prev-inspiration-gallery,
.swiper-button-prev-single,
.swiper-button-prev-gallery,
.swiper-button-prev-gallery-thumbs,
.swiper-button-prev-offset,
.swiper-button-prev-nearby,
.swiper-button-prev-inspiration,
.swiper-button-prev {
    left: 60px !important;
    @include media-breakpoint-down(sm) {
        right: 0 !important;
        left: auto !important;
    }
}
.swiper-button-next-inspiration-gallery.swiper-button-disabled, .swiper-button-prev-inspiration-gallery.swiper-button-disabled,
.swiper-button-next-single.swiper-button-disabled, .swiper-button-prev-single.swiper-button-disabled,
.swiper-button-next-gallery.swiper-button-disabled, .swiper-button-prev-gallery.swiper-button-disabled,
.swiper-button-next-prev-gallery-thumbs.swiper-button-disabled, .swiper-button-prev-prev-gallery-thumbs.swiper-button-disabled,
.swiper-button-next-nearby.swiper-button-disabled, .swiper-button-prev-nearby.swiper-button-disabled,
.swiper-button-next-offset.swiper-button-disabled, .swiper-button-prev-offset.swiper-button-disabled,
.swiper-button-next-inspiration.swiper-button-disabled, .swiper-button-prev-inspiration.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
}
.swiper-container-horizontal {
    .swiper-scrollbar {
        &.bar {
            background: $secondary-light;
            border-radius: 0;
            height: 9px;
            top: 0;
            left: 0;
            .swiper-scrollbar-drag {
                background: $primary;
                border-radius: 0;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 4px;
            }
        }
    }
}
.bg-white {
    .swiper-container-horizontal {
        .swiper-scrollbar {
            &.bar {
                background: #dbdbdb;
                border-radius: 0;
                height: 9px;
                top: 0;
                left: 0;
                .swiper-scrollbar-drag {
                    background: $primary;
                    border-radius: 0;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    height: 4px;
                }
            }
        }
    }
}
.swiper-pagination-bullet-active {
    background: $primary;
}
.all-btn {
    position: absolute;
    top: -60px;
    right: 2%;
    @include media-breakpoint-down(sm) {
        top: -25px;
        right: 0;
    }
}
.text-image-swiper {
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                color: $white;
                height: 42vw;
                background: $secondary;
                padding: 6vw;
                @include media-breakpoint-only(xxl) {
                    height: 33vw;
                }
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                h2 {
                    color: $white;
                    margin-left: -50%;
                    padding-right: 50%;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $primary;
                    opacity: 1;
                }
                .content .description {
                    margin-bottom: 60px;
                    height: 230px;
                    overflow: auto;
                    @include media-breakpoint-down(ms) {
                        height: inherit;
                        margin-bottom: 40px;
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
    .swiper-button-next-single, .swiper-button-prev-single {
        bottom: 5% !important;
        top: inherit !important;
        z-index: 1;
        @include media-breakpoint-down(sm) {
            bottom: 55%;
        }
    }
    .swiper-button-prev-single {
        @include media-breakpoint-down(sm) {
            right: 20px !important;
        }
    }
    .swiper-button-next-single {
        @include media-breakpoint-down(sm) {
            right: 60px !important;
        }
    }
}
.local-area-swiper {
    &:before {
        display: none !important;
    }
    .text-image-box {
        .content-area {
            &.image {
                width: 50%;
                padding: 0;
                position: relative;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-top: 0;
                    height: 50vw;
                }
                .details {
                    position: absolute;
                    bottom: 5%;
                    left: 10%;
                    color: $white;
                    z-index: 2;
                    width: 60%;
                    h4 {
                        color: $white;
                        margin-bottom: 0.5rem;
                    }
                }
            }
            &.image:after {
                content:'';
                background: rgba($black, 0.3);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &.text {
                width: 50%;
                height: 42vw;
                background: $extra-light;
                padding: 6vw;
                @include media-breakpoint-only(xxl) {
                    height: 33vw;
                }
                @include media-breakpoint-down(lg) {
                    height: inherit;
                }
                h2 {
                    color: $primary;
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                }
                .sub-title {
                    color: $primary;
                    opacity: 1;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
    .swiper-button-next, .swiper-button-prev {
        bottom: 5%;
        top: inherit !important;
        @include media-breakpoint-down(sm) {
            bottom: 55%;
        }
    }
    .swiper-button-prev {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='Component_30_89' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
        @include media-breakpoint-down(sm) {
            right: 20px !important;
        }
    }
    .swiper-button-next {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Component_30_88' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/svg%3E") !important;
        @include media-breakpoint-down(sm) {
            right: 60px !important;
        }
    }
}
.single-swiper {
    .swiper-button-next, .swiper-button-prev {
        bottom: 5%;
        top: inherit !important;
        @include media-breakpoint-down(sm) {
            bottom: inherit;
            top: 11px !important;
        }
    }
    .swiper-button-prev {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg id='Component_30_89' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
        @include media-breakpoint-down(sm) {
            right: 0 !important;
        }
    }
    .swiper-button-next {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Component_30_88' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23AF8657;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_23' class='st0' cx='25' cy='25' r='25'/%3E%3Cg id='Group_335' transform='translate(16.85 16.567)'%3E%3Crect id='Rectangle_906' x='-0.7' y='3.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.2814 5.5077)' class='st1' width='12.5' height='3.1'/%3E%3Crect id='Rectangle_907' x='3.9' y='5.9' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -6.9632 7.4467)' class='st1' width='3.1' height='12.5'/%3E%3C/g%3E%3C/svg%3E") !important;
        @include media-breakpoint-down(sm) {
            right: 40px !important;
        }
    }
}
.arrows {
    position: absolute;
    right: 65%;
    bottom: 5%;
    @include media-breakpoint-down(lg) {
        right: 68%;
    }
    @include media-breakpoint-down(md) {
        right: 70%;
    }
    @include media-breakpoint-down(sm) {
        top: 5%;
        right: inherit;
        left: 20%;
        bottom: inherit;
    }
}
